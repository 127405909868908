import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../Components/ProgressBar";
import { Grid } from "@material-ui/core";

export const QuestionSection = ({ sectiondata, ...props }) => {
  return (
    <>
      <Grid
        container
        alignItem="center"
        justifyContent="center"
        direction="row"
        //className={classes.bgImg}
      >
        <Grid
          item
          md={12}
          xs={12}
          style={{ marginLeft: 10, marginRight: 10, zIndex: 9 }}
        >
          {(props.progressval !== undefined || props.progressval !== "") && (
            <ProgressBar
              progress={props.progressval.index}
              totprogress={props.progressval.total}
            />
          )}
        </Grid>
      </Grid>

      <ul>
        {sectiondata.map((section, index) => {
          let fontIcon = "";
          let classAttent = "";
          let classActiveshadow = "";

          if (
            (props.reviewconfirm && section.section_lable === "Confirm") ||
            (props.confirmationscreen && section.section_lable === "Confirm")
          ) {
            classActiveshadow = " shadow";
            if (section.is_complete === true) {
              fontIcon = <FontAwesomeIcon icon={faCheck} />;
              if (
                sectiondata[sectiondata.length - 1].is_complete === true &&
                section.section_lable !== "Review"
              ) {
                classAttent = "disabled";
              } else {
                classAttent = "attent";
              }
            }
          } else {
            if (section.is_active === true) {
              classActiveshadow = " shadow";
            }
            if (section.is_complete === true) {
              fontIcon = <FontAwesomeIcon icon={faCheck} />;
              if (
                sectiondata[sectiondata.length - 1].is_complete === true &&
                section.section_lable !== "Review"
              ) {
                classAttent = "disabled";
              } else {
                classAttent = "attent";
              }
            }
          }
          return (
            <li
              key={index}
              className={classAttent}
              onClick={() => {
                if (
                  sectiondata[sectiondata.length - 1].is_complete === true &&
                  section.section_lable.trim() !== "Review"
                ) {
                } else if (section.section_lable.trim() === "Review") {
                  props.getReview();
                  {
                    sectiondata.map((sec, ind) => {
                      if (sec.is_active === true) {
                        props.setSectionData(
                          [...sectiondata],
                          ([...sectiondata][ind].is_active = false)
                        );
                      }
                    });
                  }
                  props.setSectionData(
                    [...sectiondata],
                    ([...sectiondata][sectiondata.length - 2].is_active = true)
                  );
                } else if (section.section_lable.trim() === "Confirm") {
                  props.getConfirm();
                } else {
                  props.getSectionWiseQuest(section.section_id);
                  props.setSectionData(
                    [...sectiondata],
                    ([...sectiondata][sectiondata.length - 2].is_active = false)
                  );
                }
              }}
            >
              <span className={classActiveshadow}>
                {fontIcon} {section.section_lable}
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSection);
