import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Page from "src/Components/Page";
import PreQuestListDetails from "./Components/PreQuestListDetails";
import { Http } from "src/Services/Http";
import { createUseStyles } from "react-jss";
import CenterLoader from "src/Components/Loader/CenterLoader";
import { Grid } from "@material-ui/core";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { configureStore } from "src/redux/store";

const bgimage = localStorage.getItem("postBgImage");
const useStyles = createUseStyles({
  bgImg: {
    background: bgimage
      ? "url(" + bgimage + ") no-repeat"
      : "url(/img/m.jpg) no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100vh",
  },
  avatar: {
    height: 100,
    width: 100,
  },
  input: {
    display: "none",
  },
});

export const PreQuestListView = (props) => {
  const classes = useStyles();
  const [Values, setValues] = useState();
  const [isLoading, setisLoading] = useState(true);
  const store = configureStore();
  const isPaymentChk = store.getState().session.paymentchk;

  const handleChange = (event) => {
    setValues({
      ...Values,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    const fetchPrequestUserAns = async () => {
      try {
        const { data } = await Http("GET", "user/pre_quest_user_answer");
        setValues(data);
        setisLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchPrequestUserAns();
  }, []);

  // if (isLoading) {
  //   return <CenterLoader />;
  // }

  return (
    <Page title={"Preliminary Questionnaire"}>
      <Grid
        container
        direction="row"
        className="main-body homesection_main"
        style={{
          backgroundImage: `url("/img/Rectangle24.png")`,
          backgroundSize: "cover",
          height: "100vh",
          alignItem: "center",
          justifyContent: "center",
          zIndex: "0",
          position: "relative",
        }}
      >
        <Grid item md={6} xs={12} style={{ padding: "3%" }}>
          <div className="postque-bg">
            {isLoading ? (
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  minHeight: 400,
                }}
              >
                <CenterLoader />
              </Grid>
            ) : (
              <div className="que-data">
                <h6 className="ques-title">Pre Questionnaire Responses</h6>
                <p className="ques-desc">
                  If you need to change any of your responses to the following
                  questions, please use the button below.
                </p>
                {/* {isPaymentChk !== true ? (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      className="homenavbutton"
                      component={Link}
                      to={"/app/editprequest"}
                      variant="contained"
                      alignItem="center"
                      style={{ textTransform: "unset" }}
                    >
                      Edit Pre-Questionnaire
                    </Button>
                  </div>
                ) : (
                  ""
                )} */}
                <PreQuestListDetails
                  data={Values}
                  handleChange={handleChange}
                  setValues={setValues}
                />
              </div>
            )}
          </div>
        </Grid>
        {/* <Grid item md={6} xs={12}>
        <div className={classes.bgImg}></div>
      </Grid> */}
      </Grid>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PreQuestListView);
