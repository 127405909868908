import React from "react";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import AutocompleteComponent from "react-google-autocomplete";
import * as Yup from "yup";
import * as Messages from "src/constants/Messages";

export default function GoogleAutoCompleteBox({
  address,
  getQuestion,
  is_last,
  setAnswer,
  setAddress,
  UnitData,
  error,
  setError,
}) {
  const setAddressData = async (
    { address_components, geometry, formatted_address },
    setFieldValue
  ) => {
    if (address_components.length > 0) {
      setFieldValue("address_line_1", formatted_address);
      setFieldValue("lat", geometry.location.lat());
      setFieldValue("long", geometry.location.lng());
    }
    if (address_components.length > 0) {
      address_components.map((address_component) => {
        if (address_component.types.includes("administrative_area_level_1")) {
          setFieldValue("state", address_component.long_name);
        } else if (address_component.types.includes("locality")) {
          setFieldValue("city", address_component.long_name);
        } else if (address_component.types.includes("postal_code")) {
          setFieldValue("zip", address_component.long_name);
        } else if (address_component.types.includes("country")) {
          setFieldValue("country", address_component.long_name);
        }
      });
    }
  };
  const ValidationSchema = Yup.object().shape({
    locationAddress: Yup.string().required(Messages.FIELD_REQUIRED),
    address_line_1: Yup.string().required(Messages.FIELD_REQUIRED),
    unit_type: Yup.boolean(),
    unit_number: Yup.number().when("unit_type", {
      is: true,
      then: Yup.number().required(Messages.FIELD_REQUIRED),
    }),
    city: Yup.string().required(Messages.FIELD_REQUIRED),
    state: Yup.string().required(Messages.FIELD_REQUIRED),
    zip: Yup.string().required(Messages.FIELD_REQUIRED),
    country: Yup.string().required(Messages.FIELD_REQUIRED),
  });
  return (
    <div className="col-md-12 ans-fill">
      <Formik
        initialValues={{
          locationAddress:
            address.address_line_1 !== undefined ? address.address_line_1 : "",
          address_line_1:
            address.address_line_1 !== undefined ? address.address_line_1 : "",
          unit_type: address.unit_type !== undefined ? address.unit_type : "",
          unit_number:
            address.unit_number !== undefined ? address.unit_number : "",
          city: address.city !== undefined ? address.city : "",
          state: address.state !== undefined ? address.state : "",
          zip: address.zip !== undefined ? address.zip : "",
          country: address.country !== undefined ? address.country : "",
          long: address.long !== undefined ? address.long : "",
          lat: address.lat !== undefined ? address.lat : "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={async (values, actions) => {
          await getQuestion(true, values.locationAddress, is_last, values);
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          setAddress(values);
          return (
            <form
              onSubmit={handleSubmit}
              onChange={(e) => {
                if (e.target.name === "unit_type" && e.target.value === "") {
                  setFieldValue("unit_number", "");
                }
                setError("");
              }}
            >
              <Form.Group>
                <AutocompleteComponent
                  apiKey="AIzaSyAW9PMZKxsJ3iAZ249oFM6k8M1xYKhMXhw"
                  options={{
                    types: ['geocode','establishment'],
                  }}
                  defaultValue={values.locationAddress}
                  onPlaceSelected={(place) => {
                    setAddressData(place, setFieldValue);
                    setFieldValue("locationAddress", place.formatted_address);
                    setAnswer(place.formatted_address);
                  }}
                  className="form-control"
                />
                {error !== "" ? (
                  <Form.Control.Feedback type="invalid" className=" d-block">
                    {error}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Control
                  name="address_line_1"
                  type="text"
                  placeholder={"Address 1"}
                  value={values.address_line_1}
                  onChange={handleChange}
                  isInvalid={errors.address_line_1 !== "" ? true : false}
                />
                {errors.address_line_1 !== "" ? (
                  <Form.Control.Feedback type="invalid" className=" d-block">
                    {errors.address_line_1}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Control
                  as="select"
                  name="unit_type"
                  value={values.unit_type}
                  onChange={handleChange}
                >
                  <option value="">Select Unit Type</option>
                  {UnitData.length > 0 &&
                    UnitData.map((user_typee, index) => (
                      <option key={index} value={user_typee.id}>
                        {user_typee.unit_type_name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  name="unit_number"
                  type="text"
                  placeholder={"Unit Number"}
                  value={values.unit_number}
                  disabled={values.unit_type === "" ? true : false}
                  onChange={handleChange}
                  isInvalid={errors.unit_number !== "" ? true : false}
                />
                {errors.unit_number !== "" ? (
                  <Form.Control.Feedback type="invalid" className=" d-block">
                    {errors.unit_number}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Control
                  name="city"
                  type="text"
                  placeholder={"City"}
                  value={values.city}
                  disabled={true}
                  isInvalid={errors.city !== "" ? true : false}
                />
                {errors.city !== "" ? (
                  <Form.Control.Feedback type="invalid" className=" d-block">
                    {errors.city}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Control
                  name="state"
                  type="text"
                  placeholder={"state"}
                  value={values.state}
                  disabled={true}
                  isInvalid={errors.state !== "" ? true : false}
                />
                {errors.state !== "" ? (
                  <Form.Control.Feedback type="invalid" className=" d-block">
                    {errors.state}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Control
                  name="country"
                  type="text"
                  placeholder={"Country"}
                  value={values.country}
                  disabled={true}
                  isInvalid={errors.country !== "" ? true : false}
                />
                {errors.country !== "" ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.country}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Control
                  name="zip"
                  type="text"
                  placeholder={"Zip"}
                  value={values.zip}
                  disabled={true}
                  isInvalid={errors.zip !== "" ? true : false}
                />
                {errors.zip !== "" ? (
                  <Form.Control.Feedback type="invalid" className=" d-block">
                    {errors.zip}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Button
                color="primary"
                variant="outline-light"
                type="submit"
                className="btn-block"
                //disabled={isSubmitting}
              >
                Continue
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}
