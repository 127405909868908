import React from "react";
import { IMaskInput } from "react-imask";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { inputRef, onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={"+{1} (000) 000-0000"}
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default TextMaskCustom;
