import React, { useState } from "react";
import { Formik } from "formik";
// import { usePaymentInputs } from "react-payment-inputs";
import { Col, Form, Row } from "react-bootstrap";
import images from "react-payment-inputs/images";
import * as Yup from "yup";
import * as Messages from "src/constants/Messages";
import { RadioGroup } from "@mui/material";
import { Radio } from "@mui/material";
import { Grid, TextField, FormControlLabel } from "@mui/material";
import TextInput from "src/Components/Formik/TextInput";
import AddressBox from "src/Components/Formik/AddressBox";
import { usePlacesWidget } from "react-google-autocomplete";
import TextMaskCustom from "src/Components/TextMaskCustom";
//import { HelmetProvider } from "react-helmet-async";

export default function PaymentForm({ setPaymentInfo, setValid }) {
  const [gaddress, setGAddress] = useState({});

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(Messages.FIELD_REQUIRED)
      .max(50, Messages.MAX_FIFTY_CHARACTER_ALLOWED),
    lastName: Yup.string()
      .required(Messages.FIELD_REQUIRED)
      .max(50, Messages.MAX_FIFTY_CHARACTER_ALLOWED),
    address: Yup.string()
      .required(Messages.FIELD_REQUIRED)
      .max(60, Messages.MAX_SIXTY_CHARACTER_ALLOWED),
    city: Yup.string()
      .required(Messages.FIELD_REQUIRED)
      .max(40, Messages.MAX_FOURTY_CHARACTER_ALLOWED),
    state: Yup.string()
      .required(Messages.FIELD_REQUIRED)
      .min(2, Messages.ALLOW_TWO_CHARACTER_STATE_CODE)
      .max(2, Messages.ALLOW_TWO_CHARACTER_STATE_CODE),
    country: Yup.string()
      .required(Messages.FIELD_REQUIRED)
      .max(60, Messages.MAX_SIXTY_CHARACTER_ALLOWED),
    zip: Yup.string()
      .required(Messages.FIELD_REQUIRED)
      .max(20, Messages.MAX_TWENTY_CHARACTER_ALLOWED),
    phone: Yup.string()
      .required(Messages.FIELD_REQUIRED)
      .max(25, Messages.MAX_TWENTYFIVE_CHARACTER_ALLOWED),
  });

  //const { erroredInputs, touchedInputs } = meta;
  return (
    <div className="col-md-6">
      <div className="payment-method">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            address: gaddress.address,
            city: gaddress.city?gaddress.city:"",
            state: gaddress.state ?gaddress.state:"",
            country: gaddress.country ? gaddress.country :"" ,
            zip: "",
            phone: "",
          }}
          onSubmit={(values, actions) => {
            if (Object.keys(gaddress).length === 0) {
              actions.setErrors({
                address: "Please select any place from google address dropdown",
              });
            } else {
              setPaymentInfo(values);
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleBlur,
            handleChange,
            handleSubmit,
            validateForm,
            setErrors,
            setFieldValue,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                // <---new function--->
                onChange={async () => {
                  localStorage.setItem("PaymentData", JSON.stringify(values));
                  await validateForm().then((formErrors) => {
                    // Check if there are any errors related to the holderName field
                    const firstName = formErrors.firstName !== undefined;
                    const lastName = formErrors.lastName !== undefined;
                    const address = formErrors.address !== undefined;
                    const city = formErrors.city !== undefined;
                    const state = formErrors.state !== undefined;
                    const country = formErrors.country !== undefined;
                    const zip = formErrors.zip !== undefined;
                    const phone = formErrors.phone !== undefined;

                    // Set errors based on form validation results
                    setErrors(formErrors);

                    // Update validity based on formErrors and whether holderName is not empty
                    if (
                      !firstName &&
                      !lastName &&
                      !address &&
                      !city &&
                      !state &&
                      !country &&
                      !zip &&
                      !phone &&
                      values.firstName.trim() !== "" &&
                      values.lastName.trim() !== "" &&
                      values.address.trim() !== "" &&
                      values.city.trim() !== "" &&
                      values.state.trim() !== "" &&
                      values.country.trim() !== "" &&
                      values.zip.trim() !== "" &&
                      values.phone.trim() !== ""
                    ) {

                      if (Object.keys(gaddress).length === 0) {
                        setValid(false);
                        setErrors({
                          address: "Please select any place from google address dropdown",
                        });
                      } else {
                        setValid(true);  
                      }

                      //setValid(true);
                    } else {
                      setValid(false);
                    }
                  });
                }}
                onBlur={async () => {
                  localStorage.setItem("PaymentData", JSON.stringify(values));
                  await validateForm().then((formErrors) => {
                    // Check if there are any errors related to the holderName field
                    const firstName = formErrors.firstName !== undefined;
                    const lastName = formErrors.lastName !== undefined;
                    const address = formErrors.address !== undefined;
                    const city = formErrors.city !== undefined;
                    const state = formErrors.state !== undefined;
                    const country = formErrors.country !== undefined;
                    const zip = formErrors.zip !== undefined;
                    const phone = formErrors.phone !== undefined;

                    // Set errors based on form validation results
                    setErrors(formErrors);

                    // Update validity based on formErrors and whether holderName is not empty
                    if (
                      !firstName &&
                      !lastName &&
                      !address &&
                      !city &&
                      !state &&
                      !country &&
                      !zip &&
                      !phone &&
                      values.firstName.trim() !== "" &&
                      values.lastName.trim() !== "" &&
                      values.address.trim() !== "" &&
                      values.city.trim() !== "" &&
                      values.state.trim() !== "" &&
                      values.country.trim() !== "" &&
                      values.zip.trim() !== "" &&
                      values.phone.trim() !== ""
                    ) {
                      if (Object.keys(gaddress).length === 0) {
                        setValid(false);
                        setErrors({
                          address: "Please select any place from google address dropdown",
                        });
                      } else {
                        setValid(true);  
                      }

                      //setValid(true);
                    } else {
                      setValid(false);
                    }
                  });
                }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <h3>Billing Information</h3>
                  </div>
                </div>

                {/* <Grid item md={12} xs={12}>
                  <div className="payment-icon">
                    <RadioGroup
                      aria-label="gender"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value={"ture"}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="ture"
                        control={<Radio />}
                        label="Credit Card"
                      />
                    </RadioGroup>
                    <svg {...getCardImageProps({ images })} />
                    {/* <img src="img/payment1.png" class="pcard" /> }
                  </div>
                </Grid> */}
                <Row>
                  <Col md={12} className="p-0 mt-2">
                    <div className="line-pay"></div>
                  </Col>
                </Row>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={6}>
                        <TextInput
                          fullWidth
                          label="First Name"
                          placeholder="First Name"
                          name="firstName"
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          autoFocus={true}
                        />
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <TextInput
                          fullWidth
                          label="Last Name"
                          placeholder="Last Name"
                          name="lastName"
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <AddressBox
                          name="address"
                          placeholder="Address"
                          setAddress={setGAddress}
                          setFieldValue={setFieldValue}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <TextInput
                          fullWidth
                          name="city"
                          label="City"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <TextInput
                          fullWidth
                          name="state"
                          label="State"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <TextInput
                          fullWidth
                          name="country"
                          label="Country"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <TextInput
                          fullWidth
                          name="zip"
                          label="Zip"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={6} xs={6}>
                        <TextInput
                          name="phone"
                          label="Phone Number"
                          variant="outlined"
                          onChange={(event) => {
                            setFieldValue("phone", event.target.value);
                          }}
                          InputProps={{
                            inputComponent: TextMaskCustom,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <div className="d-flex justify-content-center">
                      {/* <Button type="submit">Verify</Button> */}
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
