import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Page from "src/Components/Page";
import QuestionBox from "./QuestionBox";
import * as Helper from "src/Services/Helper";
import { Http } from "src/Services/Http";

export default function EditPreQuest({ color }) {
  // const { image, user, questconditionchk } = props;
  const bgImage = Helper.getItem("bgImage");
  const [Image, setImage] = useState(bgImage);
  const [Color, setColor] = useState("#273969");
  useEffect(() => {
    const getTheme = async () => {
      await Http("get", "get_theme/1").then(async ({ data, status }) => {
        setImage(data.image);
        setColor(data.font_color);
        Helper.setItem("postBgImage", data.image);
      });
    };
    getTheme();
  }, []);
  const css =
    `.dynamic-edit-prequest-body {
    margin: 0;
    padding: 0;
    background-image: url(` +
    Image +
    `);
    background-size: cover;
    height: 100%;
    position: relative;
    min-height: 100vh;
    background-position: center;
    z-index: 0;
  }
  .back-button {
    color: ` +
    Color +
    ` !important;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  }
  .call-details {
    color: ` +
    Color +
    ` !important;
    font-size: 12px;
    font-weight: 300;
  }`;
  return (
    <>
      <style>{css}</style>
      <Page
        title="Fernwood Cemetery Pre-question Edit"
        className="dynamic-edit-prequest-body mt-5 pt-5 homesection_main"
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <div className="que-top-pre">
              <p>
                Fernwood Cemetery offers simple direct cremation options that
                can be arranged from the comfort of your home with the help of
                our compassionate staff.
              </p>
            </div>
          </Grid>
          <QuestionBox color={color} />
        </Grid>
      </Page>
    </>
  );
}
