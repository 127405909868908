import React from "react";

export default function QuestionHeader({ section_name }) {
  return (
    <div className="row">
      <div className="col-md-12">
        <h6 className="main-title">{section_name}</h6>
      </div>
    </div>
  );
}
