import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import * as Messages from "src/constants/Messages";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

export default function MaterialUIPickers({
  setAnswer,
  answer,
  setError,
  error,
  isIframe,
  isBirthDatePicker,
  ...otherProps
}) {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(
    answer === "" ? null : answer
  );
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const handleDateChange = (date) => {
    //setError("");
    setSelectedDate(date);
    setAnswer(date);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        // SelectProps={{ style: { height: "10%", width: "10%" } }}
        variant={isIframe ? "inline" : "dialog"}
        orientation={vw > 400 ? "landscape" : "static"}
        format="MM/dd/yyyy"
        margin="normal"
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        //disableToolbar={isIframe}
        inputProps={{ style: { paddingLeft: 10, paddingRight: 0, margin: 0 } }}
        value={selectedDate}
        onChange={handleDateChange}
        autoOk={true}
        maxDateMessage={Messages.INVALID_DATE}
        minDateMessage={Messages.INVALID_DATE}
        onError={setError}
        helperText={<div style={{ fontSize: 15 }}>{error}</div>}
        error={error !== ""}
        maxDate={
          isBirthDatePicker
            ? new Date().setFullYear(new Date().getFullYear() - 18)
            : undefined
        }
        {...otherProps}
      />
    </MuiPickersUtilsProvider>
  );
}
