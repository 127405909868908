import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { usePaymentInputs } from "react-payment-inputs";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import * as Messages from "src/constants/Messages";
import { RadioGroup } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import images from "react-payment-inputs/images";
import { Navigate, useParams } from "react-router-dom";
import "src/styles/custom.css";
import {
  setCertificatePrice,
  setPackageData,
  emptyPackageData,
} from "src/redux/actions";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Col, Form, Row } from "react-bootstrap";
import AddressBox from "src/Components/Formik/AddressBox";
import TextInput from "src/Components/Formik/TextInput";
import TextMaskCustom from "src/Components/TextMaskCustom";
import { makeStyles } from "@material-ui/core/styles";
import { Http } from "src/Services/Http";
import Page from "src/Components/Page";
import ThankYouView from "src/views/ThankYouView";
import PaymentLoader from "src/views/PaymentView/Components/PaymentLoader";
import { setCategoryData } from "src/redux/actions";
import { HostedForm } from "react-acceptjs";
import AdditionalPaymentLoader from "src/views/PaymentView/Components/AdditionalPaymentLoader";

// const validationSchema = Yup.object().shape({
//   holderName: Yup.string().required(Messages.FIELD_REQUIRED),
// });

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(Messages.FIELD_REQUIRED)
    .max(50, Messages.MAX_FIFTY_CHARACTER_ALLOWED),
  lastName: Yup.string()
    .required(Messages.FIELD_REQUIRED)
    .max(50, Messages.MAX_FIFTY_CHARACTER_ALLOWED),
  address: Yup.string()
    .required(Messages.FIELD_REQUIRED)
    .max(60, Messages.MAX_SIXTY_CHARACTER_ALLOWED),
  city: Yup.string()
    .required(Messages.FIELD_REQUIRED)
    .max(40, Messages.MAX_FOURTY_CHARACTER_ALLOWED),
  state: Yup.string()
    .required(Messages.FIELD_REQUIRED)
    .min(2, Messages.ALLOW_TWO_CHARACTER_STATE_CODE)
    .max(2, Messages.ALLOW_TWO_CHARACTER_STATE_CODE),
  country: Yup.string()
    .required(Messages.FIELD_REQUIRED)
    .max(60, Messages.MAX_SIXTY_CHARACTER_ALLOWED),
  zip: Yup.string()
    .required(Messages.FIELD_REQUIRED)
    .max(20, Messages.MAX_TWENTY_CHARACTER_ALLOWED),
  phone: Yup.string()
    .required(Messages.FIELD_REQUIRED)
    .max(25, Messages.MAX_TWENTYFIVE_CHARACTER_ALLOWED),
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
}));



export const AdditonalPaymentView = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [isExpire, setIsExpire] = useState(false);
  const [isAgree, setisAgree] = useState(false);
  const [ isValid, setValid] = useState(false);
  const handleChangeChk = (arg) => {
    setisAgree(arg.target.checked);
  };
  const [Discount, setDiscount] = useState(0);
  const [PromoCode, setPromocode] = useState("");
  const [resStatus, setresStatus] = useState(null);
  const [resMessage, setresMessage] = useState("");
  const [promoLoader, setpromoLoader] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [serviceData, setServiceData] = useState([]);
  // const [packageData, setPackageData] = useState({});
  const [discountDetaile, setdiscountDetaile] = useState(null);
  const [cirtificateCount, setCirtificateCount] = useState(0);
  const [certificatePrice, setCertificatePrice] = useState(0);
  const [gaddress, setGAddress] = useState({
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });
  // const { categoryData, crtPrice } = props;
  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const { erroredInputs, touchedInputs } = meta;
  const categoryData = useSelector((state) => state.package.categoryData);
  let total = cirtificateCount * certificatePrice;
  let totaltax = 0;

  let serviceprice = 0;
  let shipping = 0;
  let shippingAddonData = categoryData.find((item) => item.id === 10);
  let itemTax = 0;
  let itemShipping = 0;
  let itemPricewithShipTax = 0;

  const CheckCode = async () => {
    setpromoLoader(true);

    let category = [];
    serviceData.length > 0 &&
      // serviceData.map((item, index) => (category[index] = item.id));
      serviceData.map((item, index) => {
        return category.push({
          id: item.id,
          quantity: item.quantity ? item.quantity : 0,
          included: item.included ? item.included : 0,
          state: item.state !== undefined ? item.state : "",
          address: item.address !== undefined ? item.address : "",

          name: item.service_name,
          custom_addons:item.custom_addons,
          price: item.price,
          tax: item.tax_per,

          city: item.city !== undefined ? item.city : "",
          country: item.country !== undefined ? item.country : "",
          lat: item.lat !== undefined ? item.lat : "",
          long: item.long !== undefined ? item.long : "",
          zip: item.zip !== undefined ? item.zip : "",
        });
      });

    if (Discount !== 0) {
      setDiscount(0);
      setresStatus({});
      setresMessage("");
      setPromocode("");
      setdiscountDetaile(null);
    } else {
      const response = await Http("POST", "discount", {
        promocode: PromoCode,
        addons_ids: category,
        no_of_cert: cirtificateCount,
        is_adjustpayment: 1,
        // flag: "admin",
      });
      setresStatus(response.status);
      setresMessage(response.message);
      if (response.status === 200) {
        localStorage.setItem("discount", JSON.stringify(response.data));
        setdiscountDetaile(response.data);
        setDiscount(response.data.discount_amount);
      }
    }
    setpromoLoader(false);
    return true;
  };

  const getPaymentDetaile = async () => {
    const { data, status, message } = await Http("POST", "payment-services", {
      token: props.resetFeedbackToken,
      is_adjustpayment: 1,
    });
    if (status === 200) {
      data.addonCategory.map((item, index) => {
        item.addOns.map((additem, addindex) => {
          if (additem.is_selected) {
            setPackageData({
              id: additem.id,
              catId: item.id,
              name: additem.service_name,
              custom_addons:additem.custom_addons,
              price: additem.price,
              tax: additem.tax,
              tax_per: additem.tax_per,
              type: item.selection_type,
              quantity: additem.quantity ? additem.quantity : 0,
              selected: additem.quantity ? true : false,
              included: additem.is_included ? true : false,
              state: additem.state ? additem.state : "",
              address: additem.address ? additem.address : "",
              shipping_inside: item.shipping_inside,
              shipping_outside: item.shipping_outside,
              city: additem.city !== undefined ? additem.city : "",
              country: additem.country !== undefined ? additem.country : "",
              lat: additem.lat !== undefined ? additem.lat : "",
              long: additem.long !== undefined ? additem.long : "",
              zip: additem.zip !== undefined ? additem.zip : "",
            });
          }
        });
      });

      setCirtificateCount(parseInt(data.certificate_count));
      setCertificatePrice(parseInt(data.certificate_rate));
      let services = [];
      data.addonCategory.map((item, index) => {
        item.addOns.map((additem, addindex) => {
          if (additem.is_selected) {
            services.push({
              id: additem.id,
              catId: item.id,
              name: additem.service_name,
              quantity: additem.quantity,
              custom_addons:additem.custom_addons,
              price: additem.price,
              type: item.selection_type,
              address: additem.address,
              state: additem.state,
              price: additem.price,
              tax: additem.tax,
              tax_per: additem.tax_per,
              selected: additem.quantity ? true : false,
              included: additem.is_included ? true : false,
              shipping_inside: item.shipping_inside,
              shipping_outside: item.shipping_outside,
              city: additem.city !== undefined ? additem.city : "",
              country: additem.country !== undefined ? additem.country : "",
              lat: additem.lat !== undefined ? additem.lat : "",
              long: additem.long !== undefined ? additem.long : "",
              zip: additem.zip !== undefined ? additem.zip : "",
            });
          }
        });
      });
      setServiceData(services);
      dispatch(setCategoryData(services));

      setLoading(false);
    } else if (status === 409) {
      setRedirect("/thank-you?token=" + props.resetFeedbackToken);
    } else {
      setLoading(false);
      setIsExpire(true)
      // alert("API ERO+ROR" + message);
    }
  };

  useEffect(() => {
    getPaymentDetaile();
    localStorage.setItem("discount", JSON.stringify({}));
  }, []);

  const makePayment = async (hostedFormResponse) => {
    setLoading(true);

    const PaymentInfo = JSON.parse(localStorage.getItem("PaymentData"));

    let addOns = [];
    serviceData.length > 0 &&
      serviceData.map((item, index) =>
        addOns.push({
          id: item.id,
          name: item.name,
          quantity: item.quantity ? item.quantity : 0,
          price: item.price ? item.price : 0,
          tax: item.tax_per ? item.tax_per : 0,
          custom_addons:item.custom_addons ? item.custom_addons : 0,
          state: item.state !== undefined ? item.state : "",
          address: item.address !== undefined ? item.address : "",
          city: item.city !== undefined ? item.city : "",
          country: item.country !== undefined ? item.country : "",
          lat: item.lat !== undefined ? item.lat : "",
          long: item.long !== undefined ? item.long : "",
          zip: item.zip !== undefined ? item.zip : "",
        })
      );

    const discount_id = JSON.parse(
      localStorage.getItem("discount")
    ).discount_id;

    let body = {
      opaqueData:hostedFormResponse.opaqueData,
      first_name: PaymentInfo.firstName,
      last_name: PaymentInfo.lastName,
      address: PaymentInfo.address,
      city: PaymentInfo.city,
      state: PaymentInfo.state,
      country: PaymentInfo.country,
      zip: PaymentInfo.zip,
      phone:PaymentInfo.phone,
      addons_ids: addOns,
      discount_id: discount_id,
      user_id: "",
      no_of_cert: cirtificateCount,
      is_adjustpayment: 1,
      package_id: 0,
      token: props.resetFeedbackToken,
    };

    await Http("POST", "payment", body).then((response) => {
      if (response.status === 200) {
        setLoading(false);
        //setRedirect(true);
        setRedirect("/thank-you?token=" + props.resetFeedbackToken);
      } else {
        alert(response.message);
      }
    });
    setLoading(false);
  };

  if (redirect) {
    // console.log("in redirect");
    return <Navigate to={redirect} />;
    //return <ThankYouView paymentFlag={"payment"} isAdjustpayment={1} token={props.resetFeedbackToken}/>;
  }

  if (isLoading) {
    return <PaymentLoader />;
  }
  if (isExpire) {
    return <AdditionalPaymentLoader />;
  }

  return (
    <Page title="Payment" className={classes.root}>
      <Card>
        <CardHeader title="" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <h2>Enter Payment Detail Below :</h2>
            </Grid>
            <Grid item md={6}>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  address: gaddress.address,
                  city: gaddress.city?gaddress.city:"",
                  state: gaddress.state ?gaddress.state:"",
                  country: gaddress.country ? gaddress.country :"" ,
                  zip: "",
                  phone: "",
                }}
                onSubmit={(values, actions) => {
                  if (Object.keys(gaddress).length === 0) {
                    actions.setErrors({
                      address:
                        "Please select any place from google address dropdown",
                    });
                  }
                }}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  // isValid,
                  handleBlur,
                  dirty,
                  handleChange,
                  handleSubmit,
                  validateForm,
                  setErrors,
                  setFieldValue,
                }) => {
                  return (
                    <form
                      onSubmit={handleSubmit}
                      // <---new function--->
                      onChange={async () => {
                        localStorage.setItem(
                          "PaymentData",
                          JSON.stringify(values)
                        );
                        await validateForm().then((formErrors) => {
                          // Check if there are any errors related to the holderName field
                          const firstName = formErrors.firstName !== undefined;
                          const lastName = formErrors.lastName !== undefined;
                          const address = formErrors.address !== undefined;
                          const city = formErrors.city !== undefined;
                          const state = formErrors.state !== undefined;
                          const country = formErrors.country !== undefined;
                          const zip = formErrors.zip !== undefined;
                          const phone = formErrors.phone !== undefined;

                          // Set errors based on form validation results
                          setErrors(formErrors);

                          // Update validity based on formErrors and whether holderName is not empty
                          if (
                            !firstName &&
                            !lastName &&
                            !address &&
                            !city &&
                            !state &&
                            !country &&
                            !zip &&
                            !phone &&
                            values.firstName.trim() !== "" &&
                            values.lastName.trim() !== "" &&
                            values.address.trim() !== "" &&
                            values.city.trim() !== "" &&
                            values.state.trim() !== "" &&
                            values.country.trim() !== "" &&
                            values.zip.trim() !== "" &&
                            values.phone.trim() !== ""
                          ) {
                            if (Object.keys(gaddress).length === 0) {
                              setValid(false);
                              setErrors({
                                address:
                                  "Please select any place from google address dropdown",
                              });
                            } else {
                              setValid(true);
                            }

                            //setValid(true);
                          } else {
                            setValid(false);
                          }
                        });
                      }}
                      onBlur={async () => {
                        localStorage.setItem(
                          "PaymentData",
                          JSON.stringify(values)
                        );
                        await validateForm().then((formErrors) => {
                          // Check if there are any errors related to the holderName field
                          const firstName = formErrors.firstName !== undefined;
                          const lastName = formErrors.lastName !== undefined;
                          const address = formErrors.address !== undefined;
                          const city = formErrors.city !== undefined;
                          const state = formErrors.state !== undefined;
                          const country = formErrors.country !== undefined;
                          const zip = formErrors.zip !== undefined;
                          const phone = formErrors.phone !== undefined;

                          // Set errors based on form validation results
                          setErrors(formErrors);

                          // Update validity based on formErrors and whether holderName is not empty
                          if (
                            !firstName &&
                            !lastName &&
                            !address &&
                            !city &&
                            !state &&
                            !country &&
                            !zip &&
                            !phone &&
                            values.firstName.trim() !== "" &&
                            values.lastName.trim() !== "" &&
                            values.address.trim() !== "" &&
                            values.city.trim() !== "" &&
                            values.state.trim() !== "" &&
                            values.country.trim() !== "" &&
                            values.zip.trim() !== "" &&
                            values.phone.trim() !== ""
                          ) {
                            if (Object.keys(gaddress).length === 0) {
                              setValid(false);
                              setErrors({
                                address:
                                  "Please select any place from google address dropdown",
                              });
                            } else {
                              setValid(true);
                            }

                            //setValid(true);
                          } else {
                            setValid(false);
                          }
                        });
                      }}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <h3>Billing Information</h3>
                        </div>
                      </div>

                      {/* <Grid item md={12} xs={12}>
                  <div className="payment-icon">
                    <RadioGroup
                      aria-label="gender"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value={"ture"}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="ture"
                        control={<Radio />}
                        label="Credit Card"
                      />
                    </RadioGroup>
                    <svg {...getCardImageProps({ images })} />
                    {/* <img src="img/payment1.png" class="pcard" /> }
                  </div>
                </Grid> */}
                      <Row>
                        <Col md={12} className="p-0 mt-2">
                          <div className="line-pay"></div>
                        </Col>
                      </Row>
                      <Grid container spacing={3}>
                        <Grid item md={12}>
                          <Grid container spacing={3}>
                            <Grid item md={6} xs={6}>
                              <TextInput
                                fullWidth
                                label="First Name"
                                placeholder="First Name"
                                name="firstName"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                autoFocus={true}
                              />
                            </Grid>
                            <Grid item md={6} xs={6}>
                              <TextInput
                                fullWidth
                                label="Last Name"
                                placeholder="Last Name"
                                name="lastName"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                              />
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <AddressBox
                                name="address"
                                placeholder="Address"
                                setAddress={setGAddress}
                                setFieldValue={setFieldValue}
                                errors={errors}
                              />
                            </Grid>
                            <Grid item md={6} xs={6}>
                              <TextInput
                                fullWidth
                                name="city"
                                label="City"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={6}>
                              <TextInput
                                fullWidth
                                name="state"
                                label="State"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={6}>
                              <TextInput
                                fullWidth
                                name="country"
                                label="Country"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={6}>
                              <TextInput
                                fullWidth
                                name="zip"
                                label="Zip"
                                variant="outlined"
                              />
                            </Grid>

                            <Grid item md={6} xs={6}>
                              <TextInput
                                name="phone"
                                label="Phone Number"
                                variant="outlined"
                                onChange={(event) => {
                                  setFieldValue("phone", event.target.value);
                                }}
                                InputProps={{
                                  inputComponent: TextMaskCustom,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <div className="row">
                        <div className="col-md-12 mt-4">
                          <div className="d-flex justify-content-center">
                            {/* <Button type="submit">Verify</Button> */}
                          </div>
                        </div>
                      </div>

                      {/* terms and button */}
                      <Grid item md={12} xs={12}>
                        <Box display="flex" justifyContent="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={isAgree}
                                onChange={handleChangeChk}
                                color="primary"
                              />
                            }
                            label={
                              <>
                                I agree to Fernwood{" "}
                                <span className="purple">
                                  {" "}
                                  <a
                                    className="gold paymentlink"
                                    href="https://www.fernwood.com/terms-of-service"
                                  >
                                    Terms and conditions
                                  </a>
                                </span>{" "}
                                and{" "}
                                <span className="purple">
                                  <a
                                    className="gold paymentlink"
                                    href="https://www.fernwood.com/privacy-policy"
                                  >
                                    Privacy policy{" "}
                                  </a>
                                </span>{" "}
                              </>
                            }
                          />
                        </Box>
                        <div className="form-check">
                          {isValid && isAgree ? (
                            // paymentToken ? (
                            //   <AcceptHosted
                            //     formToken={paymentToken}
                            //     integration="iframe"
                            //     onTransactionResponse={(response) => {
                            //       console.log(
                            //         "response =>",
                            //         JSON.stringify(response, null, 2) + "\n"
                            //       );
                            //     }}

                            //   >
                            //     <AcceptHosted.Button className="btn-purchase">
                            //       Complete Purchase
                            //     </AcceptHosted.Button>
                            //     <AcceptHosted.IFrameBackdrop />
                            //     <AcceptHosted.IFrameContainer>
                            //       <AcceptHosted.IFrame />
                            //     </AcceptHosted.IFrameContainer>
                            //   </AcceptHosted>
                            // ) : (

                            <HostedForm
                              authData={{
                                apiLoginID: "356A4ZjPwc",
                                clientKey:
                                  "5YUzHZpy4R97xDZR4EeFm6Edw6RA8v7xQadN9MfV4H69ksdBna76CF3rSErvEAmx",
                              }}
                              environment="SANDBOX"
                              billingAddressOptions={{
                                show: false,
                                required: false,
                              }}
                              onSubmit={makePayment}
                              buttonClassName="btn-purchase"
                              buttonText="Complete Purchase"
                            />
                          ) : (
                            // <button
                            //   type="button"
                            //   className="btn-purchase"
                            //   onClick={() => this.makePayment()}
                            // >
                            //   Complete Purchase
                            // </button>
                            <button
                              type="button"
                              className="btn-purchase"
                              onClick={() => console.log("missing info")}
                              disabled={true}
                            >
                              Complete Purchase
                            </button>
                          )}
                          {/* {isValid === false || isAgree === false ? (
                        <button
                          type="button"
                          className="btn-purchase"
                          onClick={() => console.log("missing info")}
                          disabled={true}
                        >
                          Complete Purchase
                        </button>
                      ) : null} */}
                        </div>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Grid>

            {/* order section */}
            <Grid item md={6}>
              <Card>
                <CardHeader title="Your Order" />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12} className="order-total">
                      <ul>
                        {categoryData &&
                          categoryData.map((item, index) => {
                            if (item !== null) {
                              if (
                                item.catId === 1 &&
                                shippingAddonData !== undefined &&
                                shippingAddonData.state === "California"
                              ) {
                                shipping = item.shipping_inside * item.quantity;
                                itemShipping += shipping;
                                itemPricewithShipTax =
                                  ((item.price * item.quantity + shipping) *
                                    item.tax_per) /
                                  100;
                                itemTax += Number(
                                  itemPricewithShipTax.toFixed(2)
                                );
                              } else if (
                                item.catId === 3 &&
                                shippingAddonData !== undefined &&
                                shippingAddonData.state === "California"
                              ) {
                                shipping = item.shipping_inside * item.quantity;
                                itemShipping += shipping;
                                itemPricewithShipTax =
                                  ((item.price * item.quantity + shipping) *
                                    item.tax_per) /
                                  100;
                                itemTax += Number(
                                  itemPricewithShipTax.toFixed(2)
                                );
                              } else if (
                                item.catId === 30 &&
                                shippingAddonData !== undefined &&
                                shippingAddonData.state === "California"
                              ) {
                                shipping = item.shipping_inside * item.quantity;
                                itemShipping += shipping;
                                itemPricewithShipTax =
                                  ((item.price * item.quantity + shipping) *
                                    item.tax_per) /
                                  100;
                                itemTax += Number(
                                  itemPricewithShipTax.toFixed(2)
                                );
                              } else if (
                                item.catId === 1 &&
                                shippingAddonData !== undefined &&
                                shippingAddonData.state !== undefined &&
                                shippingAddonData.state !== "" &&
                                shippingAddonData.state !== "California"
                              ) {
                                shipping =
                                  item.shipping_outside * item.quantity;
                                itemShipping += shipping;
                                itemPricewithShipTax =
                                  ((item.price * item.quantity + shipping) *
                                    item.tax_per) /
                                  100;
                                itemTax += Number(
                                  itemPricewithShipTax.toFixed(2)
                                );
                              } else if (
                                item.catId === 3 &&
                                shippingAddonData !== undefined &&
                                shippingAddonData.state !== undefined &&
                                shippingAddonData.state !== "" &&
                                shippingAddonData.state !== "California"
                              ) {
                                shipping =
                                  item.shipping_outside * item.quantity;
                                itemShipping += shipping;
                                itemPricewithShipTax =
                                  ((item.price * item.quantity + shipping) *
                                    item.tax_per) /
                                  100;
                                itemTax += Number(
                                  itemPricewithShipTax.toFixed(2)
                                );
                              } else if (
                                item.catId === 30 &&
                                shippingAddonData !== undefined &&
                                shippingAddonData.state !== undefined &&
                                shippingAddonData.state !== "California"
                              ) {
                                shipping =
                                  item.shipping_outside * item.quantity;
                                itemShipping += shipping;
                                itemPricewithShipTax =
                                  ((item.price * item.quantity + shipping) *
                                    item.tax_per) /
                                  100;
                                itemTax += Number(
                                  itemPricewithShipTax.toFixed(2)
                                );
                              } else {
                                itemPricewithShipTax =
                                  (item.price * item.quantity * item.tax_per) /
                                  100;
                                itemTax += Number(
                                  itemPricewithShipTax.toFixed(2)
                                );
                              }

                              serviceprice = item.included
                                ? item.price * (item.quantity - 1)
                                : item.price * item.quantity;
                              total = total + serviceprice;
                              if (index === categoryData.length - 1) {
                                totaltax = totaltax + itemTax;

                                total = total + itemShipping + totaltax;
                              }
                              if (item.catId === 31 && item.name !== "Yes") {
                                return null;
                              } else {
                                return (
                                  <li key={index}>
                                    <p className="total-label">
                                      {item.catId === 32 ? (
                                        <>
                                          {`Service Area (${
                                            item.quantity > 0
                                              ? item.quantity
                                              : "0"
                                          } miles)`}
                                          <br />
                                          {item.address}
                                        </>
                                      ) : item.catId === 32 ? (
                                        `Service Area (${item.quantity} miles)`
                                      ) : item.catId === 31 ? (
                                        `Implant Disposition (${item.quantity})`
                                      ) : (
                                        <>
                                          {item.name}
                                          <br />
                                          {item.address}
                                        </>
                                      )}{" "}
                                      {item.type
                                        ? "$" +
                                          item.price +
                                          " x " +
                                          item.quantity
                                        : ""}
                                      {item.included ? " (1 included)" : ""}
                                    </p>

                                    <p className="total-price">
                                      {item.catId !== 28
                                        ? item.id === 9
                                          ? "$0.00"
                                          : item.id === 10
                                          ? "$" +
                                            parseFloat(itemShipping).toFixed(2)
                                          : // : item.id === 77
                                          //  ||
                                          item.id === 76
                                          ? "$0.00"
                                          : item.id === 77 &&
                                            item.quantity <= 20
                                          ? "$0.00"
                                          : item.id === 2
                                          ? "$" +
                                            parseFloat(
                                              item.price * item.quantity
                                            ).toFixed(2)
                                          : item.included
                                          ? item.price && item.price > 0
                                            ? "$" +
                                              parseFloat(
                                                item.price * (item.quantity - 1)
                                              ).toFixed(2)
                                            : ""
                                          : item.price && item.price > 0
                                          ? "$" +
                                            parseFloat(
                                              item.price * item.quantity
                                            ).toFixed(2)
                                          : ""
                                        : item.catId === 28
                                        ? item.placeholder_price > 0
                                          ? "$" +
                                            parseFloat(
                                              item.placeholder_price
                                            ).toFixed(2)
                                          : item.price > 0
                                          ? "$" +
                                            parseFloat(item.price).toFixed(2)
                                          : ""
                                        : ""}
                                    </p>
                                  </li>
                                );
                              }
                            }
                          })}
                        {cirtificateCount > 0 && (
                          <li>
                            <p className="total-label">
                              Death certificates ({cirtificateCount}):
                            </p>
                            <p className="total-price">
                              + $
                              {parseFloat(
                                cirtificateCount * certificatePrice
                              ).toFixed(2)}
                            </p>
                          </li>
                        )}

                        <li>
                          <p className="total-label">Tax Calculation:</p>
                          <p className="total-price">
                            ${parseFloat(totaltax).toFixed(2)}
                          </p>
                        </li>
                        <li>
                          <p className="total-label">Total:</p>
                          <p className="total-price">
                            ${parseFloat(total).toFixed(2)}
                          </p>
                        </li>
                        {Discount !== 0 && Discount < total && (
                          <li>
                            <p className="total-label">Discount:</p>
                            <p className="total-price">
                              - ${parseFloat(Discount).toFixed(2)}
                            </p>
                          </li>
                        )}
                        {Discount !== 0 && Discount < total && (
                          <li>
                            <p className="total-label">Payable Amount:</p>
                            <p className="total-price">
                              ${parseFloat(total - Discount).toFixed(2)}
                            </p>
                          </li>
                        )}
                      </ul>
                    </Grid>
                    <Grid container spacing={3} className="discount-box">
                      <Grid
                        item
                        md={12}
                        style={{
                          padding: 20,
                          justifyContent: "space-between",
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <TextField
                            fullWidth
                            label="Enter Service Discount"
                            name="promocode"
                            type="text"
                            margin="normal"
                            variant="outlined"
                            value={PromoCode}
                            onChange={(e) => setPromocode(e.target.value)}
                            readOnly={Discount !== 0 ? true : false}
                          />
                          {resStatus === 200 && (
                            <div style={{ color: "green" }}>{resMessage}</div>
                          )}
                          {resStatus !== 200 && resStatus !== null && (
                            <div style={{ color: "red" }}>{resMessage}</div>
                          )}
                        </Box>
                        <Button
                          color="primary"
                          variant="contained"
                          type="button"
                          onClick={() => CheckCode()}
                          disabled={promoLoader}
                          style={{
                            height: "100%",
                            marginLeft: "10px",
                          }}
                        >
                          {promoLoader
                            ? "Checking"
                            : Discount !== 0
                            ? "Remove"
                            : "Check"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  crtPrice: state.package.certificatePrice,
  categoryData: state.package.categoryData,
});

const mapDispatchToProps = {
  setCertificatePrice,
  setPackageData,
  emptyPackageData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditonalPaymentView);
