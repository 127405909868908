import { Formik } from "formik";
import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import TextInput from "src/Components/Formik/TextInput";
import CheckInput from "src/Components/Formik/CheckInput";
import { Link } from "react-router-dom";
import CenterLoader from "src/Components/Loader/CenterLoader";
import * as Messages from "src/constants/Messages";
import { Grid, Button, FormHelperText } from "@material-ui/core";
import PasswordInput from "src/Components/Formik/PasswordInput";

const initialValues = { email: "", /*password: "",*/ remember: false };

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(Messages.VALID_EMAIL)
    .max(100, Messages.MAX_Email)
    .required(Messages.FIELD_REQUIRED),
  // password: Yup.string()
  //   .required(Messages.FIELD_REQUIRED),
});
export default function LoginForm({
  handleLogin,
  errormessage,
  errormessagecolor,
  isLoading,
}) {
  const errormsg = errormessage;
  if (isLoading) {
    return (
      <div>
        <CenterLoader />
      </div>
    );
  }
  return (
    <div className="Login_form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          handleLogin(values, actions);
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          {
            return (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextInput
                      name="email"
                      placeholder="email"
                      type="email"
                      label="Email"
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                  <PasswordInput
                    name="password"
                    label="Password"
                  />
                </Grid>*/}
                </Grid>
                <Grid container direction="row" style={{ paddingTop: 10 }}>
                  <FormHelperText
                    error
                    style={{ color: errormessagecolor, textAlign: "left" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: errormsg,
                      }}
                    />
                  </FormHelperText>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item style={{ paddingTop: 10 }}>
                    <CheckInput
                      name="remember"
                      type="checkbox"
                      label="Remember Me"
                      size="lg"
                    />
                  </Grid>

                  <Grid item style={{ paddingTop: 10 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
                {/*<Container>
                  <div className="row">
                    <Link to="/forgot">Forgot Password</Link>
                  </div>
                </Container> */}
              </Form>
            );
          }
        }}
      </Formik>
    </div>
  );
}
