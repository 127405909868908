import React from "react";

export default function index({ progress, totprogress }) {
  const prog = progress * 100 / totprogress ;
  return (
    <div>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${prog}%` }}
          aria-valuenow="5"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        {/* <ProgressBar now={prog} className="progress-bar" /> */}
      </div>
      <p className="answered">{progress} of {totprogress} Section</p>
    </div>
  );
}
