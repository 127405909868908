import React from "react";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import * as Messages from "src/constants/Messages";

export default function PhonInputBox({
  answer,
  placeholder,
  setAnswer,
  error,
  setError,
}) {
  return (
    <div className="col-md-12 ans-fill">
      <Form.Group>
        <PhoneInput
          inputStyle={{ width: "inherit" }}
          country={"us"}
          value={"+" + answer}
          placeholder={placeholder}
          autoFocus={true}
          enableLongNumbers={true}
          inputProps={{
            name: "answer",
            autoFormat: true,
            autoFocus: true,
          }}
          onChange={(e) => setAnswer(e)}
          isValid={(inputNumber, country, countries) => {
            let length = inputNumber.length;
            if (length === 0 ) {
              setError(Messages.FIELD_REQUIRED);
            } else if (country.format.match(/\./g).length !== length) {
              setError(Messages.INVALID_MOBILE);
            } else {
              setError("");
            }
            return true;
          }}
        />
        {error !== "" && (
          <Form.Control.Feedback type="invalid" className=" d-block">
            {error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </div>
  );
}
