import React from "react";
import { Toast } from "react-bootstrap";
// import Toast from 'react-bootstrap/Toast';
import { createUseStyles } from "react-jss";
import { connect } from "react-redux";
import { setSnackbar } from "src/redux/actions";

const useStyles = createUseStyles({
    topRight: 
    {
        top: '6rem! important',
        right: '12px',
        transition: 'transform .6s ease-in-out',
        animation: 'toast-in-right .7s'
    }
});
const CustomToastMessageView = (props) => {
    const classes = useStyles();
    const handleClose = (event, reason) => {
        props.setSnackbar(false, props.type, props.message);
    };

    let type = '';

    if (props.type === 200) {
        type = "Success";
    } else {
        type = "Error";
    }
    
    return (
        <Toast
            show={props.visible}
            style={{
                position: 'absolute'
            }}
            className={classes.topRight}
            autohide
            delay={5000}
            onClose={handleClose}
        >
            <Toast.Header>
                <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                <strong className="mr-auto">{type}</strong>
            </Toast.Header>
            <Toast.Body>{props.message}</Toast.Body>
        </Toast>
    )
} 

const mapStateToProps = (state) => ({
    type: state.global.snackBarType,
    message: state.global.snackBarMessage,
});

const mapDispatchToProps = {
    setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomToastMessageView);
