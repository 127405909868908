import React from "react";

export default function RadioBox({ options, answer, error, getQuestion }) {
  return (
    <div className="col-md-12">
      {options.map((option, index) => {
        let classString = "text-left btn col-12 mb-1 btn-custom";
        if (option.id === answer) {
          classString = classString + " active";
        }
        return (
          <div className="box" key={index}>
            <div className="">
              <div
                className={classString}
                onClick={() => getQuestion(true, option.id)}
              >
                {option.answer}
              </div>
            </div>
          </div>
        );
      })}
      {error !== "" && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  );
}
