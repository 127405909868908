import React, { useEffect, useState } from "react";
import CenterLoader from "src/Components/Loader/CenterLoader";
import { connect } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, useLocation } from "react-router";
import { login } from "src/redux/actions";
import { Login } from "src/Services/Login";
import { useNavigate } from "react-router-dom";

export const LoginVerifyAccountView = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const paths = location.pathname.split("/");
  const loginverifyToken = paths[2];
  const expiretime = new URLSearchParams(useLocation().search).get("expires");
  const expiresignature = new URLSearchParams(useLocation().search).get(
    "signature"
  );

  const [isLoading, setisLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const loginToken =
    loginverifyToken +
    "?expires=" +
    expiretime +
    "&signature=" +
    expiresignature;

  useEffect(() => {
    const handleLogin = async (values, actions) => {
      await Login("GET", "user/auth/login_verify/" + loginToken).then(
        async (response) => {
          const { status } = response;
          if (status === 200) {
            // console.log(response);
            const { access_token, token_type } = response.data;
            await localStorage.setItem("token", access_token);
            await props.login(access_token, token_type, response.data);
            navigate("/app/check-verified/login");
            //props.setSnackbar(true, response.status, response.message);
          } else {
            setErrorMessage(response.message);
            setisLoading(false);
            //actions.setErrors({ email: response.message });
            //alert("somthing Wrong");
          }
        }
      );
    };
    handleLogin();
  }, [loginverifyToken]);

  if (isLoading) {
    return <CenterLoader />;
  }

  return (
    <Container>
      <Row>
        <Col>
          <div className="thanks-section">
            <div className="thank-box">
              {errorMessage ? <p className="">{errorMessage}</p> : ""}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

// export default LoginVerifyAccountView;
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  login,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginVerifyAccountView);
