import { Grid } from "@material-ui/core";
import React from "react";

import Lottie from "react-lottie";
import animation from "../../../Assets/img/FernWood_Loading_color2_new.json";

export default function AdditionalPaymentLoader({ minheight }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
    //   style={{
    //     display: "flex",
    //     alignItems: "center",
    //     flexDirection: "column",
    //     margin: "auto",
    //   }}
    >
      {/* <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: minheight ? minheight : "250px",
          top: "45%",
        }}
      >
        <Lottie
          options={defaultOptions}
          style={{ height: 100, alignSelf: "center" }}
        />
      </Grid> */}

      <div className="thanks-section">
        <div className="thank-box-inovice">
          <p className="thanks-heading">Your payment link has expired.</p>
          <p className="thanks-heading-description">
            To generate a new one, please contact us at{" "}
            <a href="mailto:cremation@fernwood.com">cremation@fernwood.com</a>{" "}
            or call us at (415) 707-3393.
          </p>
        </div>
      </div>
    </div>
  );
}
