import { Grid } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'

export const index = (props) => {
    return (
        <Grid container className="main-body">
            <Grid item md={6} xs={12} className="scroll-md theme-bg-color">

            </Grid>
            <Grid item md={6} xs={12} className="scroll-md">

            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(index)

