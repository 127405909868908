export const SESSION_LOGIN                  = "SESSION_LOGIN";
export const SESSION_LOGOUT                 = "SESSION_LOGOUT";
export const SESSION_SET_IMAGE              = "SESSION_SET_IMAGE";
export const SESSION_SET_VERIFY             = "SESSION_SET_VERIFY";
export const SESSION_SET_PAYMENTCHK         = "SESSION_SET_PAYMENTCHK";
export const SESSION_SET_EXTRAMAILES_PAYMENTCHK         = "SESSION_SET_EXTRAMAILES_PAYMENTCHK";
export const SESSION_SET_EXTRAMAILES_PAYMENTCHK_TEM     = "SESSION_SET_EXTRAMAILES_PAYMENTCHK_TEM";
export const SESSION_SET_QUESTCONDITIONCHK  = "SESSION_SET_QUESTCONDITIONCHK";
export const SESSION_SET_PRENEEDDONE        = "SESSION_SET_PRENEEDDONE";
export const SESSION_SET_PACKAGE            = "SESSION_SET_PACKAGE";
export const SESSION_SET_POST_QA_CHECK      = "SESSION_SET_POST_QA_CHECK";
export const SESSION_SET_EXTRA_MAILS_CHECK  = "SESSION_SET_EXTRA_MAILS_CHECK";

export const login = (token, type, data) => (dispatch) => {
  dispatch({
    type: SESSION_LOGIN,
    payload: {
      token: token,
      token_type: type,
      data: data,
    },
  });
};

export const logout = () => (dispatch) =>
  dispatch({
    type: SESSION_LOGOUT,
  });

export const setImage = (image) => (dispatch) => 
  dispatch({
    type: SESSION_SET_IMAGE,
    payload: {
      data: image,
    },
  });
export const setVerify = (arg) => (dispatch) => {
  dispatch({
    type: SESSION_SET_VERIFY,
    payload: {
      data: arg,
    },
  });
};
export const setPaymentChk = (arg) => (dispatch) => {
  dispatch({
    type: SESSION_SET_PAYMENTCHK,
    payload: {
      data: arg,
    },
  });
};
export const setPaymentExtraMailsChk = (arg) => (dispatch) => {
  dispatch({
    type: SESSION_SET_EXTRAMAILES_PAYMENTCHK,
    payload: {
      data: arg,
    },
  });
};
export const setPaymentExtraMailsChkTem = (arg) => (dispatch) => {
  dispatch({
    type: SESSION_SET_EXTRAMAILES_PAYMENTCHK_TEM,
    payload: {
      data: arg,
    },
  });
};
export const setQuestConditionChk = (arg) => (dispatch) => {
  dispatch({
    type: SESSION_SET_QUESTCONDITIONCHK,
    payload: {
      data: arg,
    },
  });
};
export const setPreNeedDoneChk = (arg) => (dispatch) => {
  dispatch({
    type: SESSION_SET_PRENEEDDONE,
    payload: {
      data: arg,
    },
  });
};
export const setPackageSelected = (arg) => (dispatch) => {
  dispatch({
    type: SESSION_SET_PACKAGE,
    payload: {
      data: arg,
    },
  });
};
export const setPostQaCheck = (arg) => (dispatch) => {
  dispatch({
    type: SESSION_SET_POST_QA_CHECK,
    payload: {
      data: arg,
    },
  });
};
export const checkExtraMails = (arg) => (dispatch) => {
  dispatch({
    type: SESSION_SET_EXTRA_MAILS_CHECK,
    payload: {
      data: arg,
    },
  });
};
