import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import CenterLoader from "src/Components/Loader/CenterLoader";
import { Download } from "@mui/icons-material";
import { Grid } from "@material-ui/core";
import { Http } from "src/Services/Http";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ExtraMailesInvoice from "../MailesChargesPaymentView/ExtraMailesInvoice";

export const ExtraMails = (props) => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);

  const [Values, setValues] = useState({
    name: "",
    email: "",
    invoiceno: "",
    referenceno: "",
    invoice_date: "",
    title: "",
    total_amount_paid: "",
    total_mile_count: "",
    amount_per_extra_mile: "",
    tax_amount: "",
    total_amount: "",
    discount_amount: "",
    invoicepdf: "",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await Http("POST", "invoicedetails", {
          isExtramile: 1,
        });
        // console.log("data==>", response);
        setValues({
          name: response.name,
          email: response.email,
          invoiceno: response.invoiceno,
          referenceno: response.referenceno,
          invoice_date: response.invoice_date,
          title: response.title,
          total_mile_count: response.total_mile_count,
          amount_per_extra_mile: response.amount_per_extra_mile,
          total_amount_paid: response.total_amount_paid,
          services: response.services,
          sub_total: response.sub_total,
          tax_amount: response.tax_amount,
          total_amount: response.total_amount,
          discount_amount: response.discount_amount,
          invoicepdf: response.invoicepdf,
        });
        setisLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchUsers();
  }, []);

  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundImage: `url("/img/Rectangle24.png")`,
        backgroundSize: "cover",
        height: "100%",
        backgroundPosition: "center",
        position: "relative",
        zIndex: "0",
      }}
      className="homesection_main"
    >
      <Container>
        <Row>
          <Col>
            <div className="success-section-extramiles">
              <Grid
                container
                style={{
                  // marginTop: 100,
                  textAlign: "Center",
                }}
              >
                <Grid item md={12} xs={12} className="invoice-rightcircle">
                  <CheckCircleOutlineIcon
                    sx={{ fontSize: 100, color: "grey" }}
                  />
                </Grid>
                <Grid item md={12} xs={12} style={{ color: "black" }}>
                  Your application has been submitted successfully and is
                  currently under careful review by our dedicated counselors. We
                  are committed to thoroughly understanding your needs and
                  preferences and to ensure completeness of your application.
                  <br />
                  <br />
                  We aim to complete reviews within 12-24 hours and will email
                  the final documents for signing upon completion. If additional
                  information is needed to finalize your arrangements, we'll
                  reach out to you directly.
                  <br />
                  <br />
                  Your peace of mind is our top priority. Please don't hesitate
                  to reach out to us at{" "}
                  <a
                    href="mailto:technical-support@fernwood.com"
                    style={{ color: "#D8B34F" }}
                  >
                    technical-support@fernwood.com
                  </a>{" "}
                  if you have any questions or need assistance.
                </Grid>
              </Grid>
            </div>
          </Col>
        </Row>
        {isLoading ? (
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: 400,
            }}
          >
            <CenterLoader />
          </Grid>
        ) : (
          <Row>
            <Col>
              <div className="thanks-section-invoice">
                <div className="invoice-box">
                  <div className="logo-invoiceid">
                    <div className=" invoice-logo text-dark">
                      <Image src="/img/logo/f_invoice-logo.png" alt="Logo" />
                    </div>
                    <div className="invoice-id">
                      <h6> Invoice</h6>
                      <p>{Values.invoiceno}</p>
                    </div>
                  </div>

                  <div className="invoice-content-custom">
                    <div className="invoice-child-content-custom">
                      <h6 className="invoice-h6">Invoice Date</h6>
                      <p>{Values.invoice_date}</p>
                    </div>
                    <div className="invoice-child-content-custom">
                      <h6 className="invoice-h6"> Billed to</h6>
                      <p style={{ fontWeight: "600" }}>{Values.name}</p>
                      <p>{Values.email}</p>
                    </div>
                    {/* <div className="invoice-child-content-custom">
                      <h6 className="invoice-h6">Reference No</h6>
                      <p>{Values.referenceno}</p>
                    </div> */}
                  </div>

                  <ExtraMailesInvoice formValues={Values} />

                  <div>
                    <Button
                      className="btn-apply mx-0 mt-1 invoice-page-button"
                      style={{ color: "#d8b350" }}
                    >
                      <Download style={{ marginRight: "20px" }} />
                      <a href={Values.invoicepdf} rel="noopener noreferrer" target="_blank" >Download Invoice</a>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Grid>
  );
};

export default ExtraMails;
