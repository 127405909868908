import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const ExtraMailesInvoice = ({ formValues }) => {
  const {
    title,
    amount_per_extra_mile,
    total_mile_count,
    total_amount_paid,
    tax_amount,
    discount_amount,
  } = formValues;

  // const ccyFormat = (amount) => {
  //   if (typeof amount === "number") {
  //     return `$${amount.toFixed(2)}`;
  //   } else {
  //     return amount;
  //   }
  // };

  const ccyFormat = (amount) => {
    if (typeof amount !== "number" || amount === 0) {
      return "—"; // Return "-" if amount is not a number or is 0
    }
    return `$${amount.toFixed(2)}`;
  };

  return (
    <TableContainer>
      <Table className="tableContainer" aria-label="spanning table">
        <TableHead className="tableHead-custom">
          <TableRow className="tablerowHead-custom">
            <TableCell className="table-col-custom">Description</TableCell>
            <TableCell align="right">Extra Mile Count</TableCell>
            <TableCell align="right">Amount Per Extra Mile</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{title}</TableCell>
            <TableCell align="right">{total_mile_count}</TableCell>
            <TableCell align="right">{ccyFormat(amount_per_extra_mile)}</TableCell>
            <TableCell align="right">{ccyFormat(total_amount_paid)}</TableCell>
          </TableRow>

          <TableRow className="invoice-billing-content-custome">
            <TableCell rowSpan={4} />
            <TableCell colSpan={2}  align="right">Subtotal</TableCell>
            <TableCell align="right">{ccyFormat(total_amount_paid)}</TableCell>
          </TableRow>
          <TableRow className="invoice-billing-content-custome">
            <TableCell colSpan={2}  align="right">Tax</TableCell>
            <TableCell align="right">{ccyFormat(tax_amount)}</TableCell>
          </TableRow>
          <TableRow className="invoice-billing-content-custome">
            <TableCell colSpan={2}  align="right">Service Discount</TableCell>
            <TableCell align="right">{ccyFormat(discount_amount)}</TableCell>
          </TableRow>
          <TableRow className="invoice-billing-total-border-custome">
            <TableCell colSpan={2}  align="right">Total</TableCell>
            <TableCell align="right" className="total-voice-amount">
              {ccyFormat(total_amount_paid)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExtraMailesInvoice;
