import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const PaymentInvoice = ({ formValues }) => {
  const {
    packagename,
    packageprice,
    packageqty,
    packagetotalprice,
    services,
    sub_total,
    tax_amount,
    total_amount,
    discount_amount,
    certificate_base_price,
    certificate_count,
    certificate_total_price,
    promocode,
  } = formValues;

  // const ccyFormat = (amount) => {
  //   if (typeof amount === "number") {
  //     return `$${amount.toFixed(2)}`;
  //   } else {
  //     return amount;
  //   }
  // };

  let shipping = 0;
  let shippingAddonData = services.find((item) => item.service_id === 10);
  const ccyFormat = (amount) => {
    if (typeof amount !== "number" || amount === 0) {
      return "—"; // Return "-" if amount is not a number or is 0
    }
    return `$${amount.toFixed(2)}`;
  };

  return (
    <TableContainer>
      <Table className="tableContainer" aria-label="spanning table">
        <TableHead className="tableHead-custom">
          <TableRow className="tablerowHead-custom">
            <TableCell className="table-col-custom">Description</TableCell>
            <TableCell align="right">Qty.</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packagename !== '' && (
            <TableRow>
              <TableCell>{packagename}</TableCell>
              <TableCell align="right">{packageqty}</TableCell>
              <TableCell align="right">{packageprice}</TableCell>
              <TableCell align="right">{packagetotalprice}</TableCell>
            </TableRow>
          )}
          {services &&
            services
              .filter(
                (service) =>
                  service.catId !== 28 &&
                  service.catId !== 32 &&
                  service.catId !== 31 &&
                  service.service_id !== 9 &&
                  service.service_id !== 10
              )
              .map((service, index) => (
                <TableRow key={index}>
                  {/* <TableCell>{service.catId === 31 ? service.category_name+ ' (' +service.service_name + ')': service.service_name}</TableCell> */}
                  <TableCell>
                    {service.catId === 31
                      ? service.category_name
                      : service.service_name}
                  </TableCell>
                  <TableCell align="right">{service.quantity}</TableCell>
                  <TableCell align="right">
                    {ccyFormat(service.base_price)}
                  </TableCell>
                  <TableCell align="right">
                    {ccyFormat(service.quantity * service.base_price)}
                  </TableCell>
                </TableRow>
              ))}

          {/* Pacemaker */}
          {services &&
            services
              .filter(
                (service) =>
                  service.catId === 31 && service.service_name !== "No"
              )
              .map((service, index) => (
                <TableRow key={index}>
                  {/* <TableCell>{service.catId === 31 ? service.category_name : service.service_name}</TableCell> */}
                  <TableCell>
                    {service.category_name === "Pacemaker"
                      ? "Implant Disposition"
                      : service.service_name}
                  </TableCell>
                  <TableCell align="right">{service.quantity}</TableCell>
                  <TableCell align="right">
                    {ccyFormat(service.base_price)}
                  </TableCell>
                  <TableCell align="right">
                    {ccyFormat(service.quantity * service.base_price)}
                  </TableCell>
                </TableRow>
              ))}
        { certificate_count > 0 &&
          <TableRow>
            <TableCell>Death Certificate</TableCell>
            <TableCell align="right">{certificate_count}</TableCell>
            <TableCell align="right">
              {ccyFormat(certificate_base_price)}
            </TableCell>
            <TableCell align="right">
              {ccyFormat(certificate_total_price)}
            </TableCell>
          </TableRow> 
          }

          {/* 
          {services &&
            services.map((service, index) => {
              // Filter out services with service_id 10 and 9
              if (service.service_id === 10 || service.service_id === 9) {
                return (
                  <TableRow
                    key={index}
                    className="invoice-billing-content-custome"
                  >
                    <TableCell style={{ fontWeight: "600" }}>
                      {service.category_name}
                    </TableCell>
                    <TableCell align="left" colSpan={2}>
                      <div>{service.service_name}</div>
                      <div>{service.address}</div>
                    </TableCell>

                    {services.map((item, index) => {
                      console.log("item", item);
                      // if (item.service_id === 10 || item.service_id === 9) {
                      if (item !== null) {
                        if (
                          item.catId === 1 &&
                          shippingAddonData !== undefined &&
                          shippingAddonData.state === "California"
                        ) {
                          shipping = shipping + 30 * item.quantity;
                        } else if (
                          item.catId === 3 &&
                          shippingAddonData !== undefined &&
                          shippingAddonData.state === "California"
                        ) {
                          shipping = shipping + 30 * item.quantity;
                        } else if (
                          item.catId === 30 &&
                          shippingAddonData !== undefined &&
                          shippingAddonData.state === "California"
                        ) {
                          shipping = shipping + 10 * item.quantity;
                        } else if (
                          item.catId === 1 &&
                          shippingAddonData !== undefined &&
                          shippingAddonData.state !== undefined &&
                          shippingAddonData.state !== "" &&
                          shippingAddonData.state !== "California"
                        ) {
                          shipping = shipping + 50 * item.quantity;
                        } else if (
                          item.catId === 3 &&
                          shippingAddonData !== undefined &&
                          shippingAddonData.state !== undefined &&
                          shippingAddonData.state !== "" &&
                          shippingAddonData.state !== "California"
                        ) {
                          shipping = shipping + 50 * item.quantity;
                        } else if (
                          item.catId === 30 &&
                          shippingAddonData !== undefined &&
                          shippingAddonData.state !== undefined &&
                          shippingAddonData.state !== "California"
                        ) {
                          shipping = shipping + 10 * item.quantity;
                        }
                        console.log("first", shipping);

                        return (
                          <TableCell key={index} align="right">
                            {item.service_id === 10
                              ? "$" + parseFloat(shipping).toFixed(2)
                              : item.included}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              }
              return null;
            })} */}

          {services &&
            services.map((service, index) => {
              // Filter out services with service_id 10 and 9
              if (service.service_id === 10 || service.service_id === 9) {
                let shipping = 0; // Initialize shipping cost for this service

                // Calculate shipping cost for this service based on conditions
                services.forEach((item) => {
                  if (item !== null) {
                    if (
                      item.catId === 1 &&
                      shippingAddonData !== undefined &&
                      shippingAddonData.state === "California"
                    ) {
                      shipping = shipping + 30 * item.quantity;
                    } else if (
                      item.catId === 3 &&
                      shippingAddonData !== undefined &&
                      shippingAddonData.state === "California"
                    ) {
                      shipping = shipping + 30 * item.quantity;
                    } else if (
                      item.catId === 30 &&
                      shippingAddonData !== undefined &&
                      shippingAddonData.state === "California"
                    ) {
                      shipping = shipping + 10 * item.quantity;
                    } else if (
                      item.catId === 1 &&
                      shippingAddonData !== undefined &&
                      shippingAddonData.state !== undefined &&
                      shippingAddonData.state !== "" &&
                      shippingAddonData.state !== "California"
                    ) {
                      shipping = shipping + 200 * item.quantity;
                    } else if (
                      item.catId === 3 &&
                      shippingAddonData !== undefined &&
                      shippingAddonData.state !== undefined &&
                      shippingAddonData.state !== "" &&
                      shippingAddonData.state !== "California"
                    ) {
                      shipping = shipping + 200 * item.quantity;
                    } else if (
                      item.catId === 30 &&
                      shippingAddonData !== undefined &&
                      shippingAddonData.state !== undefined &&
                      shippingAddonData.state !== "California"
                    ) {
                      shipping = shipping + 50 * item.quantity;
                    }
                  }
                });

                return (
                  <TableRow
                    key={index}
                    className="invoice-billing-content-custome"
                  >
                    {/* <TableCell style={{ fontWeight: "600" }}>
                      {service.category_name}
                    </TableCell> */}
                    <TableCell align="left" colSpan={3}>
                      <div style={{ fontWeight: "600" }}>
                        {service.category_name}
                      </div>
                      <div>{service.service_name}</div>
                      <div>{service.address}</div>
                    </TableCell>
                    <TableCell align="right">
                      {service.service_id === 10
                        ? `$${parseFloat(shipping).toFixed(2)}`
                        : `$${parseFloat(0).toFixed(2)}`}
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}

          {services &&
            services.map((service, index) => {
              // Filter out services with service_id 57 and 11
              // if (service.catId === 28 || service.service_id === 9) {
              if (service.catId === 28) {
                return (
                  <TableRow
                    key={index}
                    className="invoice-billing-content-custome"
                  >
                    <TableCell align="left" colSpan={3}>
                      <div style={{ fontWeight: "600" }}>
                        {service.category_name}
                      </div>
                      <div>{service.service_name}</div>
                    </TableCell>
                    <TableCell align="right">
                      {service.price > 0
                        ? // service.quantity <= 20 ? "0"
                          `$${parseFloat(service.price).toFixed(2)}`
                        : ""}
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}

          {services &&
            services.map((service, index) => {
              // Filter out services with service_id 10 and 9
              if (service.service_id === 76 || service.service_id === 77) {
                return (
                  <TableRow key={index}>
                    <TableCell align="left" colSpan={3}>
                      <div style={{ fontWeight: "600" }}>
                        {service.category_name}
                      </div>
                      <div>{
                        `${
                          service.quantity > 0 ? service.quantity : "0"
                        }  miles` /*service.service_name*/
                      }</div>
                      <div>{service.address}</div>
                    </TableCell>
                    <TableCell align="right">
                      {service.service_id === 77
                        ? // service.quantity <= 20 ? "0"
                          `$${parseFloat(
                            service.quantity <= 20 ? "0" : service.price
                          ).toFixed(2)}`
                        : `$${parseFloat(0).toFixed(2)}`}
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}

          <TableRow className="invoice-billing-content-custome">
            <TableCell rowSpan={4} />
            <TableCell colSpan={2} align="right">
              Subtotal
            </TableCell>
            <TableCell align="right">{ccyFormat(sub_total)}</TableCell>
          </TableRow>
          <TableRow className="invoice-billing-content-custome">
            <TableCell colSpan={2} align="right">
              Tax
            </TableCell>
            <TableCell align="right">{ccyFormat(tax_amount)}</TableCell>
          </TableRow>

          {discount_amount > 0 ? (
            <TableRow className="invoice-billing-content-custom">
              <TableCell colSpan={2} align="right">
                Service Discount
                <br />
                {promocode}
              </TableCell>
              <TableCell align="right">
                {discount_amount > 0
                  ? "-" + ccyFormat(discount_amount)
                  : ccyFormat(discount_amount)}
              </TableCell>
            </TableRow>
          ) : null}

          <TableRow className="invoice-billing-total-border-custome">
            <TableCell colSpan={2} align="right">
              Total
            </TableCell>
            <TableCell align="right" className="total-voice-amount">
              {ccyFormat(total_amount)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentInvoice;
