import { Formik } from "formik";
import React from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import * as Messages from "src/constants/Messages";
import { TextField } from "@material-ui/core";

const ValidationSchema = Yup.object().shape({
  first_name: Yup.string().required(Messages.FIELD_REQUIRED),
  last_name: Yup.string().required(Messages.FIELD_REQUIRED),
});
export default function NameBox({
  defaultValue,
  getQuestion,
  is_last,
  setAnswer,
  setAddress,
  NameError,
  setNameError,
}) {
  return (
    <div className="col-md-12 ans-fill">
      <Formik
        initialValues={{
          first_name:
            defaultValue.first_name !== undefined
              ? defaultValue.first_name
              : "",
          middle_name:
            defaultValue.middle_name !== undefined
              ? defaultValue.middle_name
              : "",
          last_name:
            defaultValue.last_name !== undefined ? defaultValue.last_name : "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={async (values, actions) => {
          setNameError({});
          await getQuestion(
            true,
            values.first_name +
              "," +
              values.middle_name +
              "," +
              values.last_name,
            is_last,
            values
          );
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setErrors,
        }) => {
          if (Object.keys(errors).length === 0) {
            if (Object.keys(NameError).length !== 0) {
              setErrors(NameError);
            }
          }
          setAddress(values);
          setAnswer(
            values.first_name +
              "," +
              values.middle_name +
              "," +
              values.last_name
          );
          return (
            <form onSubmit={handleSubmit}>
              <Form.Group>
                <TextField
                  name="first_name"
                  type="text"
                  label="First Name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  fullWidth={true}
                  autoFocus={true}
                  autoComplete=""
                  error={Boolean(touched.first_name && errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                />
                {/* <Form.Control
                  name="first_name"
                  type="text"
                  placeholder="First Name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.first_name ? true : false}
                /> */}
                {/* {errors.first_name !== "" ? (
                  <Form.Control.Feedback type="invalid" className=" d-block">
                    {errors.first_name}
                  </Form.Control.Feedback>
                ) : null} */}
              </Form.Group>
              <Form.Group>
                <TextField
                  name="middle_name"
                  type="text"
                  label="Middle Name (optional)"
                  value={values.middle_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  fullWidth={true}
                  autoComplete=""
                />
                {/* <Form.Control
                  name="middle_name"
                  type="text"
                  placeholder="Middle Name"
                  value={values.middle_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.middle_name ? true : false}
                /> */}
              </Form.Group>
              <Form.Group>
                <TextField
                  name="last_name"
                  type="text"
                  label="Last Name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  fullWidth={true}
                  autoComplete=""
                  error={Boolean(touched.last_name && errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                />
                {/* <Form.Control
                  name="last_name"
                  type="text"
                  placeholder="Last Name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.last_name ? true : false}
                /> */}
                {/* {errors.last_name !== "" ? (
                  <Form.Control.Feedback type="invalid" className=" d-block">
                    {errors.last_name}
                  </Form.Control.Feedback>
                ) : null} */}
              </Form.Group>

              <Button
                color="primary"
                variant="outline-light"
                type="submit"
                className="btn-block"
                //disabled={isSubmitting}
              >
                Continue
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}
