import React, { useState } from "react";
import { useField } from "formik";
import "react-phone-input-2/lib/material.css";
import PhoneInput from "react-phone-input-2";
import * as Messages from "src/constants/Messages";
import { Box, FormHelperText, makeStyles } from "@material-ui/core";
import { FormControl, FormLabel } from "@mui/material";
import "react-phone-input-2/lib/material.css";

const useStyles = makeStyles((theme) => ({
  phonInput: {
    height: 55,
  },
}));
const CustomPhoneInput = ({
  name,
  handleChange,
  handleBlur,
  setCheckPhoneNumber,
  ...otherProps
}) => {
  const [field, mata, helpers] = useField(name);
  const classes = useStyles();

  return (
    <Box component="span">
      <PhoneInput
        inputStyle={{
          width: "100%",
          color: "black",
          backgroundColor: "transparent",
        }}
        specialLabel="Phone Number"
        country={"us"}
        inputProps={{
          name: name,
        }}
        containerStyle={{ height: 50 }}
        inputClass={classes.phonInput}
        name={name}
        onChange={(e) => {
          helpers.setValue(e);
          handleChange(e);
          localStorage.setItem(name, e);
        }}
        onBlur={handleBlur}
        value={field.value}
        enableLongNumbers={true}
        isValid={(inputNumber, country, countries) => {
          let length = inputNumber.length;
          if (length === 0) {
            if (!mata.error) {
              helpers.setError(Messages.FIELD_REQUIRED);
              return false;
            }
          } else if (country.format.match(/\./g).length !== length) {
            if (!mata.error) {
              helpers.setError(Messages.INVALID_MOBILE);
              setCheckPhoneNumber(1);
              return true;
            }
            if (mata.touched) {
              return false;
            }
          } else {
            setCheckPhoneNumber(0);
          }
          /*else if (country.format.match(/\./g).length !== length) {
              if (!mata.error) {
                helpers.setError(Messages.INVALID_MOBILE);
                setCheckPhoneNumber(1);
              }
              if (mata.touched) {
                return false;
              }
            }else{
              setCheckPhoneNumber(0);
            }*/
          return true;
        }}
      />
      {mata.touched && mata.error && (
        <FormHelperText
          error={mata.touched && mata.error}
          margin="dense"
          className={"MuiFormHelperText-contained"}
        >
          {mata.error}
        </FormHelperText>
      )}
    </Box>
  );
};

export default CustomPhoneInput;

// import React from "react";
// import { useField } from "formik";
// import PhoneInput from "react-phone-input-2";
// import { Form } from "react-bootstrap";
// const CustomPhoneInput = ({
//   name,
//   handleChange,
//   handleBlur,
//   ...otherProps
// }) => {
//
//   return (
//     <Form.Group controlId={name}>

//       {mata.touched && mata.error ? (
//         <Form.Control.Feedback type="invalid">
//           {mata.error}
//         </Form.Control.Feedback>
//       ) : null}
//     </Form.Group>
//   );
// };

// export default CustomPhoneInput;
