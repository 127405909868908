import React from "react";
import { useField } from "formik";
import { TextField } from "@mui/material";

const TextInput = ({ name, ...otherProps }) => {
  const [field, meta] = useField(name);
  const configTextfield = {
    ...field,
    ...otherProps,
  };
  return (
    <TextField
      fullWidth
      label={otherProps.placeholder}
      variant="outlined"
      helperText={meta.touched && meta.error ? meta.error : ""}
      error={Boolean(meta.touched && meta.error)}
      autoComplete="off"
      {...configTextfield}
    />
  );
};

export default TextInput;
