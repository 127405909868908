import React from "react";
import { Link } from "react-router-dom";
// import logo from "src/Assets/img/logo/HF-ER-logo.svg";

export default function Header() {
  return (
    <div>
      <header className="py-3">
        <div className="row flex">
          <div className="col-md-12 col-12 col-sm-12">
            <a className="header-logo text-dark" href="/">
              <img src="/img/logo/Fernwood_logo.png" alt="Logo" />
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}
