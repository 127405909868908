import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Grid } from "@material-ui/core";

export default function AutoCompleteBox({
  options,
  answer,
  placeholder,
  setAnswer,
  setError,
}) {
  const [inputValue, setInputValue] = useState("");
  const [inputOptions, setInputOptions] = React.useState([{ answer: answer }]);
  return (
    <Grid container className="d-grid mx-auto">
      <Autocomplete
        id="disable-clearable"
        renderInput={(params) => (
          <TextField {...params} label={placeholder} variant="standard" />
        )}
        forcePopupIcon={false}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          if (newInputValue !== answer && newInputValue.length > 1) {
            setInputOptions(options);
          } else {
            setInputOptions([{ answer: answer }]);
            setInputOptions([{ answer }]);
          }
        }}
        defaultValue={{ answer: answer }}
        onChange={(event, newValue) => {
          setAnswer(newValue.answer);
          setError("");
        }}
        filterOptions={createFilterOptions({
          matchFrom: "start",
          stringify: (option) => option.answer,
        })}
        style={{ width: "100%" }}
        disableClearable
        options={inputOptions}
        getOptionLabel={(option) => option.answer || ""}
      />
    </Grid>
  );
}
