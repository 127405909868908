import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import * as Messages from "src/constants/Messages";
import CenterLoader from "src/Components/Loader/CenterLoader";
import TextInput from "src/Components/Formik/TextInput";
import {
  Grid,
  Button,
  Paper,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { Form as BootstrapForm } from "react-bootstrap";
import { useLocation } from "react-router";

const validationSchema = Yup.object().shape({
  star: Yup.string().required(Messages.FIELD_REQUIRED),
  first_name: Yup.string()
    .matches(
      /^[A-Za-z]+$/,
      "Name can only contain letters without spaces or numbers."
    )
    .required(Messages.FIELD_REQUIRED),
  middle_name: Yup.string().matches(
    /^[A-Za-z]+$/,
    "MiddleName can only contain letters without spaces or numbers."
  ),
  last_name: Yup.string()
    .matches(
      /^[A-Za-z]+$/,
      "LastName can only contain letters without spaces or numbers."
    )
    .required(Messages.FIELD_REQUIRED),
  remark: Yup.string()
    .max(1500, "Must be 1500 characters or less")
    .required(Messages.FIELD_REQUIRED),
});
export default function ReviewFormDetails({
  handleRegister,
  submitReview,
  submitError,
  showReviewForm,
  submitErrorMsg,
  decodedToken_Email,
}) {
  const resetFeedbackToken = new URLSearchParams(useLocation().search).get(
    "token"
  );
  const [characterCount, setCharacterCount] = useState(0);
  const initialValues = {
    token: resetFeedbackToken,
    email: decodedToken_Email,
    star: "",
    remark: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    is_allow_public_shared: true,
  };
  return (
    <Paper elevation={3} style={{ padding: 40 }}>
      <Grid container direction="column">
        <Grid item>
          <h6 className="feedback-title">Fernwood Cares: Share Your Review</h6>
          <p className="feedback-reftext">
            We strive to provide families with compassionate care and dignified
            services during their time of need. Your feedback is invaluable in
            helping us continuously improve our services.
          </p>
          {/* <hr /> */}
        </Grid>
        {submitReview ? (
          <Grid item>
            <h6 className="que-title">
              Thank you for submitting your feedback
            </h6>
          </Grid>
        ) : submitError ? (
          <Grid item>
            <h6 className="que-title">{submitErrorMsg}</h6>
          </Grid>
        ) : showReviewForm ? (
          <Grid item>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (
                values,
                { setSubmitting, resetForm, setErrors }
              ) => {
                // console.log(values);
                await handleRegister(values, setErrors);
                setSubmitting(true);
              }}
            >
              {({
                errors,
                touched,
                isSubmitting,
                handleSubmit,
                setFieldValue,
                handleChange,
                handleBlur,
                values,
                isValid,
              }) => {
                if (isSubmitting) {
                  return <CenterLoader />;
                }
                return (
                  <Form onSubmit={handleSubmit}>
                    <Field type="hidden" name="token" />
                    <Grid container className="feedback-form">
                      <Grid item xs={12}>
                        <InputLabel className="feedback-filed" id="first_name">
                          First Name
                        </InputLabel>
                        <TextInput
                          name="first_name"
                          label="First Name"
                          placeholder="e.g. John"
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          margin="normal"
                          fullWidth
                          // multiline
                          rows={3}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel className="feedback-filed" id="middle_name">
                          Middle Name
                        </InputLabel>

                        <TextInput
                          name="middle_name"
                          placeholder="e.g. Andrew"
                          label="Middle Name"
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          margin="normal"
                          fullWidth
                          // multiline
                          rows={3}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel className="feedback-filed" id="last_name">
                          Last Name
                        </InputLabel>

                        <TextInput
                          name="last_name"
                          label="Last Name"
                          placeholder="e.g. Appleseed"
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          margin="normal"
                          fullWidth
                          // multiline
                          rows={3}
                        />
                      </Grid>
                      {/* <Grid item xs={12} md={12}>
                        <InputLabel className="feedback-filed" id="star">
                          Rating
                        </InputLabel>
                        <Rating
                          id="star"
                          name="star"
                          defaultValue={0}
                          precision={0.5}
                          onChange={(e) => {
                            setFieldValue("star", e.target.value);
                          }}
                          className="rating"
                        />
                        {Boolean(touched.star && errors.star) ? (
                          <FormHelperText className="Mui-error">
                            {errors.star}
                          </FormHelperText>
                        ) : null}
                      </Grid> */}

                      <Grid item xs={12} md={12}>
                        <InputLabel className="feedback-filed" id="star">
                          Rating
                        </InputLabel>
                        <Select
                          id="star"
                          name="star"
                          variant="filled"
                          fullWidth
                          rows={3}
                          margin="normal"
                          label="Rating"
                          value={values.star}
                          onChange={(e) => {
                            setFieldValue("star", e.target.value);
                          }}
                          displayEmpty // This ensures that the placeholder is displayed even if no value is selected
                          className="rating"
                        >
                          <MenuItem value="">
                            <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                              Please Select
                            </span>
                          </MenuItem>
                          <MenuItem value={5}>5. Excellent</MenuItem>
                          <MenuItem value={4}>4. Very Good</MenuItem>
                          <MenuItem value={3}>3. Neutral</MenuItem>
                          <MenuItem value={2}>2. Needs Improvement</MenuItem>
                          <MenuItem value={1}>1. Poor</MenuItem>
                        </Select>
                        {Boolean(touched.star && errors.star) ? (
                          <FormHelperText className="Mui-error">
                            {errors.star}
                          </FormHelperText>
                        ) : null}
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel className="feedback-filed" id="email">
                          Email
                        </InputLabel>

                        <TextInput
                          name="email"
                          label="Email"
                          placeholder="e.g. Appleseed"
                          variant="outlined"
                          disabled
                          onBlur={handleBlur}
                          onChange={handleChange}
                          margin="normal"
                          fullWidth
                          // multiline
                          rows={3}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <InputLabel className="feedback-filed" id="remark">
                          Review
                        </InputLabel>
                        <BootstrapForm.Group>
                          <Field id="remark" name="remark">
                            {({ field, form }) => (
                              <div>
                                <BootstrapForm.Control
                                  {...field}
                                  as="textarea"
                                  rows={3}
                                  placeholder="Review"
                                  isInvalid={
                                    form.touched.remark && form.errors.remark
                                  }
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setCharacterCount(e.target.value.length);
                                  }}
                                  inputProps={{ maxLength: 1500 }}
                                />
                                <ErrorMessage
                                  name="remark"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            )}
                          </Field>
                        </BootstrapForm.Group>
                        {/* <TextInput
                          name="remark"
                          label="review"
                          placeholder="Please leave your review or comments here..."
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            setCharacterCount(e.target.value.length);
                          }}
                          margin="normal"
                          fullWidth
                          inputProps={{ maxLength: 1500 }}
                          multiline
                          rows={3}
                          value={values.remark}
                        /> */}
                        <p className="review-count">{`${characterCount} of 1500 Characters Left`}</p>
                      </Grid>
                      <Grid item xs={12} md={12} className="confirm-feedback">
                        <FormControlLabel
                          className="feedback-info"
                          control={
                            <Checkbox
                              checked={values.is_allow_public_shared}
                              onChange={(e) =>
                                setFieldValue(
                                  "is_allow_public_shared",
                                  e.target.checked
                                )
                              }
                              color="primary"
                            />
                          }
                          label="I consent to having my review shared publicly (optional)"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item style={{ paddingTop: 10 }}>
                        <button
                          className="btn-apply quote-button-custom feedback-button"
                          type="submit"
                          disabled={!isValid}
                          style={{
                            cursor: isValid ? "pointer" : "not-allowed",
                          }}
                        >
                          Send Feedback
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
}
