import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./Components/Header";
import Footer from "../DashboardLayout/Components/Footer";
import CustomToastMessageView from "src/Components/CustomToastMessageView";
import { Http } from "src/Services/Http";
import * as Helper from "src/Services/Helper";

export const MainLayout = (props) => {
  const bgImage = Helper.getItem("bgImage");
  const [Image, setImage] = useState(bgImage);
  const [Color, setColor] = useState("#273969");
  useEffect(() => {
    const getTheme = async () => {
      await Http("get", "get_theme/1").then(async ({ data, status }) => {
        setImage(data.image);
        setColor(data.font_color);
        Helper.setItem("bgImage", data.image);
      });
    };
    getTheme();
  }, []);
  const css =
    `.dynamic-body {
      margin: 0;
      padding: 0;
      background-image: url(` +
    Image +
    `);
      background-size: cover;
      height: 100%;
      position: relative;
      min-height: 100vh;
      background-position: center;
      z-index: 0;
    }
    .back-button {
      color: ` +
    Color +
    ` !important;
    text-decoration: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    }
    .call-details {
      color: ` +
    Color +
    ` !important;
      font-size: 12px;
      font-weight: 300;
    }`;
  return (
    <>
      <style>{css}</style>
      <div className="dynamic-body">
        <Container>
          <Header />
          <Outlet />
          <CustomToastMessageView visible={props.visible} />
        </Container>
        <Footer />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  visible: state.global.snackBar,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
