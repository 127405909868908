import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Support from "./Support";
import TextMaskCustom from "src/Components/TextMaskCustom";


export default function Footer() {
  let newDate = new Date();
  let year = newDate.getFullYear();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <footer>
      <div className="cm-footer">
        <div className="cm-footer-copy">
          <p>Copyright © {year} Fernwood Cemetery, All rights reserved.</p>
        </div>
        <div className="cm-footer-menu">
          <ul>
            <li>
              <a href="https://cremation.fernwood.com/fernwoodgpl" target="_blank">
                General Price List
              </a>
            </li>
            <li>
              <span onClick={handleOpen}>Support</span>
            </li>
            <li>
              <a
                href="https://www.fernwood.com/terms-of-service"
                target="_blank"
              >
                Terms and conditions
              </a>
            </li>
            <li>
              <a href="https://www.fernwood.com/privacy-policy" target="_blank">
                Privacy policy{" "}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} className="support-block">
        <DialogContent>
          <Support />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions> */}
      </Dialog>
    </footer>
  );
}
