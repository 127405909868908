import React from "react";

export default function QuestionNotes({ question_description }) {
  return (
    <div className="col-md-12">
      {question_description !== "" && (
        <p className="postque-desc">
          <span>Note: </span>
          {question_description}
        </p>
      )}
    </div>
  );
}
