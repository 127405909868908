import React, { useState } from "react";
import { connect } from "react-redux";
import Page from "src/Components/Page";
import LoginForm from "./Components/LoginForm";
import { login } from "src/redux/actions";
import { Login } from "src/Services/Login";
import { useNavigate } from "react-router-dom";
import { FormHelperText } from "@material-ui/core";

export const LoginView = (props) => {
  let navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [errormessage, setErrorMessage] = useState([]);
  const [errormessagecolor, setErrorMessageColor] = useState([]);

  const handleLogin = async (values, actions) => {
    setisLoading(true);
    await Login("POST", "user/auth/login", values).then(async (response) => {
      const { status } = response;
      if (status === 200) {
        // console.log(response.message[0] + "<br />" + response.message[1]);
        // console.log(response);
        setisLoading(false);
        setErrorMessage(response.message);
        setErrorMessageColor("green");

        // const { access_token, token_type } = response.data;
        // await localStorage.setItem("token", access_token);
        // await props.login(access_token, token_type, response.data);
        // navigate("/app/check-verified/login");
        // props.setSnackbar(true, response.status, response.message);
      } else {
        setErrorMessage(response.message);
        setErrorMessageColor("red");
        setisLoading(false);
        //actions.setErrors({ email: response.message });
        //alert("somthing Wrong");
      }
    });
  };
  return (
    <div>
      <Page title="Fernwood Cemetery Cremation">
        <div className="que-section">
          <div className="row">
            <div className="col-md-3 my-auto"></div>
            <div className="col-md-6">
              <div className="que-bg">
                <h6 className="que-title">Login</h6>
                <hr />
                <div className="que-option">
                  <LoginForm
                    handleLogin={handleLogin}
                    errormessage={errormessage}
                    isLoading={isLoading}
                    errormessagecolor={errormessagecolor}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 my-auto"></div>
          </div>
        </div>
      </Page>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
