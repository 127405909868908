import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Http } from "src/Services/Http";
import CenterLoader from "src/Components/Loader/CenterLoader";
import { IframeContentView } from "./IframeContentView";
import { Navigate, useParams } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import QuestionBox from "../v1/PreQuest/QuestionBox";

export const IframeView = ({ loggedIn }) => {
  const [isLoading, setisLoading] = useState(true);
  const [isqueLoading, setisqueLoading] = useState(true);
  const [inFrame, setinFrame] = useState(true);
  const [isValid, setisValid] = useState(false);
  const [parent] = useState(document.referrer);
  const [isSizeValid, setisSizeValid] = useState(false);
  const [isTPCAllowed, setisTPCAllowed] = useState(false);
  const { token } = useParams();
  const [color, setColor] = useState("#007bff");
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  useEffect(() => {
    try {
      if (window.location !== window.parent.location) {
        setinFrame(true);
      } else {
        setinFrame(false);
      }
      setisTPCAllowed(true);
    } catch (error) {
      setisTPCAllowed(false);
    }
    const checkValid = async () => {
      try {
        await Http("POST", "guest/clients/verify_token", {
          domain_name: parent.match(/:\/\/(.[^/]+)/)[1],
          token: token,
        }).then(({ status, data }) => {
          if (status === 200) {
            setColor(data.client.button_color);
            setisValid(data.is_valid);
          } else {
            setisValid(false);
          }
          setisSizeValid(true);
          if (vh >= 300 && vw >= 550) {
          }
          setisLoading(false);
        });
      } catch (e) {
        setisTPCAllowed(false);
        setisLoading(false);
      }
    };
    checkValid();
    document.body.style.overflowY = 'hidden';
    document.body.style.background= 'transparent';
  }, [parent, token, vh, vw]);

  const DisplayCenter = ({ arg }) => {
    return (
      <Grid container justifyContent="center" style={{ height: vh }}>
        {arg}
      </Grid>
    );
  };
  if (!inFrame) {
    return <Navigate to="/" />;
  }

  const css =
    `.dynamic-btn {
    outline: none;
    -webkit-appearance: none;
    background-color: ` +
    color +
    `10;
    border: 1px solid ` +
    color +
    `!important;
    color: ` +
    color +
    `!important;
    border-radius: 1rem !important;
    font-size: 16px;
    font-weight: 500;
  }
  .dynamic-btn:hover {
    border-radius: 1rem !important;
    -webkit - appearance: none;
    color: #fff!important;
    background-color: ` +
    color +
    `!important;
    border: 1px solid ` +
    color +
    `!important;
  }
  .dynamic-btn.active {
    border-radius: 1rem !important;
    -webkit - appearance: none;
    color: #fff!important;
    background-color: ` +
    color +
    `!important;
    border: 1px solid ` +
    color +
    `!important;
  }`;
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      // style={{ paddingTop: 20, paddingBottom:20 }}
    >
      {/* <Grid item>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <h6
            style={{
              marginTop: 20,
              color: "#273969",
              fontSize: 24,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            Request An Instant Online Quote
          </h6>
        </Grid>
      </Grid> */}
      <Grid item>
        {isLoading ? (
          <DisplayCenter arg={<CenterLoader />} />
        ) : !isTPCAllowed ? (
          <DisplayCenter arg="Third Party Cookie is Blocked, Please allow it To Continue" />
        ) : !isValid ? (
          <DisplayCenter arg="Please Contact to Fernwood Cemetery Admin." />
        ) : !isSizeValid ? (
          <DisplayCenter arg="min Heigh 550 & width 400 allowed" />
        ) : loggedIn ? (
          <Grid
            container
            // justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid item style={{ margin: 20 }}>
              You are already logged in!
            </Grid>
            {/* <div className="row">
              <Button
                color="primary"
                variant="outlined"
                fullWidth={false}
                onClick={() => window.open("/login", "_blank")}
              >
                click here
              </Button>
            </div> */}
            <Grid item direction="row" xs={12} spacing={2}>
              <Button
                className="homenavbutton"
                variant="contained"
                onClick={() => window.open("/login", "_blank")}
              >
                Continue where I left off
              </Button>
            </Grid>
          </Grid>
        ) : (
          <QuestionBox color={color} />
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({ loggedIn: state.session.loggedIn });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IframeView);
