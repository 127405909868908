import DatePicker from "../DatePicker";
import React from "react";
import { Button, Form } from "react-bootstrap";

export default function DatePickerBox({
  placeholder,
  answer,
  setAnswer,
  error,
  setError,
  validateForm,
  handleAnswerClick,
  maxDate,
}) {
  return (
    <div className="col-md-12 ans-fill">
      <Form.Group>
        <DatePicker
          name="answer"
          lable="Select Date"
          placeholder={placeholder}
          setAnswer={setAnswer}
          answer={answer}
          error={error}
          setError={setError}
          maxDate={maxDate}
        />
      </Form.Group>
      {/* <Button
        color="primary"
        variant="outline-light"
        onClick={() => {
          let isvalid = validateForm();
          if (isvalid) {
            handleAnswerClick(answer, true);
          }
        }}
      >
        Continue
      </Button> */}
    </div>
  );
}
