import React from "react";
import { Container, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { connect } from "react-redux";

export const PreQuestListDetails = ({ data, ...props }) => {
  let fileName = "";
  return (
    <div>
      <Container fluid="md">
        <Card className="mb-2" style={{ width: "100%" }}>
          <ListGroup className="list-group-flush">
            {data.map((prequestdata, predataindex) => {
              return (
                <div key={predataindex}>
                  <ListGroupItem className="reviewsection_quetitle">
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          predataindex +
                          1 +
                          ". " +
                          prequestdata.question
                            .replace(/<p[^>]*>/g, "")
                            .replace(/<\/p>/g, ""),
                      }}
                    ></div>
                  </ListGroupItem>
                  <ListGroupItem>
                    {prequestdata.control_id === 6 ? (
                      fileName !== "Unknown" ? (
                        <a
                          href={prequestdata.answer}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          {fileName}
                        </a>
                      ) : (
                        fileName
                      )
                    ) : prequestdata.control_id === 10 &&
                      prequestdata.answer !== "Unknown" ? (
                      "+" +
                      prequestdata.answer.replace(
                        /^(\d{3})(\d{3})(\d{4}).*/,
                        "($1) $2-$3"
                      )
                    ) : (
                      prequestdata.answer
                    )}
                  </ListGroupItem>
                </div>
              );
            })}
          </ListGroup>
        </Card>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreQuestListDetails);
