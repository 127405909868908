import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Http } from "src/Services/Http";

import * as Messages from "src/constants/Messages";
import CenterLoader from "src/Components/Loader/CenterLoader";
import TextInput from "src/Components/Formik/TextInput";
import { Form as BootstrapForm } from "react-bootstrap";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import TextMaskCustom from "src/Components/TextMaskCustom";
import TextAreaBox from "src/views/PostQuestView/Components/Question/TextAreaBox";

// const validationSchema = Yup.object().shape({
//   name: Yup.string()
//     .max(50, "First name must be at most 50 characters")
//     .required(Messages.FIELD_REQUIRED),
//   from_email: Yup.string()
//     .lowercase()
//     .email(Messages.VALID_EMAIL)
//     .required(Messages.FIELD_REQUIRED),
//   phone: Yup.string()
//     // .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
//     .matches(/^\+?[0-9]{0,3}[0-9]{10}$/, Messages.INVALID_MOBILE)
//     .nullable(),
//   support_for: Yup.string().required(Messages.FIELD_REQUIRED),
// });

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters and spaces")
    .required(Messages.FIELD_REQUIRED),
  from_email: Yup.string()
    .lowercase()
    .email(Messages.VALID_EMAIL)
    .required(Messages.FIELD_REQUIRED),
  // phone: Yup.string().matches(/^[0-9+\s]+$/, "Invalid phone number"),
  phone: Yup.string().min(10, ""),
  support_for: Yup.string().required("Support For is required"),
  comment: Yup.string()
    .max(1500, "Comment must be at most 1500 characters")
    .required(Messages.FIELD_REQUIRED),
});

export default function Support() {
  const initialValues = {
    name: "",
    from_email: "",
    phone: "",
    support_for: "",
    comment: "",
  };
  const [responseMessage, setResponseMessage] = useState("");

  const handleRegister = async (values, actions) => {
    await Http("POST", "guest/support_email", {
      ...values,
    }).then(async (response) => {
      const { status, data } = response;
      if (status === 200) {
        setResponseMessage(response.message);
        actions.setErrors("");
        actions.resetForm();
      } else {
        if (status === 400) {
          actions.setErrors(data);
        }
      }
    });
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <h6 className="que-title">SUPPORT</h6>
        <hr />
      </Grid>

      <Grid item style={{ padding: 15 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            await handleRegister(values, actions);
            // setSubmitting(true);
          }}
        >
          {({
            errors,
            touched,
            values,
            isSubmitting,
            handleSubmit,
            setFieldValue,
            handleChange,
            handleBlur,
            isValid,
          }) => {
            if (isSubmitting) {
              return <CenterLoader />;
            }
            return (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextInput
                      name="name"
                      label="NAME"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      margin="normal"
                      fullWidth
                      // multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      name="from_email"
                      label="EMAIL"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      margin="normal"
                      fullWidth
                      // multiline
                      rows={3}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <TextInput
                      name="phone"
                      label="PHONE"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      margin="normal"
                      fullWidth
                      // multiline
                      rows={3}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextInput
                        name="phone"
                        label="Phone Number"
                        onChange={(event) => {
                          setFieldValue("phone", event.target.value);
                        }}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      select
                      label="SUPPORT FOR"
                      name="support_for"
                      onChange={handleChange}
                      variant="filled"
                      fullWidth
                      margin="normal"
                      onBlur={handleBlur}
                      value={values.support_for}
                      error={Boolean(touched.support_for && errors.support_for)}
                      helperText={touched.support_for && errors.support_for}
                    >
                      <MenuItem key="arrangements" value="0">
                        Help Making Arrangements
                      </MenuItem>
                      <MenuItem key="support" value="1">
                        Technical Support & Troubleshooting
                      </MenuItem>
                    </TextInput>
                  </Grid>

                  <Grid item xs={12}>
                    {/* <TextInput
                      name="comment"
                      label="COMMENT"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      margin="normal"
                      fullWidth
                      multiline
                      rows={3}
                    /> */}

                    <BootstrapForm.Group>
                      <Field name="comment">
                        {({ field, form }) => (
                          <div>
                            <BootstrapForm.Control
                              {...field}
                              as="textarea"
                              rows={3}
                              placeholder="COMMENT"
                              isInvalid={
                                form.touched.comment && form.errors.comment
                              }
                            />
                            <ErrorMessage
                              name="comment"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        )}
                      </Field>
                    </BootstrapForm.Group>
                  </Grid>

                  {responseMessage !== undefined && responseMessage !== "" && (
                    <Grid item xs={12} md={12}>
                      <FormHelperText
                        className="Mui-error"
                        style={{ color: "green" }}
                      >
                        {responseMessage}
                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item style={{ paddingTop: 10 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      disabled={!isValid}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
