import React from "react";
// Importing Routing Library
import { Navigate } from "react-router-dom";
// Importing Layouts Files
import MainLayout from "src/layouts/MainLayout";
import ErrorLayout from "src/layouts/ErrorLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
// Importing View Files
import PreQuestView from "src/views/PreQuestView";

import RegisterView from "src/views/auth/RegisterView";
import LoginView from "src/views/auth/LoginView";
import LogoutView from "src/views/auth/LogoutView";
import CheckVerifiedView from "src/views/auth/CheckVerifiedView";
import VerifyAccountView from "src/views/auth/VerifyAccountView";
import LoginVerifyAccountView from "src/views/auth/LoginVerifyAccountView";

import NotFoundView from "src/views/errors/NotFoundView";

import HomeView from "src/views/home";
import Profile from "src/views/account/Profile";
import PackageInfoView from "src/views/PackageInfoView";
import PaymentView from "src/views/PaymentView";
import ThankYouView from "src/views/ThankYouView";
import { ExtraMails as ThankYouExtraMailsView } from "src/views/ThankYouView/ExtraMails";
import PostQuestView from "src/views/PostQuestView";
import PreQuestListView from "src/views/account/PreQuestListView";
import ExtraMailesPaymentView from "src/views/MailesChargesPaymentView";
import IframeView from "src/views/iframe";
import IframeLayout from "src/layouts/IframeLayout";
import TestView from "src/views/TestView";
import { Feedback } from "src/views/Feedback";
import PreQuest from "src/views/v1/PreQuest";
import PostQuest from "src/views/v1/PostQuest";
import EditPreQuest from "src/views/v1/EditPreQuest";
import ImportantNotice from "src/views/PackageInfoView/Components/ImportantNotice";
import ExtraMilesNotes from "src/views/MailesChargesPaymentView/ExtraMilesNotes";
import AdditionalServicePayment from "src/views/AdditionalServicePayment";

const routes = (
  loggedIn,
  isVerified,
  isPaymentChk, // is payment done or not ?
  isQuestConditionChk, // is great western in pre questionary ?
  isPreNeedDoneChk, // is great western completed ?
  isPackageSeleted,
  payment_extra_mailes_chk,
  payment_extra_mailes_chk_tem
) => {
  const isPaymentDone = isPaymentChk;
  const isGwinPreQue = isQuestConditionChk;
  const isGwComplete = isPreNeedDoneChk;
  const routes = [
    {
      path: "/app",
      element: loggedIn ? (
        isVerified === true ? (
          <DashboardLayout />
        ) : isPaymentChk === true ? (
          isQuestConditionChk === true ? (
            <Navigate to="/app/gwquest" />
          ) : (
            <Navigate to="/app/postquest" />
          )
        ) : (
          <Navigate to="/app/check-verified/direct" />
        )
      ) : (
        <Navigate to="/login" />
      ),
      children: [
        {
          path: "packages",
          element: isPaymentDone ? (
            <Navigate to="/app/postquest" />
          ) : isGwinPreQue && isGwComplete ? (
            <Navigate to="/app/payment" />
          ) : (
            <PackageInfoView />
          ),
        },

        //custome add
        {
          path: "important-notice",
          // element: isPaymentDone ? (
          //   <ImportantNotice />
          // ) : (
          //   <PackageInfoView />
          // ),
          element:
            <ImportantNotice />
          
        },


        {
          path: "payment",
          element: !isPaymentDone ? (
            <PaymentView />
          ) : (
            <Navigate to="/app/postquest" />
          ),
        },
        {
          path: "thank-you",
          element: isPaymentDone ? (
            <ThankYouView />
          ) : (
            <Navigate to="/app/packages" />
          ),
        },
        {
          path: "extra-mails-thank-you",
          element: payment_extra_mailes_chk && !payment_extra_mailes_chk_tem ? (
            <Navigate to="/app/postquest" />
          ) : payment_extra_mailes_chk_tem ? (
            <ThankYouExtraMailsView />
          ) : (
            <Navigate to="/app/extramailespayment" />
          ),
        },
        { path: "home", element: <HomeView /> },
        { path: "test", element: <TestView /> },
        { path: "prequestlist", element: <PreQuestListView /> },
        {
          path: "editprequest",
          element: !isPaymentDone ? (
            <EditPreQuest />
          ) : (
            <Navigate to="/app/prequestlist" />
          ),
        },
        {
          path: "postquest",
          element: isPackageSeleted ? (
            isGwinPreQue ? (
              isGwComplete ? (
                isPaymentDone ? (
                  // payment_extra_mailes_chk ? (
                    <PostQuest />
                  // ) : (
                  //   <Navigate to="/app/extramailespayment" />
                  // )
                ) : (
                  <Navigate to="/app/payment" />
                )
              ) : (
                <Navigate to="/app/gwquest" />
              )
            ) : isPaymentDone ? (
              // payment_extra_mailes_chk ? (
                <PostQuest />
              // ) : (
              //   <Navigate to="/app/extramailespayment" />
              // )
            ) : (
              <Navigate to="/app/payment" />
            )
          ) : (
            <Navigate to="/app/packages" />
          ),
          //   isGwinPreQue ? (
          //     isGwComplete ? (
          //       payment_extra_mailes_chk ? (
          //         <PostQuest />
          //       ) : (
          //         <Navigate to="/app/extramailespayment" />
          //       )
          //     ) : (
          //       <Navigate to="/app/gwquest" />
          //     )
          //   ) : payment_extra_mailes_chk ? (
          //     <PostQuest />
          //   ) : (
          //     <Navigate to="/app/extramailespayment" />
          //   )
          // ) : (
          //   <Navigate to="/app/packages" />
          // ),
        },
        // {
        //   path: "postquestv1",
        //   element: <PostQuest />,
        // },
        {
          path: "gwquest",
          element:
            isGwinPreQue && isPackageSeleted ? (
              // <PostQuestView />
              <PostQuest />
            ) : (
              <Navigate to="/app/packages" />
            ),
        },
        {
          path: "extramailespayment",
          element: payment_extra_mailes_chk ? (
            <Navigate to="/app/postquest" />
          ) : (
            <ExtraMailesPaymentView />
          ),
        },
          //custome add
          {
            path: "important-notice-for-extramiles",
            // element: isPaymentDone ? (
            //   <ImportantNotice />
            // ) : (
            //   <PackageInfoView />
            // ),
            element:
              <ExtraMilesNotes />
            
          },
        { path: "profile", element: <Profile /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/app",
      element: loggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: "check-verified/:id", element: <CheckVerifiedView /> },
        { path: "verify-account", element: <VerifyAccountView /> },
        // { path: "login-verify", element: <LoginVerifyAccountView /> },
        { path: "logout", element: <LogoutView /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/i",
      element: <IframeLayout />,
      children: [
        { path: ":token", element: <IframeView /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },

    {
      path: "/",
      element: !loggedIn ? (
        <MainLayout />
      ) : (
        <Navigate to="/app/check-verified/direct" />
      ),
      children: [
        // { path: "", element: <PreQuestView /> },
        { path: "", element: <PreQuest /> },
        // { path: "v1", element: <PreQuest /> },
        { path: "register", element: <RegisterView /> },
        { path: "verify-account", element: <VerifyAccountView /> },
        { path: "login", element: <LoginView /> },
        // { path: "feedback", element: <Feedback /> },
        { path: "login-verify/:id", element: <LoginVerifyAccountView /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },

    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "404", element: <NotFoundView /> },
        { path: "*", element: <Navigate to="/404" /> },
        { path: "feedback", element: <Feedback /> },
        { path: "additional-service-payment", element: <AdditionalServicePayment /> },
        { path: "thank-you", element: <ThankYouView /> },
      ],
    },
  ];
  return routes;
};

export default routes;
