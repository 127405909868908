import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RegisterForm from "./Components/RegisterForm";
import { useNavigate } from "react-router";
import Page from "src/Components/Page";
import { login } from "src/redux/actions/sessionActions";

export const RegisterView = ({ login }) => {
  let navigate = useNavigate();
  useEffect(() => {
    const checkActivity = async () => {
      let rand_token = await localStorage.getItem("rand_token");
      let stage = await localStorage.getItem("stage");
      if (rand_token === null || stage === null || parseInt(stage) === 0) {
        navigate("/v1");
      }
      // if (parseInt(stage) === 2) {
      //   navigate("/package");
      // }
    };
    checkActivity();
  }, [navigate]);

  return (
    <Page title="Fernwood Cemetery Register">
      <div className="que-section">
        <div className="row">
          <div className="col-md-2 my-auto"></div>
          <div className="col-md-8 my-auto mx-auto">
            <RegisterForm />
          </div>
          <div className="col-md-2 my-auto"></div>
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterView);
