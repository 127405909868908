import React, { useEffect, useState } from "react";
import { CloudUpload } from "@material-ui/icons";
import { useDropzone } from "react-dropzone";
import { Grid } from "@material-ui/core";
import { createUseStyles } from "react-jss";
import { Button, Form } from "react-bootstrap";

const getExtension = (arg) => {
  let re = /(?:\.([^.]+))?$/;
  return re.exec(arg)[1];
};
const useStyles = createUseStyles({
  input: {
    display: "none",
  },
  img: {
    display: "block",
    width: 200,
    height: "100%",
  },
});

export default function FileDropBox({
  control_handler,
  ext,
  setfileExt,
  setAnswer,
  file_path,
  answer,
  error,
  setError,
}) {
  const classes = useStyles();
  let acceptedString = "";
  let fileName = "";
  if (answer !== undefined && answer !== "") {
    let filepath = answer.split("/");
    fileName = filepath[filepath.length - 1];
  }
  if (control_handler === 2) {
    acceptedString = "." + ext;
  } else if (control_handler === 3) {
    acceptedString = "." + ext;
  } else {
    acceptedString = ".png,.jpg,.jpeg,.pdf,.doc,.docx,.xlx,.xlsx,.ppt,.pptx";
  }
  const [filevalues, setFileValues] = useState([]);
  const [dragOver, setDragOver] = useState("");
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: acceptedString,
      maxSize: 5242880,
      onDrop: (acceptedFiles) => {
        setFileValues(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
      onDragOver: (e) => setDragOver("dragStart"),
      onDragLeave: (e) => setDragOver(""),
      onDropAccepted: (e) => {
        e.map((item) => {
          let extension = getExtension(item.name);
          setfileExt(extension);
          let reader = new FileReader();
          reader.readAsDataURL(item);
          reader.onload = (event) => {
            setAnswer(event.target.result);
          };
        });
        setDragOver("");
        setError("");
      },
      onDropRejected: (e) => {
        setfileExt("");
        setDragOver("dragFailed");
      },
    });
  const files = acceptedFiles.map((file) => (
    <Grid
      item
      key={file.path}
      className="p-3 d-flex flex-row align-items-center"
    >
      {[2, 4].includes(control_handler) && (
        <img src={file.preview} alt={file.path} className={classes.img} />
      )}
      <p>
        {file.path}
        <br />
        {file.size} bytes
      </p>
    </Grid>
  ));
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={file.path}>
      {/* {file.path} */}
      {errors.map((e) => (
        <Form.Control.Feedback type="invalid" className=" d-block">
          {e.message}
        </Form.Control.Feedback>
      ))}
    </div>
  ));
  return (
    <div className="col-md-12">
      <div className="">
        <Grid
          container
          {...getRootProps({
            className: "dropzone input-file " + dragOver,
          })}
        >
          <input {...getInputProps()} />
          <CloudUpload
            style={{ fontSize: 60, color: "#ffffff90", margin: 10 }}
          />
          <h4>Drag & drop {ext} file to upload</h4>
          <h6 style={{ margin: 20 }}>or</h6>
          <Button>Browse Files</Button>
        </Grid>
      </div>
      <Grid container className="input-file-preview" alignItems="center">
        {error !== "" && (
          <Form.Control.Feedback type="invalid" className=" d-block">
            {error}
          </Form.Control.Feedback>
        )}
        {files.length > 0 ? (
          files
        ) : fileRejectionItems.length > 0 ? (
          fileRejectionItems
        ) : (
          <Grid
            item
            key={fileName}
            className="p-3 d-flex flex-row align-items-center"
          >
            {file_path !== "" && (
              <div>
                {[2, 4].includes(control_handler) && (
                  <img src={file_path} alt={fileName} className={classes.img} />
                )}
                <p>
                  <a
                    href={file_path}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    {fileName}
                  </a>
                </p>
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
}
