import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { usePaymentInputs } from "react-payment-inputs";
import { connect } from "react-redux";
import * as Yup from "yup";
import * as Messages from "src/constants/Messages";
import { Col, Form, Row } from "react-bootstrap";
import images from "react-payment-inputs/images";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Http } from "src/Services/Http";
import {
  setPaymentExtraMailsChk,
  setPaymentExtraMailsChkTem,
} from "src/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import CenterLoader from "src/Components/Loader/CenterLoader";
import { RadioGroup } from "@mui/material";
import {} from "@mui/material";
import { Radio } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  DialogContentText,
} from "@mui/material";
// import { DialogContentText } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
const validationSchema = Yup.object().shape({
  holderName: Yup.string()
    .matches(
      /^[a-zA-Z\u00C0-\u024F\s]+$/,
      "Please enter a valid cardholder name"
    )
    .required(Messages.FIELD_REQUIRED),
});

const validateForm = (values, meta) => {
  let errors = {};
  if (values.holderName === "") {
    errors.holderName = Messages.FIELD_REQUIRED;
  }
  if (meta.erroredInputs.cardNumber) {
    errors.cardNumber = meta.erroredInputs.cardNumber;
  }
  if (meta.erroredInputs.expiryDate) {
    errors.expiryDate = meta.erroredInputs.expiryDate;
  }
  if (meta.erroredInputs.cvc) {
    errors.cvc = meta.erroredInputs.cvc;
  }
  if (meta.erroredInputs.zip) {
    errors.zip = meta.erroredInputs.zip;
  }
  return errors;
};

const ExtraMailesPaymentView = (props) => {
  const navigate = useNavigate();
  const [isAgree, setisAgree] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isTermModal, setisTermModal] = useState(false);
  const [isPrivacyModal, setisPrivacyModal] = useState(false);
  const [extraMailesData, setExtraMailesData] = useState({});
  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const { erroredInputs, touchedInputs } = meta;

  const user_id = useSelector((state) => state.session.user.id);
  const return_by_post_question = useSelector((state) => state.session.check_post_qa);
  const dispatch = useDispatch();

  const handleChangeChk = (arg) => {
    setisAgree(arg.target.checked);
  };

  const getExtraMailesCharges = async () => {
    try {
      const response = await Http("GET", `extra_miles/${user_id}`);
      //   console.log(response);
      setExtraMailesData(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const makePayment = async (PaymentInfo) => {
    setLoading(true);

    let body = {
      card_number: parseInt(PaymentInfo.cardNumber.replace(/ /g, ""), 10),
      card_name: PaymentInfo.holderName,
      card_expiry: PaymentInfo.expiryDate.replace(/ /g, ""),
      card_cvv: parseInt(PaymentInfo.cvc),
      zip: PaymentInfo.zip,
      amount_per_extra_mile: parseFloat(
        extraMailesData.amount_per_extra_mile
      ).toFixed(2),
      destination_address_id: extraMailesData.destination_address_id,
      source_address_id: extraMailesData.source_address_id,
      total_amount_to_be_paid: parseFloat(
        extraMailesData.total_amount_to_be_paid
      ).toFixed(2),
      total_mile_count: parseFloat(extraMailesData.total_mile_count).toFixed(2),
    };
    await Http("POST", "extra_miles", body).then((response) => {
      if (response.status === 200) {
        dispatch(setPaymentExtraMailsChk(true));
        dispatch(setPaymentExtraMailsChkTem(true));
        navigate("/app/extra-mails-thank-you");
      } else {
        alert(response.Messages);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    getExtraMailesCharges();
  }, []);

  if (isLoading) {
    return <CenterLoader minheight="100vh" />;
  }

  return (
    <Grid
      // container
      // direction="row"
      style={{
        backgroundImage: `url("/img/Rectangle24.png")`,
        backgroundSize: "cover",
      }}
    >
      <div className="payment-section mt-5 pt-5">
        <div className="container">
          <div className="payment-method">
            <Formik
              initialValues={{
                holderName: "",
                cardNumber: "",
                expiryDate: "",
                cvc: "",
                zip: "",
              }}
              onSubmit={(values) => makePayment(values)}
              validationSchema={validationSchema}
              validate={(e) => validateForm(e, meta)}
            >
              {({
                values,
                errors,
                touched,
                isValid,
                handleBlur,
                handleChange,
                handleSubmit,
                validateForm,
                setErrors,
              }) => {
                return (
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <h3>Service Area Calculation</h3>
                          </div>
                        </div>
                        <Grid item md={12} xs={12}>
                          <div className="payment-icon">
                            <RadioGroup
                              aria-label="gender"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              value={"ture"}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="ture"
                                control={<Radio />}
                                label="Credit Card"
                              />
                            </RadioGroup>
                            <svg {...getCardImageProps({ images })} />
                            {/* <img src="img/payment1.png" class="pcard" /> */}
                          </div>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item md={12}>
                            <Grid container spacing={3}>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Card holder Name"
                                  placeholder="Card holder Name"
                                  name="holderName"
                                  value={values.holderName}
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.holderName && errors.holderName
                                  )}
                                  helperText={
                                    touched.holderName && errors.holderName
                                  }
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  inputProps={getCardNumberProps({
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                  })}
                                  isInvalid={
                                    (touchedInputs.cardNumber &&
                                      erroredInputs.cardNumber) ||
                                    (touched.cardNumber && errors.cardNumber)
                                  }
                                  fullWidth
                                  label="Card Number"
                                  name="cardNumber"
                                  value={values.cardNumber}
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  error={Boolean(
                                    (touchedInputs.cardNumber &&
                                      erroredInputs.cardNumber) ||
                                      (touched.cardNumber && errors.cardNumber)
                                  )}
                                  helperText={
                                    Boolean(
                                      (touchedInputs.cardNumber &&
                                        erroredInputs.cardNumber) ||
                                        (touched.cardNumber &&
                                          errors.cardNumber)
                                    )
                                      ? erroredInputs.cardNumber
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item md={6} xs={6}>
                                <TextField
                                  inputProps={getExpiryDateProps({
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                  })}
                                  fullWidth
                                  value={values.expiryDate}
                                  name="expiryDate"
                                  label="Expiry Date"
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  error={Boolean(
                                    (touchedInputs.expiryDate &&
                                      erroredInputs.expiryDate) ||
                                      (touched.expiryDate && errors.expiryDate)
                                  )}
                                  helperText={
                                    Boolean(
                                      (touchedInputs.expiryDate &&
                                        erroredInputs.expiryDate) ||
                                        (touched.expiryDate &&
                                          errors.expiryDate)
                                    )
                                      ? erroredInputs.expiryDate
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item md={6} xs={6}>
                                <TextField
                                  fullWidth
                                  inputProps={getCVCProps({
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                  })}
                                  label="CVV Code"
                                  placeholder="CVV Code"
                                  value={values.cvc}
                                  name="cvc"
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  error={Boolean(
                                    (touchedInputs.cvc && erroredInputs.cvc) ||
                                      (touched.cvc && errors.cvc)
                                  )}
                                  helperText={
                                    Boolean(
                                      (touchedInputs.cvc &&
                                        erroredInputs.cvc) ||
                                        (touched.cvc && errors.cvc)
                                    )
                                      ? erroredInputs.cvc
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  fullWidth
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  label="Zip"
                                  placeholder="Zip"
                                  value={values.zip}
                                  name="zip"
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  error={Boolean(touched.zip && errors.zip)}
                                  helperText={touched.zip && errors.zip}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="col-md-6">
                        <div className="order-section">
                          <h3 className="text-center">Summary</h3>
                          <div className="order-total">
                            <ul>
                              <li>
                                <p className="total-label">
                                  Extra Miles Count:
                                </p>
                                <p className="total-price">
                                  {parseFloat(
                                    extraMailesData.total_mile_count
                                  ).toFixed(2)}
                                </p>
                              </li>
                              <li>
                                <p className="total-label">
                                  Per Extra Miles Amount:
                                </p>
                                <p className="total-price">
                                  $
                                  {parseFloat(
                                    extraMailesData.amount_per_extra_mile
                                  ).toFixed(2)}
                                </p>
                              </li>
                              <li>
                                <p className="total-label">Total:</p>
                                <p className="total-price">
                                  $
                                  {parseFloat(
                                    extraMailesData.total_amount_to_be_paid
                                  ).toFixed(2)}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="text-center purchase-section">
                          <div className="form-check payment-policy-extra">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={isAgree}
                              onChange={handleChangeChk}
                              id="defaultCheck1"
                            />
                            <label
                              className="form-check-label"
                              // htmlFor="defaultCheck1"
                            >
                              I agree to Fernwood Cemetery{" "}
                              <span
                                className="gold"
                                style={{ cursor: "pointer" }}
                                // onClick={() => setisTermModal(true)}
                              >
                                <a
                                  className="gold paymentlink"
                                  href="https://www.fernwood.com/terms-of-service"
                                >
                                  Terms and conditions
                                </a>
                                {/* <Link
                                  className="gold paymentlink"
                                  target="_blank"
                                  // to={"//hollywoodforever.com/terms-and-conditions/"}
                                  to={"https://www.fernwood.com/terms-of-service"}
                                >
                                  Terms of Service
                                </Link> */}
                              </span>{" "}
                              and{" "}
                              <span
                                className="gold"
                                style={{ cursor: "pointer" }}
                                // onClick={() => setisPrivacyModal(true)}
                              >
                                <a
                                  className="gold paymentlink"
                                  href="https://www.fernwood.com/privacy-policy"
                                >
                                  Privacy policy{" "}
                                </a>
                                {/* <Link
                                  className="gold paymentlink"
                                  target="_blank"
                                  // to={"//hollywoodforever.com/privacy-policy/"}
                                  to={"https://www.fernwood.com/privacy-policy"}
                                >
                                  Privacy Policy.
                                </Link> */}{" "}
                              </span>
                            </label>
                          </div>
                          {isValid && isAgree && (
                            <button className="btn-purchase" type="submit">
                              Make Payment
                            </button>
                          )}
                          {isValid === false || isAgree === false ? (
                            <button
                              type="button"
                              className="btn-purchase"
                              onClick={() => console.log("missing info")}
                              disabled={true}
                            >
                              Make Payment
                            </button>
                          ) : null}
                        </div>
                        <div className="text-center form-check">
                          <Link
                            to={
                              return_by_post_question ? "/app/postquest" : ""}
                            className="btn-back"
                          >
                            Return
                          </Link>
                        </div>
                      </div>
                      <Dialog
                        open={isTermModal}
                        onClose={() => setisTermModal(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="sm"
                        fullWidth={true}
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Terms of Service"}
                          <IconButton
                            aria-label="close"
                            onClick={() => setisTermModal(false)}
                            style={{
                              position: "absolute",
                              right: 8,
                              top: 10,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                          <DialogContentText id="alert-dialog-description">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.
                            </p>
                            <p>
                              It has survived not only five centuries, but also
                              the leap into electronic typesetting, remaining
                              essentially unchanged. It was popularised in the
                              1960s with the release of Letraset sheets
                              containing Lorem Ipsum passages, and more recently
                              with desktop publishing software like Aldus
                              PageMaker including versions of Lorem Ipsum.
                            </p>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.
                            </p>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setisTermModal(false)}>
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Dialog
                        open={isPrivacyModal}
                        onClose={() => setisPrivacyModal(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="sm"
                        fullWidth={true}
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Privacy Policy"}
                          <IconButton
                            aria-label="close"
                            onClick={() => setisPrivacyModal(false)}
                            style={{
                              position: "absolute",
                              right: 8,
                              top: 10,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                          <DialogContentText id="alert-dialog-description">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.
                            </p>
                            <p>
                              It has survived not only five centuries, but also
                              the leap into electronic typesetting, remaining
                              essentially unchanged. It was popularised in the
                              1960s with the release of Letraset sheets
                              containing Lorem Ipsum passages, and more recently
                              with desktop publishing software like Aldus
                              PageMaker including versions of Lorem Ipsum.
                            </p>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.
                            </p>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setisPrivacyModal(false)}>
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default ExtraMailesPaymentView;
