import React, { useState, useEffect } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import left_arrow_icon from "src/Assets/img/left_arrow_icon.svg";
export default function Header({ ...rest }) {
  const conditionData = useSelector((state) => state.session);
  const [redirectroute, setRedirectRoute] = useState("");
  const location = useLocation();
  const paths = location.pathname.split("/");
  const urlName = paths[2];
  const getUrlName = urlName === "gwquest" ? "Great Western" : "Post Question";

  useEffect(() => {
    if (!conditionData.verified) {
      setRedirectRoute("/app/check-verified/login");
    } else if (!conditionData.is_package_selected) {
      // setRedirectRoute("/app/packages");
      setRedirectRoute("/app/home");
    } else if (!conditionData.questconditionchk) {
      if (!conditionData.paymentchk) {
        // setRedirectRoute("/app/payment");
        setRedirectRoute("/app/home");
      } else if (!conditionData.check_post_qa) {
        // setRedirectRoute("/app/postquest");
        setRedirectRoute("/app/home");
      } else if (!conditionData.extra_mailes_chk) {
        setRedirectRoute("/app/home");
      } else {
        if (!conditionData.payment_extra_mailes_chk) {
          // setRedirectRoute("/app/extramailespayment");
          setRedirectRoute("/app/home");
        } else {
          setRedirectRoute("/app/home");
        }
      }
    } else {
      if (!conditionData.preeneeddonechk) {
        // setRedirectRoute("/app/gwquest");
        setRedirectRoute("/app/home");
      } else if (!conditionData.paymentchk) {
        // setRedirectRoute("/app/payment");
        setRedirectRoute("/app/home");
      } else if (!conditionData.check_post_qa) {
        // setRedirectRoute("/app/postquest");
        setRedirectRoute("/app/home");
      } else if (!conditionData.extra_mailes_chk) {
        setRedirectRoute("/app/home");
      } else {
        if (!conditionData.payment_extra_mailes_chk) {
          setRedirectRoute("/app/extramailespayment");
        } else {
          setRedirectRoute("/app/home");
        }
      }
    }
  });

  return (
    <header className="blog-header py-1 fixed-top ">
      <div className="container px-0">
        <div className="row flex justify-content-between align-items-center">
          <div className="col-md-4 col-4 col-sm-4 pt-1 px-0">
            {/* {urlName === "gwquest" ||
             urlName === "postquest" ? (
              <Link to="/app/home" className="back">
                <img src={left_arrow_icon} alt="Logo" />
                <span className=""> Return to Main Menu</span>
                <span className=""> Return to Home pageee </span>
              </Link>
            ) : 
            (
              <Link to={redirectroute} className="back">
                <img src={left_arrow_icon} alt="Logo" />
                <span className=""> Return to Application</span>
                <span className=""> Return to Home</span>
              </Link>
            )
            } */}
            {
              urlName!=="home" ?   <Link to={redirectroute} className="back">
              <img src={left_arrow_icon} alt="Logo" />
              <span className=""> Return to Home</span>
            </Link> : ""
            }


          </div>
          <div className="col-md-4 col-4 col-sm-4 text-center">
            <Link to="/app/home" className="header-logo text-dark">
              {/* <Image src="/img/logo/HF-WG-logo.svg" alt="Logo" /> */}
              <Image src="/img/logo/Fernwood_logo.png" alt="Logo" />
            </Link>
          </div>
          <div className="px-0 col-md-4 col-4 col-sm-4 d-flex justify-content-end align-items-center">
            <div className="row">
              <div className="col-md-12 justify-content-end align-items-center d-flex px-0">
                <div className="logout-btn">
                  <Dropdown className="dropdown">
                    <Dropdown.Toggle
                      className="btn-lgt dropdown-toggle d-flex align-items-center"
                      id="dropdown-basic"
                      variant="light"
                    >
                      <Image
                        className="lgt-img header-logo "
                        src={
                          rest.avatar === "" || rest.avatar === undefined
                            // ? "/img/logo/Avatar.png"
                            ? "/img/logo/F_C_Logo.png"
                            : rest.avatar
                        }
                        alt="gdf"
                      />
                      <div className="user-toggle">{`${rest.authUser.first_name} ${rest.authUser.last_name}`}</div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu">
                      <Dropdown.Item
                        className="dropdown-item"
                        href="/app/profile"
                      >
                        My Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item"
                        href="/app/prequestlist"
                      >
                        Pre questionnaire
                      </Dropdown.Item>
                      {rest.isGwinPreQue ? (
                        <Dropdown.Item
                          className="dropdown-item"
                          href="/app/gwquest"
                        >
                          Great Western
                        </Dropdown.Item>
                      ) : (
                        " "
                      )}
                      <Dropdown.Item
                        className="dropdown-item"
                        href="/app/postquest"
                      >
                        Post questionnaire
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item"
                        href="/app/logout"
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
