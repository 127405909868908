import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Col, Container, Card } from "react-bootstrap";
import { QuestionBox } from "./Components/QuestionBox";
import { Http } from "src/Services/Http";
import Page from "src/Components/Page";
import CenterLoader from "src/Components/Loader/CenterLoader";
import { QuestionSection } from "./Components/QuestionSection";
import * as Messages from "src/constants/Messages";

import { ReviewBox } from "./Components/ReviewBox";
import { ConfirmBox } from "./Components/ConfirmBox";
import {
  setPreNeedDoneChk,
  setPaymentExtraMailsChk,
  setPostQaCheck,
  checkExtraMails,
} from "src/redux/actions/sessionActions";
import * as Helper from "src/Services/Helper";
import { Grid } from "@material-ui/core";
import { createUseStyles } from "react-jss";

const bgimage = localStorage.getItem("postBgImage");
const useStyles = createUseStyles({
  bgImg: {
    background: bgimage
      ? "url(" + bgimage + ") no-repeat"
      : "url(/img/m.jpg) no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
  },
});
export const PostQuestView = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  //declaring const
  const paths = location.pathname.split("/");
  let isPostQueStart = localStorage.getItem("isPostQueStart");
  let isGwQueStart = localStorage.getItem("isGwQueStart");
  const urlname = paths[2];
  const emailtest = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  const alaphatest = new RegExp(/^[a-zA-Z0-9\-\s]+$/);
  const numbRegExp = /^\d+$/;
  const pagetitle = urlname === "gwquest" ? "Great Western" : "Post Question";
  const questionnaireType = urlname === "gwquest" ? 2 : 1;
  //declaring states
  const [QuestionData, setQuestionData] = useState({});
  const [OptionnsData, setOptionnsData] = useState([]);
  const [requiredmobileno, setRequiredMobileNo] = useState(false);
  const [vlidmobileno, setVlidMobileNo] = useState(false);
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState("");
  const [reviewerror, setReviewError] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isReview, setReview] = useState(false);
  const [isConfirm, setConfirm] = useState(false);
  const [sectionquestvalues, setSectionQuestValues] = useState([]);
  const [prevval, setPrevval] = useState("");
  const [nextval, setNextval] = useState("");
  const [skipval, setSkipval] = useState("");
  const [progressval, setProgressval] = useState("");
  const [ReviewData, setReviewData] = useState([]);
  const [isConfirmation, setConfirmation] = useState(false);
  const [confirmerror, setConfirmError] = useState("");
  const [confirmLoadingError, setConfirmLoadingError] = useState("");
  const [reload, setreload] = useState(false);
  const [fileExt, setfileExt] = useState("");
  const [UnitData, setUnitData] = useState([]);
  const [NameError, setNameError] = useState({});
  const [checkExtraMailesCharges, setCheckExtraMailesCharges] = useState(false);

  const [Address, setAddress] = useState({});
  let full_name = "";
  //declaring functions
  const getQuestion = async (
    is_next,
    ans = "",
    islast = 0,
    full_address = ""
  ) => {
    // alert("in get Question");
    let fileName = "";
    setLoading(true);

    const controlid = QuestionData.control;
    const controlhandid = QuestionData.control_handler;
    const stringArray = [1, 5, 6, 8, 9, 10, 11, 12, 13, 14];
    if (answer !== undefined && answer !== "" && controlid === 6) {
      let filepath = answer.split("/");
      fileName = filepath[filepath.length - 1];
    }
    const response = await Http("POST", "user/questionnaire", {
      type: urlname === "gwquest" ? 2 : 1,
      current_question_id: QuestionData.question_id,
      answer: ans !== "" ? ans : answer,
      is_string:
        stringArray.includes(controlid) ||
        (controlid === 4 && controlhandid === 1) ||
        (controlid === 4 && controlhandid === 5) ||
        (controlid === 4 && controlhandid === 6)
          ? 1
          : 0,
      is_next: is_next,
      is_last: islast,
      address: full_address,
      isFileSelected: answer === fileName ? false : true,
    });

    if (response.status === 200) {
      if (
        response.data.is_review !== undefined &&
        (response.data.is_review === 1 ||
          (response.data.is_review === 0 && response.data.is_confirm === 1) ||
          (response.data.is_review === 0 && response.data.is_confirm === 0))
      ) {
        setSectionQuestValues(response.data.sections);
        setPrevval(response.data.question.is_previous);
        setNextval(response.data.question.is_next);
        setSkipval(response.data.question.is_skip);
        setProgressval(response.data.progressbar);
        setConfirm(false);
        getReview();
      } else {
        setQuestionData(response.data.question);
        setOptionnsData(response.data.question.options);
        setAnswer(response.data.question.default_value);
        setUnitData(response.data.question.unit_types);
        setError("");
        setSectionQuestValues(response.data.sections);
        setPrevval(response.data.question.is_previous);
        setNextval(response.data.question.is_next);
        setSkipval(response.data.question.is_skip);
        setProgressval(response.data.progressbar);
        setReview(false);
        setConfirm(false);
        setLoading(false);
      }
    } else {
      console.log(response);
    }
  };
  const getReview = async () => {
    // const controlid = QuestionData.control;
    const reviewresponse = await Http(
      "GET",
      "user/review/" + questionnaireType
    );
    setReviewError("");
    if (reviewresponse !== null && reviewresponse.status === 200) {
      setReview(true);
      setReviewData(reviewresponse.data);
      // setSectionQuestValues(
      //   [...sectionquestvalues],
      //   [...sectionquestvalues][sectionquestvalues.length-2].is_active = true,
      // );
      setConfirm(false);
      setConfirmation(false);
      setLoading(false);
    } else {
      console.log(reviewresponse);
    }
  };
  const getSectionWiseQuest = async (sectionid) => {
    setLoading(true);
    const sectionquesresponse = await Http(
      "POST",
      "user/questions_by_section",
      {
        type: urlname === "gwquest" ? 2 : 1,
        section_id: sectionid,
      }
    );

    if (sectionquesresponse.status === 200) {
      setQuestionData(sectionquesresponse.data.question);
      setOptionnsData(sectionquesresponse.data.question.options);
      setAnswer(sectionquesresponse.data.question.default_value);
      setUnitData(sectionquesresponse.data.question.unit_types);

      setError("");
      setLoading(false);
      setSectionQuestValues(sectionquesresponse.data.sections);
      setPrevval(sectionquesresponse.data.question.is_previous);
      setNextval(sectionquesresponse.data.question.is_next);
      setSkipval(sectionquesresponse.data.question.is_skip);
      setProgressval(sectionquesresponse.data.progressbar);
      setReview(false);
      setConfirm(false);
    } else {
      console.log(sectionquesresponse);
    }
  };
  const getConfirm = async () => {
    const reviewresponse = await Http("POST", "user/update_review", {
      type: urlname === "gwquest" ? 2 : 1,
      is_review: 1,
    });

    if (reviewresponse.status === 200) {
      setReview(false);
      setConfirm(true);
      if (sectionquestvalues[sectionquestvalues.length - 2] !== undefined) {
        setSectionQuestValues(
          [...sectionquestvalues],
          ([...sectionquestvalues][
            sectionquestvalues.length - 2
          ].is_active = false),
          ([...sectionquestvalues][
            sectionquestvalues.length - 2
          ].is_complete = true)
        );
      }
      setAnswer("");
      setLoading(false);
    } else {
      setReviewError(reviewresponse.message);
    }
  };
  const getConfirmation = async () => {
    setLoading(true);
    setConfirmLoadingError(
      "Thank you for completing your application with  Cremation. Your application has now been submitted and will soon be sent to you via email for your signature."
    );
    let url = "";

    if (urlname === "gwquest") {
      url = "user/gen_gw_review_pdf";
    } else {
      url = "user/gen_review_pdf";
    }

    const confirmresponse = await Http("POST", url, {
      type: urlname === "gwquest" ? 2 : 1,
    });

    if (confirmresponse.status === 200) {
      if (urlname === "gwquest") {
        setLoading(false);
        props.setPreNeedDoneChk(true);
        navigate("/app/payment");
      } else {
        props.setPaymentExtraMailsChk(!confirmresponse.data[0].is_extra_miles);
        props.checkExtraMails(confirmresponse.data[0].is_extra_miles);
        props.setPostQaCheck(true);

        setReview(false);
        setConfirm(false);
        setConfirmation(true);
        setConfirmError("");
        setConfirmLoadingError("");
        setSectionQuestValues(
          [...sectionquestvalues],
          ([...sectionquestvalues][
            sectionquestvalues.length - 2
          ].is_active = false),
          ([...sectionquestvalues][
            sectionquestvalues.length - 1
          ].is_complete = true)
        );
        setLoading(false);
      }
    } else {
      setConfirmError(confirmresponse.message);
    }
  };
  const validateForm = async () => {
    if (answer === "" || answer === null || answer === undefined) {
      setError(Messages.FIELD_REQUIRED);
      return false;
    } else {
      if ([2, 3, 4, 5, 7, 8].includes(QuestionData.control)) {
        return true;
      } else if (QuestionData.control === 1) {
        let wordCount = answer.trim().split(/\s+/).length;
        if (!alaphatest.test(answer)) {
          setError(Messages.ONLY_ALPHANUM);
          return false;
        }
        return true;
      } else if (QuestionData.control === 5) {
        let wordCount = answer.trim().split(/\s+/).length;
        if (wordCount < 2) {
          setError(Messages.MIN_2WORDS);
          return false;
        }
        return true;
      } else if (QuestionData.control === 6) {
        if (fileExt !== QuestionData.ext) {
          setError("Please select valid document.");
          return false;
        }
        return true;
      } else if (QuestionData.control === 9) {
        if (!emailtest.test(answer)) {
          setError(Messages.VALID_EMAIL);
          return false;
        }
        return true;
      } else if (QuestionData.control === 10) {
        if (error !== "") {
          // setError(Messages.VALID_EMAIL);
          return false;
        }
        return true;
      } else if (QuestionData.control === 11) {
        if (!numbRegExp.test(answer)) {
          setError(Messages.INVALID_NUMBERS);
          return false;
        }
        if (QuestionData.question_id === 13 && (answer < 1 || answer > 5)) {
          setError(Messages.NOT_MORE_THEN_FIVE);
          return false;
        }
        return true;
      } else if (QuestionData.control === 12) {
        let isvalid = true;
        const {
          address_line_1,
          city,
          state,
          country,
          zip,
          unit_type,
          unit_number,
        } = Address;
        if (
          address_line_1 === "" ||
          city === "" ||
          state === "" ||
          country === "" ||
          zip === ""
        ) {
          isvalid = false;
        }
        if (isvalid) {
          return true;
        } else {
          setError(Messages.FIELD_REQUIRED);
          return false;
        }
      } else if (QuestionData.control === 14) {
        setNameError({});
        let isvalid = true;
        const { first_name, last_name, middle_name } = Address;
        let NameError = {};
        if (first_name === "") {
          isvalid = false;
          NameError = { ...NameError, first_name: Messages.FIELD_REQUIRED };
        }
        if (last_name === "") {
          NameError = { ...NameError, last_name: Messages.FIELD_REQUIRED };
          isvalid = false;
        }
        if (isvalid) {
          full_name = first_name + "," + middle_name + "," + last_name;
        } else {
          setNameError(NameError);
        }
        return isvalid;
      } else {
        setError("");
        return true;
      }
    }
  };

  useEffect(() => {
    //setquestionnaireType(urlname === "gwquest" ? 2 : 1);
    getQuestion();
  }, [reload]);

  return (
    <Page title={pagetitle}>
      <div className="postque">
        <Grid container className="main-body">
          <Grid item md={4} xs={12} className="scroll-md list-que">
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="row"
              //className={classes.bgImg}
            >
              <QuestionSection
                sectiondata={sectionquestvalues}
                setSectionData={setSectionQuestValues}
                getQuestion={getQuestion}
                getReview={getReview}
                getConfirm={getConfirm}
                getSectionWiseQuest={getSectionWiseQuest}
                progressval={progressval}
                review={isReview}
                reviewconfirm={isConfirm}
                confirmationscreen={isConfirmation}
              />
            </Grid>
          </Grid>
          <Grid item md={8} xs={12} className="scroll-md theme-bg-color">
            <div className="postque-bg">
              {(isGwQueStart === null && urlname === "gwquest") ||
              (isPostQueStart === null && urlname === "postquest") ? (
                <div>
                  <Container fluid="md">
                    <div className="this-area">
                      <h2>This is a Secure Area </h2>
                      <p>
                        {" "}
                        Thank you for choosing Fernwood Cemetery. Your Privacy
                        and security are important to us.
                      </p>
                      <p>
                        {" "}
                        if you are using the shared computer, please remember to
                        logout after each session.{" "}
                      </p>
                      <Button
                        variant="outline-light"
                        onClick={() => {
                          urlname === "gwquest"
                            ? localStorage.setItem("isGwQueStart", true)
                            : localStorage.setItem("isPostQueStart", true);
                          setreload(true);
                        }}
                      >
                        Continue
                      </Button>
                    </div>
                  </Container>
                </div>
              ) : isLoading ? (
                <div
                  className="que-bg que-data"
                  style={{ background: "transparent", marginTop: "150px" }}
                >
                  <div className="d-flex justify-content-center  mx-auto">
                    {/* <div className="spinner-grow" role="status"></div> */}
                    <CenterLoader />
                    {/* <img
                      src="/img/loader.gif"
                      height={}
                      // className="spinner"
                      // loading="lazy"
                    /> */}
                  </div>
                  {confirmLoadingError !== "" && (
                    <div
                      className="invalid-feedback d-block"
                      style={{ textAlign: "center" }}
                    >
                      {confirmLoadingError}
                    </div>
                  )}
                </div>
              ) : isReview ? (
                <ReviewBox
                  sectiondata={sectionquestvalues}
                  data={ReviewData}
                  getSectionWiseQuest={getSectionWiseQuest}
                  getConfirm={getConfirm}
                  reviewerror={reviewerror}
                />
              ) : isConfirm ? (
                <ConfirmBox
                  sectiondata={sectionquestvalues}
                  setSectionData={setSectionQuestValues}
                  getConfirm={getConfirm}
                  getReview={getReview}
                  getConfirmation={getConfirmation}
                  answer={answer}
                  setAnswer={setAnswer}
                  confirmerror={confirmerror}
                  setConfirmError={setConfirmError}
                />
              ) : isConfirmation ? (
                <div>
                  <Container fluid="md">
                    <Card className="mb-2" style={{ width: "100%" }}>
                      <Card.Body className="list-group-flush">
                        <Card.Text style={{ color: "#000" }}>
                          Review pdf and mail send successfully.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Container>
                </div>
              ) : (
                <div className="que-data">
                  <QuestionBox
                    data={QuestionData}
                    options={OptionnsData}
                    requiredmobileno={requiredmobileno}
                    vlidmobileno={vlidmobileno}
                    setRequiredMobileNo={setRequiredMobileNo}
                    setVlidMobileNo={setVlidMobileNo}
                    answer={answer}
                    error={error}
                    setAnswer={setAnswer}
                    validateForm={validateForm}
                    setError={setError}
                    getQuestion={getQuestion}
                    getReview={getReview}
                    setfileExt={setfileExt}
                    setAddress={setAddress}
                    UnitData={UnitData}
                    NameError={NameError}
                    setNameError={setNameError}
                  />
                  <div className="slide-section">
                    <div className="row">
                      <div className="col-md-12">
                        <ul>
                          <li>
                            {(prevval === "" || prevval !== 1) && (
                              <Button
                                color="primary"
                                variant="outline-light"
                                onClick={() => {
                                  if (prevval !== 1) {
                                    getQuestion(false);
                                  }
                                }}
                              >
                                Previous question
                              </Button>
                            )}
                          </li>
                          <li>
                            {(skipval === "" || skipval === 1) && (
                              <Button
                                color="primary"
                                variant="outline-light"
                                onClick={() => {
                                  if (QuestionData.is_skip === 1) {
                                    getQuestion(true);
                                  }
                                }}
                              >
                                Skip for Now
                              </Button>
                            )}
                          </li>
                          <li>
                            {(nextval === "" || nextval !== 1) && (
                              <Button
                                color="primary"
                                variant="outline-light"
                                onClick={async () => {
                                  let isValid = await validateForm();
                                  if (isValid) {
                                    if (QuestionData.control === 12) {
                                      getQuestion(
                                        true,
                                        Address.locationAddress,
                                        QuestionData.is_last,
                                        Address
                                      );
                                    } else if (QuestionData.control === 14) {
                                      getQuestion(
                                        true,
                                        full_name,
                                        QuestionData.is_last,
                                        Address
                                      );
                                    } else {
                                      getQuestion(true);
                                    }
                                  }
                                }}
                              >
                                Next Question
                              </Button>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* {(progressval !== undefined || progressval !== "") && (
                    <ProgressBar
                      progress={progressval.index}
                      totprogress={progressval.total}
                    />
                  )} */}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const mapStateToProps = (state, ownProps) => ({
  currentId: parseInt(ownProps.id, 10),
  pageNumber: parseInt(ownProps.id, 10),
});

const mapDispatchToProps = {
  setPreNeedDoneChk,
  setPaymentExtraMailsChk,
  setPostQaCheck,
  checkExtraMails,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostQuestView);
