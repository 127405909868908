import React, { Component } from "react";
import { connect } from "react-redux";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate } from "react-router-dom";

import { Http } from "src/Services/Http";
import * as Helper from "src/Services/Helper";
import * as Messages from "src/constants/Messages";
import CenterLoader from "src/Components/Loader/CenterLoader";
import ProgressBar from "src/Components/ProgressBar";
import Page from "src/Components/Page";
import { QuestionBox } from "./Components/QuestionBox";
import { Paper } from "@material-ui/core";
export class PreQuestView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      Token: "",
      error: "",
      Data: "",
      Answer: "",
      redirect: null,
      processbar: {
        index: 0,
        total: 0,
      },
      status: 200,
      message: "",
    };
  }

  setLoading = (arg) => this.setState({ isLoading: arg });
  setToken = async (arg) => await this.setState({ Token: arg });
  setError = (arg) => this.setState({ error: arg });
  setData = (arg) => this.setState({ Data: arg });
  setAnswer = (arg) => this.setState({ Answer: arg });
  setProgressBar = (arg) => this.setState({ processbar: arg });
  setResponse = (arg) => {
    this.setState({ status: arg.status });
    this.setState({ message: arg.message });
  };

  getQuestion = async () => {
    const {
      setLoading,
      setError,
      setData,
      setAnswer,
      setProgressBar,
      setResponse,
    } = this;
    const { Token } = this.state;
    setLoading(true);
    setError("");
    await Http("POST", "guest/questionnaire", {
      type: 0,
      device_id: Token,
      is_last_attended: parseInt(localStorage.getItem("is_last")),
    }).then(({ data, status, message }) => {
      if (status === 200) {
        setData(data);
        setAnswer(data.question.default_value);
        setProgressBar(data.progressbar);
      } else if (status === 400) {
        this.setState({ redirect: "/register" });
      }
      setResponse({ status, message });
    });
    setLoading(false);
  };

  postQuestion = async (arg, answer, isString) => {
    const {
      setLoading,
      setError,
      setData,
      setAnswer,
      setProgressBar,
      setResponse,
    } = this;
    const { Token, Data, Answer } = this.state;
    setLoading(true);
    setError("");
    await Http("POST", "guest/questionnaire", {
      type: 0,
      device_id: Token,
      answer: answer ? answer : Answer,
      current_question_id: Data.question.question_id,
      is_next: arg,
      is_string: isString ? isString : false,
    }).then(async ({ data, status, message }) => {
      if (status === 200) {
        if (data.question.is_last === 1) {
          localStorage.setItem("is_last", 1);
        }
        await setData(data);
        await setAnswer(data.question.default_value);
        setProgressBar(data.progressbar);
      } else if (status === 400) {
        localStorage.setItem("stage", 1);
        this.setState({ redirect: "/register" });
      }
      setResponse({ status, message });
      setLoading(false);
    });
  };
  componentDidMount = async () => {
    const { setItem, getItem } = Helper;
    const { setLoading, setToken, getQuestion } = this;

    let token = await getItem("rand_token");
    if (token === null || token === "undefined") {
      await setItem("rand_token", Math.random().toString(36).substr(2));
      await setItem("is_last", 0);
    }
    token = await getItem("rand_token");
    await setToken(token);
    await getQuestion();
    await setLoading(false);
  };

  render() {
    const css = `
    .dynamic-btn {
      outline: none;
      -webkit-appearance: none;
      background-color: #0d6efd10;
      border: 1px solid #0d6efd!important;
      color: #0d6efd!important;
      text-transform: none;
    }
    .dynamic-btn:hover {
      -webkit - appearance: none;
      color: #fff!important;
      background-color: #0d6efd!important;
      border: 1px solid #0d6efd!important;
      text-transform: none;
    }
    .dynamic-btn:active {
      -webkit - appearance: none;
      color: #fff!important;
      background-color: #0d6efd!important;
      border: 1px solid #0d6efd!important;
      text-transform: none;
    }`;
    const {
      isLoading,
      Data,
      Answer,
      error,
      redirect,
      processbar,
      status,
      message,
    } = this.state;
    const { setError, setAnswer, postQuestion } = this;
    if (redirect) {
      return <Navigate to={redirect} />;
    }
    if (status === 500) {
      return (
        <Paper elevation={3} style={{ padding: 25, minHeight: 280 }}>
          {message}
        </Paper>
      );
    }
    return (
      <Page title="Fernwood Cemetery Preliminary Questionnaire">
        <style>{css}</style>
        <div className="que-section">
          <div className="row">
            <div className="col-md-2 my-auto"></div>
            <div className="col-md-8 my-auto">
              <div className="que-top-pre">
                <p>
                  Fernwood Cemetery offers simple direct cremation options that
                  can be arranged from the comfort of your home with the help of
                  our compassionate staff.
                </p>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
          {/* <ExampleComponent /> */}
          <div className="row">
            <div className="col-md-2 my-auto"></div>
            <div className="col-md-8 my-auto">
              <Paper elevation={3} style={{ padding: 25, minHeight: 280 }}>
                {isLoading ? (
                  <CenterLoader />
                ) : (
                  <QuestionBox
                    answer={Answer}
                    setAnswer={setAnswer}
                    error={error}
                    setError={setError}
                    Data={Data}
                    postQuestion={postQuestion}
                    isIframe={false}
                  />
                )}
              </Paper>
              <div className="slide-section">
                <div className="row">
                  <div className="col-md-12">
                    <div className="slide-arrows">
                      {!isLoading && (
                        <ul>
                          <li
                            onClick={() => {
                              if (Data.question.is_previous !== 1) {
                                postQuestion(false, null, 1);
                              }
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faArrowCircleLeft}
                              color="#fff"
                              size="2x"
                            />
                            Previous
                          </li>
                          <li
                            onClick={() => {
                              if (Answer === "") {
                                setError(Messages.FIELD_REQUIRED);
                                return false;
                              } else {
                                if (error === "") {
                                  postQuestion(true, null, 1);
                                }
                              }
                            }}
                          >
                            Next
                            <FontAwesomeIcon
                              icon={faArrowCircleRight}
                              color="#fff"
                              size="2x"
                            />
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 my-auto"></div>
          </div>
          <ProgressBar progress={processbar} />
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PreQuestView);
