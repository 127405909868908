import React, { Component } from "react";
import { connect } from "react-redux";
import { Http } from "src/Services/Http";
import * as Helper from "src/Services/Helper";
import { QuestionBox } from "../PreQuestView/Components/QuestionBox";

import { Grid, Button, Link } from "@material-ui/core";
import CenterLoader from "src/Components/Loader/CenterLoader";
import BorderLinearProgress from "./BorderLinearProgress";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export class IframeContentView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      Token: "",
      error: "",
      Data: "",
      Answer: "",
      redirect: null,
      processbar: 0,
    };
  }

  setLoading = (arg) => this.setState({ isLoading: arg });
  setToken = async (arg) => await this.setState({ Token: arg });
  setError = (arg) => this.setState({ error: arg });
  setData = (arg) => this.setState({ Data: arg });
  setAnswer = (arg) => this.setState({ Answer: arg });
  setProgressBar = async (arg) => {
    let process = (arg.index / arg.total) * 100;
    await this.setState({ processbar: process });
  };

  getQuestion = async () => {
    const { setLoading, setError, setData, setAnswer, setProgressBar } = this;
    const { Token } = this.state;
    setLoading(true);
    setError("");
    await Http("POST", "guest/questionnaire", {
      type: 0,
      device_id: Token,
      is_last_attended: parseInt(localStorage.getItem("is_last")),
    }).then(async ({ data, status }) => {
      if (status === 200) {
        await setData(data);
        await setAnswer(data.question.default_value);
        await setProgressBar(data.progressbar);
      } else if (status === 400) {
        await this.setState({ processbar: 100 });
        //window.open("/register", "_blank");
        localStorage.setItem("stage", 1);
      }
    });
    setLoading(false);
  };

  postQuestion = async (arg, answer, isString) => {
    const { setLoading, setError, setData, setAnswer, setProgressBar } = this;
    const { Token, Data, Answer } = this.state;
    setLoading(true);
    setError("");
    await Http("POST", "guest/questionnaire", {
      type: 0,
      device_id: Token,
      answer: answer ? answer : Answer,
      current_question_id: Data.question.question_id,
      is_next: arg,
      is_string: isString,
    }).then(async ({ data, status }) => {
      if (status === 400) {
        //this.setState({ redirect: "/register" });
        window.open("/register", "_blank");
        localStorage.setItem("stage", 1);
      } else {
        if (localStorage.getItem("is_last") === 1) {
          //this.setState({ redirect: "/register" });
          //window.open("/register", "_blank");
          localStorage.setItem("stage", 1);
        } else {
          await setData(data);
          await setAnswer(data.question.default_value);
          await setProgressBar(data.progressbar);
          localStorage.setItem("is_last", data.question.is_last);
        }
      }
      setLoading(false);
    });
  };

  componentDidMount = async () => {
    const { setItem, getItem } = Helper;
    const { setLoading, setToken, getQuestion } = this;
    let token = await getItem("rand_token");
    if (token === null || token === "undefined") {
      await setItem("rand_token", Math.random().toString(36).substr(2));
      await setItem("is_last", 0);
    }
    token = await getItem("rand_token");
    await setToken(token);
    await getQuestion();
    await setLoading(false);
  };

  render() {
    const { isLoading, Data, Answer, error, processbar } = this.state;
    const { setError, setAnswer, postQuestion } = this;
    // alert(this.props.color);
    return (
      <Grid container direction="column">
        <Grid item>
          <Grid container justifyContent="center">
            <BorderLinearProgress
              variant={"determinate"}
              value={processbar}
              customcolor={this.props.color}
            />
          </Grid>
        </Grid>
        {isLoading ? (
          <CenterLoader />
        ) : Data.length <= 0 ||
          (Data?.question?.is_last === 1 &&
            Data?.question?.default_value !== "") ? (
          <Grid
            container
            // justifyContent="space-between"
            direction="column"
            alignItems="center"
          >
            <Grid item style={{ margin: 20 }}>
              Your pre-questionnaire has been completed.
            </Grid>
            <Grid item md={2}>
              <Button
                onClick={() => {
                  try {
                    window.open("/register", "_blank");
                  } catch (e) {
                    alert(e);
                  }
                }}
                color="primary"
                variant="outlined"
                fullWidth={false}
              >
                Please Register
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column" alignItems="center">
            <QuestionBox
              answer={Answer}
              setAnswer={setAnswer}
              error={error}
              setError={setError}
              Data={Data}
              postQuestion={postQuestion}
              isIframe={true}
            />
            <Grid container justifyContent="center">
              {Data.question?.is_previous !== 1 && (
                
                <Link 
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    postQuestion(false, null, 1);
                  }}
                >
                  <ArrowBackIcon fontSize="small" />
                  Back
                </Link>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IframeContentView);
