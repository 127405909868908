import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  step: {
    width: 100,
  },
}));

function getSteps(arg) {
  if (!arg) {
    return ["Your Details", "Select Services", "Make Payment"];
  } else {
    return [
      "Your Details",
      "Select Services",
      "Insurance",// "Great western Questionary",
      "Payment",// "Make Payment",
    ];
  }
}

export default function CustomStepper({ currentStep }) {
  const isGWApplicable = useSelector(
    (state) => state.session.questconditionchk
  );
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(currentStep);
  const steps = getSteps(isGWApplicable);

  return (
        <div className={classes.root}>
          <Stepper className="stepper_main" activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {/* <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep)}
                </Typography>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            )}
          </div> */}
        </div>
  );
}
