import React from "react";
import { useRoutes } from "react-router-dom";
import { configureStore } from "src/redux/store";
import { Provider as StoreProvider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

import "./App.css";
import routes from "src/Routes";

const App = () => {
  const store = configureStore();
  const isLoggedin = store.getState().session.loggedIn;
  const isVerified = store.getState().session.verified;
  const isPaymentChk = store.getState().session.paymentchk;
  const isQuestConditionChk = store.getState().session.questconditionchk;
  const isPreNeedDoneChk = store.getState().session.preeneeddonechk;
  const isPackageSeleted = store.getState().session.is_package_selected;
  const payment_extra_mailes_chk = store.getState().session.payment_extra_mailes_chk;
  const payment_extra_mailes_chk_tem = store.getState().session.payment_extra_mailes_chk_tem;
  const routing = useRoutes(
    routes(
      isLoggedin,
      isVerified,
      isPaymentChk,
      isQuestConditionChk,
      isPreNeedDoneChk,
      isPackageSeleted,
      payment_extra_mailes_chk,
      payment_extra_mailes_chk_tem
    )
  );
  
  return (
    <StoreProvider store={store}>
      <HelmetProvider>{routing}</HelmetProvider>
    </StoreProvider>
  );
}; 

export default App;
