import React from "react";
import { Grid } from "@mui/material";
import Page from "src/Components/Page";
import QuestionBox from "./QuestionBox";

export default function PreQuest({ color }) {
  return (
    <Page title="Fernwood Cemetery — Cremation Arrangements">
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <div className="que-top-pre">
            <p>
              Fernwood Cemetery offers simple direct cremation options that can
              be arranged from the comfort of your home with the help of our
              compassionate staff.
            </p>
          </div>
        </Grid>
        <QuestionBox color={color} />
      </Grid>
    </Page>
  );
}
