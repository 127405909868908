import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, customcolor }) => ({
    height: 15,
    width: "80%",
    alignSelf: "center",
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: customcolor + "10",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundColor: customcolor + "60",
    },
    marginTop: 20,
    marginBottom: 20,
  })
);

export default BorderLinearProgress;
