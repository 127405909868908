import { TextField } from "@material-ui/core";
import React from "react";
import { Form } from "react-bootstrap";

export default function TextBox({
  type,
  placeholder,
  answer,
  error,
  setAnswer,
}) {
  return (
    <div className="col-md-12 ans-fill">
      <Form.Group>
        <TextField
          name="answer"
          type={type}
          label={placeholder}
          value={answer}
          onBlur={(e) => setAnswer(e.target.value)}
          onChange={(e) => setAnswer(e.target.value)}
          margin="normal"
          fullWidth={true}
          autoFocus={true}
          autoComplete={false}
        />
        {error !== "" && (
          <Form.Control.Feedback type="invalid" className=" d-block">
            {error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </div>
  );
}
