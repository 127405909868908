import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import StepButton from "@material-ui/core/StepButton";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import StepConnector from "@material-ui/core/StepConnector";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
// import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Grid } from "@material-ui/core";
import Page from "src/Components/Page";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 20px)",
    right: "calc(50% + 27px)",
  },
  /*active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },*/
  line: {
    borderColor: "#dadada",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles((theme) => ({
  /*root: {
   	 	width: '100%',
    	marginTop:'20%',
  	},
  	connector: {
    	display: 'none',
  	},
  	step: {
    	marginBottom: theme.spacing.unit * 2,
  	},*/

  connector: {
    display: "none",
  },
  root: {
    width: "100%",
    marginTop: "15%",
  },
  iconContainer: {
    transform: "scale(2)",
    marginRight: theme.spacing.unit * 1,
  },
  /*labelContainer: {
	    "& $alternativeLabel": {
	      marginTop: 0
	    }
	},*/
  step: {
    "& $completed": {
      color: "lightgreen",
    },
    "& $active": {
      color: "#7b89d3",
    },
    "& $disabled": {
      color: "red",
    },
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 15,
    },
  },
}));

export const HomeView = () => {
  const classes = useStyles();
  const conditionData = useSelector((state) => state.session);
  const [active, setActive] = useState(0);
  const [stepDatas, setStepDatas] = useState([]);
  // const [classstr, setClassstr] = useState("");
  let classstr = "stepper__link ";
  useEffect(() => {
    if (conditionData.questconditionchk) {
      if (conditionData.preeneeddonechk) {
        if (conditionData.paymentchk) {
          if (conditionData.extra_mailes_chk) {
            if (conditionData.payment_extra_mailes_chk_tem) {
              setStepDatas([
                { name: "Preliminary Questionnaire", url: "/app/prequestlist" },
                { name: "Your Information", url: "/app/profile" },
                { name: "Your Package", url: "" },
                { name: "Pre-Need Questionnaire", url: "/app/gwquest" },
                { name: "Payment", url: "" },
                { name: "At-Need Questionnaire", url: "/app/postquest" },
                { name: "Service Area Calculation", url: "" },
                { name: "Complete", url: "" },
              ]);
            } else {
              setStepDatas([
                { name: "Preliminary Questionnaire", url: "/app/prequestlist" },
                { name: "Your Information", url: "/app/profile" },
                { name: "Your Package", url: "" },
                { name: "Pre-Need Questionnaire", url: "/app/gwquest" },
                { name: "Payment", url: "" },
                { name: "At-Need Questionnaire", url: "/app/postquest" },
                {
                  name: "Service Area Calculation",
                  url: "/app/extramailespayment",
                },
                { name: "Complete", url: "" },
              ]);
            }
          } else {
            setStepDatas([
              { name: "Preliminary Questionnaire", url: "/app/prequestlist" },
              { name: "Your Information", url: "/app/profile" },
              { name: "Your Package", url: "" },
              { name: "Pre-Need Questionnaire", url: "/app/gwquest" },
              { name: "Payment", url: "" },
              { name: "At-Need Questionnaire", url: "/app/postquest" },
              { name: "Complete", url: "" },
            ]);
          }
        } else {
          setStepDatas([
            { name: "Preliminary Questionnaire", url: "/app/prequestlist" },
            { name: "Your Information", url: "/app/profile" },
            { name: "Your Package", url: "" },
            { name: "Pre-Need Questionnaire", url: "/app/gwquest" },
            { name: "Payment", url: "/app/payment" },
            { name: "At-Need Questionnaire", url: "" },
            { name: "Complete", url: "" },
            // { name: "At-Need Questionnaire2", url: "/app/postquest" },
          ]);
        }
      } else {
        if(conditionData.is_package_selected){
          setStepDatas([
            { name: "Preliminary Questionnaire", url: "/app/prequestlist" },
            { name: "Your Information", url: "/app/profile" },
            { name: "Your Package", url: "/app/packages" },
            { name: "Pre-Need Questionnaire", url: "/app/gwquest" },
            // { name: "Payment3", url: "/app/payment" },
            // { name: "At-Need Questionnaire", url: "/app/postquest" },
            { name: "Payment", url: "" },
            { name: "At-Need Questionnaire", url: "" },
            { name: "Complete", url: "" },
          ]);
        }else{
          setStepDatas([
            { name: "Preliminary Questionnaire", url: "/app/prequestlist" },
            { name: "Your Information", url: "/app/profile" },
            { name: "Your Package", url: "/app/packages" },
            // { name: "Pre-Need Questionnaire", url: "/app/gwquest" },
            // { name: "Payment3", url: "/app/payment" },
            // { name: "At-Need Questionnaire", url: "/app/postquest" },
            { name: "Pre-Need Questionnaire", url: "" },
            { name: "Payment", url: "" },
            { name: "At-Need Questionnaire", url: "" },
            { name: "Complete", url: "" },
          ]);
        }
      }
    } else {
      if (conditionData.paymentchk) {
        if (conditionData.extra_mailes_chk) {
          if (conditionData.payment_extra_mailes_chk_tem) {
            setStepDatas([
              { name: "Preliminary Questionnaire", url: "/app/prequestlist" },
              { name: "Your Information", url: "/app/profile" },
              { name: "Your Package", url: "" },
              { name: "Payment", url: "" },
              { name: "At-Need Questionnaire", url: "/app/postquest" },
              { name: "Service Area Calculation", url: "" },
              { name: "Complete", url: "" },
            ]);
          } else {
            setStepDatas([
              { name: "Preliminary Questionnaire", url: "/app/prequestlist" },
              { name: "Your Information", url: "/app/profile" },
              { name: "Your Package", url: "" },
              { name: "Payment", url: "" },
              { name: "At-Need Questionnaire", url: "/app/postquest" },
              // { name: "Extra Mailes Charges", url: "/app/extramailespayment" },
              { name: "Service Area Calculation", url: "/app/extramailespayment" },
              { name: "Complete", url: "" },
            ]);
          }
        } else {
          setStepDatas([
            { name: "Preliminary Questionnaire", url: "/app/prequestlist" },
            { name: "Your Information", url: "/app/profile" },
            { name: "Your Package", url: "" },
            { name: "Payment", url: "" },
            { name: "At-Need Questionnaire", url: "/app/postquest" },
            { name: "Complete", url: "" },
          ]);
        }
      } else {
        if(conditionData.is_package_selected){
          setStepDatas([
            { name: "Preliminary Questionnaire", url: "/app/prequestlist" },
            { name: "Your Information", url: "/app/profile" },
            { name: "Your Package", url: "/app/packages" },
            { name: "Payment", url: "/app/payment" },
            // { name: "At-Need Questionnaire", url: "/app/postquest" },
            // { name: "Complete", url: "" },
            { name: "At-Need Questionnaire", url: "" },
            { name: "Complete", url: "" },
          ]);
        }
        else{
          setStepDatas([
            { name: "Preliminary Questionnaire", url: "/app/prequestlist" },
            { name: "Your Information", url: "/app/profile" },
            { name: "Your Package", url: "/app/packages" },
            // { name: "Payment", url: "/app/payment" },
            // { name: "At-Need Questionnaire", url: "/app/postquest" },
            // { name: "Complete", url: "" },
            { name: "Payment", url: "" },
            { name: "At-Need Questionnaire", url: "" },
            { name: "Complete", url: "" },
          ]);
        }
        
      }
    }

    // let classstr = "stepper__link";
    if (conditionData.preeneeddonechk) {
      if (
        conditionData.verified &&
        !conditionData.is_package_selected &&
        !conditionData.paymentchk
      ) {
        setActive(2);
      } else if (
        conditionData.verified &&
        conditionData.is_package_selected &&
        !conditionData.preeneeddonechk &&
        !conditionData.paymentchk
      ) {
        setActive(3);
      } else if (
        conditionData.verified &&
        conditionData.is_package_selected &&
        conditionData.preeneeddonechk &&
        !conditionData.paymentchk
      ) {
        setActive(4);
      } else if (
        conditionData.verified &&
        conditionData.is_package_selected &&
        conditionData.paymentchk &&
        !conditionData.check_post_qa
      ) {
        setActive(5);
      } else if (
        conditionData.check_post_qa &&
        conditionData.verified &&
        conditionData.is_package_selected &&
        conditionData.paymentchk &&
        !conditionData.payment_extra_mailes_chk_tem
      ) {
        setActive(6);
      } else if (
        conditionData.verified &&
        conditionData.is_package_selected &&
        conditionData.paymentchk &&
        conditionData.payment_extra_mailes_chk_tem
      ) {
        setActive(7);
      }
    } else {
      if (
        conditionData.verified &&
        !conditionData.is_package_selected &&
        !conditionData.paymentchk
      ) {
        setActive(2);
      } else if (
        conditionData.verified &&
        conditionData.is_package_selected &&
        !conditionData.paymentchk
      ) {
        setActive(3);
      } else if (
        conditionData.verified &&
        conditionData.is_package_selected &&
        conditionData.paymentchk &&
        !conditionData.check_post_qa
      ) {
        setActive(4);
      } else if (
        conditionData.check_post_qa &&
        conditionData.verified &&
        conditionData.is_package_selected &&
        conditionData.paymentchk &&
        !conditionData.payment_extra_mailes_chk_tem
      ) {
        setActive(5);
      } else if (
        conditionData.verified &&
        conditionData.is_package_selected &&
        conditionData.paymentchk &&
        conditionData.payment_extra_mailes_chk_tem
      ) {
        setActive(6);
      }
    }
  }, [setActive, setStepDatas]);

  return (
    <Page title={"Home"}>
      <Grid
        container
        direction="row"
        style={{
          backgroundImage: `url("/img/Rectangle24.png")`,
          backgroundSize: "cover",
          height: "100vh",
          backgroundPosition: "center",
          position: "relative",
          zIndex: "0",
        }}
        className="homesection_main"
      >
        <div className="container">
          <Grid container item direction="row" xs={12} spacing={2}>
            <Box className="home_box" classes={{ root: classes.root }}>
              {/* <Stepper
              activeStep={active}
              alternativeLabel
              connector={<QontoConnector />}
              classes={{ root: classes.root }}
            >
              {stepDatas.length > 0 &&
                stepDatas.map((label, index) => (
                  <Step
                    key={index}
                    classes={{
                      root: classes.step,
                      completed: classes.completed,
                      active: classes.active,
                    }}
                  >
                    <StepButton component={Link} to={label.url}>
                      <StepLabel
                        classes={{
                          alternativeLabel: classes.alternativeLabel,
                          labelContainer: classes.labelContainer,
                          iconContainer: classes.iconContainer,
                        }}
                        StepIconProps={{
                          classes: {
                            root: classes.step,
                            completed: classes.completed,
                            active: classes.active,
                            disabled: classes.disabled,
                          },
                        }}
                      >
                        {label.name}
                      </StepLabel>
                    </StepButton>
                  </Step>
                ))}
            </Stepper> */}
              <nav className="stepper__wrapper">
                <ul className="stepper">
                  {stepDatas.length > 0 &&
                    stepDatas.map((label, index) => {
                      if (index === active) {
                        return (
                          <li className="stepper__item" key={index}>
                            <Link
                              component={Link}
                              to={label.url}
                              className={classstr + " stepper__link--active"}
                            >
                              <div className="stepper-box">
                                <span className="stepper__icon">
                                  <span className="badge">{index + 1}</span>
                                </span>
                                <span className="stepper_text">
                                  {label.name}
                                </span>
                              </div>
                            </Link>
                          </li>
                        );
                      } else if (index < active) {
                        return (
                          <li className="stepper__item" key={index}>
                            <Link
                              component={Link}
                              to={label.url}
                              className={classstr + " stepper__link--done"}
                            >
                              <div className="stepper-box">
                                <span className="stepper__icon">
                                  {/* <span className="badge">{index + 1}</span> */}
                                  <CheckCircleIcon fontSize="small" />
                                </span>
                                <span className="stepper_text">
                                  {label.name}
                                </span>
                              </div>
                            </Link>
                          </li>
                        );
                      } else {
                        return (
                          <li className="stepper__item" key={index}>
                            <Link
                              component={Link}
                              to={label.url}
                              className={classstr + " stepper__link--disabled"}
                            >
                              <div className="stepper-box">
                                <span className="stepper__icon">
                                  <span className="badge">{index + 1}</span>
                                  {/* <CheckCircleIcon fontSize="small" /> */}
                                </span>
                                <span className="stepper_text">
                                  {label.name}
                                </span>
                              </div>
                            </Link>
                          </li>
                        );
                      }
                    })}
                </ul>
              </nav>
            </Box>
          </Grid>
          <Grid
            item
            direction="row"
            xs={12}
            spacing={2}
            className="cwlo-btn-pt"
          >
            <Button
              className="homenavbutton"
              component={Link}
              to={
                stepDatas[active]?.url !== undefined
                  ? stepDatas[active]?.url
                  : ""
              }
              // component={Link}
              // to={stepDatas[active]?.url}
              variant="contained"
            >
              Continue where I left off
            </Button>
            {/* <Button
              className="homenavbutton"
              variant="contained"
              component={Link}
              to={
                [0, 2].includes(active) && stepDatas.length > 0
                  ? stepDatas[0]?.url
                  : conditionData.is_package_selected &&
                    stepDatas.length > 0 &&
                    !conditionData.paymentchk
                  ? stepDatas[3]?.url
                  : stepDatas.length > 0 && conditionData.paymentchk
                  ? stepDatas[5]?.url
                  : ""
              }
            >
              Review my answers
            </Button> */}
          </Grid>
        </div>
      </Grid>
    </Page>
  );
};

export default HomeView;
