import React from "react";
import { Form } from "react-bootstrap";

export default function SelectBox({ answer, setAnswer, options, error }) {
  return (
    <div className="col-md-12 ans-fill">
      <Form.Group>
        <Form.Control
          as="select"
          value={answer}
          onChange={(e) => {
            setAnswer(e.target.value);
          }}
        >
          <option value="">Select</option>
          {options.map((option, index) => {
            return (
              <option key={index} value={option.id}>
                {option.answer}
              </option>
            );
          })}
        </Form.Control>
        {error !== "" && (
          <Form.Control.Feedback type="invalid" className=" d-block">
            {error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </div>
  );
}
