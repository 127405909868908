import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
//import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CategorySelection from "../CategorySelection/index";
import { Http } from "src/Services/Http";
import { useDispatch } from "react-redux";
import { setPackageSelected } from "src/redux/actions";
import CustomStepper from "src/Components/Stepper";
import { Grid } from "@mui/material";
import { Divider } from "@material-ui/core";

export default function PackageBody({
  data,
  crtPrice,
  QuestionConditionChk,
  PreneedDoneChk,
  getExtraMailesCharges,
  extraMileServicePrice,
  setExtraMileServicePrice,
  ...otherProps
}) {
  const { name, price, description, addonCategory } = data;
  const navigate = useNavigate();
  const [isPaymentLoading, setisPaymentLoading] = useState(false);
  const [errorMessageForUrn, setErrorMessageForUrn] = useState("");
  const [errorMessageForAdditionServices, setErrorMessageForAdditionServices] =
    useState("");
  const [errorMessageForDelivery, setErrorMessageForDelivery] = useState("");
  const [errorMessageForDeliveryAddress, setErrorMessageForDeliveryAddress] =
    useState("");
  const [errorMessageForPeacemaker, setErrorMessageForPeacemaker] =
    useState("");
  const [errorMessageForServiceArea, setErrorMessageForServiceArea] =
    useState("");
  const [
    errorMessageForServiceAreaAddress,
    setErrorMessageForServiceAreaAddress,
  ] = useState("");

  const dispatch = useDispatch();
  const savePackages = async () => {
    setisPaymentLoading(true);
    let addOns = [];
    //let addOnsData = [];

    let isErrro = false;
    let selectedCategories = [];

    let deliveryAddress = "";
    let serviceAreaAddress = "";
    setErrorMessageForUrn("");
    setErrorMessageForAdditionServices("");
    setErrorMessageForDelivery("");
    setErrorMessageForDeliveryAddress("");
    setErrorMessageForPeacemaker("");
    setErrorMessageForServiceArea("");
    setErrorMessageForServiceAreaAddress("");
    otherProps.selected.map((item, index) => {
      if (!selectedCategories.includes(item.catId)) {
        selectedCategories.push(item.catId);
      }
      if (
        item.address !== undefined &&
        item.address !== "" &&
        item.catId === 2
      ) {
        deliveryAddress = item.address;
      }
      if (
        item.address !== undefined &&
        item.address !== "" &&
        item.catId === 32
      ) {
        serviceAreaAddress = item.address;
      }

      return addOns.push({
        id: item.id,
        quantity: item.quantity ? item.quantity : 0,
        state: item.state !== undefined ? item.state : "",
        address: item.address !== undefined ? item.address : "",
        city: item.city !== undefined ? item.city : "",
        country: item.country !== undefined ? item.country : "",
        lat: item.lat !== undefined ? item.lat : "",
        long: item.long !== undefined ? item.long : "",
        zip: item.zip !== undefined ? item.zip : "",
      });
    });

    const caterror = document.getElementById("caterror");
    // if (!selectedCategories.includes(1) && caterror) {
    //   isErrro = true;
    //   caterror.scrollIntoView({ behavior: "smooth" });
    //   setErrorMessageForUrn("Please select urn(s)");
    // } else
    if (!selectedCategories.includes(28) && caterror) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForAdditionServices(
        "To proceed, please choose an Additional Service."
      );
    } else if (!selectedCategories.includes(2) && caterror) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForDelivery("Choose a Delivery method to proceed.");
    } else if (
      selectedCategories.includes(2) &&
      deliveryAddress === "" &&
      caterror
    ) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForDeliveryAddress(
        "Enter the Shipping address to proceed."
      );
    } else if (!selectedCategories.includes(31) && caterror) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForPeacemaker(
        "A response to the Pacemaker question is required."
      );
    } else if (!selectedCategories.includes(32) && caterror) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForServiceArea(
        "To proceed, please provide a Service Area information."
      );
    } else if (
      selectedCategories.includes(32) &&
      serviceAreaAddress === "" &&
      caterror
    ) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForServiceAreaAddress(
        "Enter the Service Area address to proceed."
      );
    }

    if (!isErrro) {
      const package_id = JSON.parse(localStorage.getItem("packageData")).id;

      let local_discount = localStorage.getItem("discount");

      let discount_id = local_discount
        ? JSON.parse(local_discount).discount_id
        : "";

      let body = {
        package_id: package_id,
        addons_ids: addOns,
        // discount_id: discount_id,
        user_id: JSON.parse(localStorage.getItem("state")).session.user.id,
        no_of_cert: otherProps.certificate,
      };
      await Http("POST", "add_subscription_details", body).then((response) => {
        if (response.status === 200) {
          dispatch(setPackageSelected(true));
          QuestionConditionChk && !PreneedDoneChk
            ? navigate("/app/gwquest")
            : // navigate("/app/payment");   // working condition
              navigate("/app/important-notice"); //test purpose cretae this path
        } else {
          alert(response.message);
        }
      });
    }
    setisPaymentLoading(false);
  };
  // console.log("data", data);

  return (
    <div
      className="scrollable-section"
      style={{
        backgroundImage: `url("/img/Rectangle24.png")`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        position: "relative",
        zIndex: "0",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <CustomStepper currentStep={1} />
          </div>
        </div>
        <div className="row package-main package-custom-main">
          <div className="col-lg-8 col-md-12 mb-4">
            <div className="selectServices selectServices-custom ">
              <div className="first-content">
                <h1>Select Services</h1>
              </div>
              <ul className="que-list ">
                <li>
                  Our standard package includes a simple container to honor your
                  loved one. To create a truly personal tribute, consider
                  exploring our alternative range of beautiful urns, pendants,
                  votives, and other meaningful keepsakes you and your loved
                  ones can treasure.
                </li>
              </ul>
            </div>

            <div className="first-payment selectServices-custom">
              <div className="payment-op1 ">
                <h3>{`${name}`}</h3>
                <h6
                //  className="text-white"
                >
                  ${`${parseFloat(price).toFixed(2)}`}
                </h6>
              </div>
              <ul className="que-list ">
                <li
                //  className="text-white"
                >
                  {/* <i className="fa fa-arrow-circle-o-right" aria-hidden="true">
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </i> */}
                  {`${description}`}
                </li>
              </ul>
            </div>

            {addonCategory.map((item, index) => {
              if (item.addOns.length > 0) {
                let selectedData = otherProps.selected.filter(
                  ({ catId }) => catId === item.id
                );
                return (
                  <CategorySelection
                    key={index}
                    data={item}
                    index={index}
                    selected={selectedData}
                    getShippingTotal={otherProps.getShippingTotal}
                    errorMessageForUrn={errorMessageForUrn}
                    setErrorMessageForUrn={setErrorMessageForUrn}
                    errorMessageForAdditionServices={
                      errorMessageForAdditionServices
                    }
                    setErrorMessageForAdditionServices={
                      setErrorMessageForAdditionServices
                    }
                    errorMessageForDelivery={errorMessageForDelivery}
                    setErrorMessageForDelivery={setErrorMessageForDelivery}
                    errorMessageForDeliveryAddress={
                      errorMessageForDeliveryAddress
                    }
                    setErrorMessageForDeliveryAddress={
                      setErrorMessageForDeliveryAddress
                    }
                    errorMessageForPeacemaker={errorMessageForPeacemaker}
                    setErrorMessageForPeacemaker={setErrorMessageForPeacemaker}
                    errorMessageForServiceArea={errorMessageForServiceArea}
                    setErrorMessageForServiceArea={
                      setErrorMessageForServiceArea
                    }
                    errorMessageForServiceAreaAddress={
                      errorMessageForServiceAreaAddress
                    }
                    setErrorMessageForServiceAreaAddress={
                      setErrorMessageForServiceAreaAddress
                    }
                    getExtraMailesCharges={getExtraMailesCharges}
                    extraMileServicePrice={extraMileServicePrice}
                    setExtraMileServicePrice={setExtraMileServicePrice}
                  />
                );
              }
            })}
            <div className="bill-container-custom">
              <div className="payment-op1 bill-container bill-content-custom">
                <p>Select no. of death certificates</p>
                <div className="que-option  que-option-for-mobile">
                  <div className="number-input">
                    <button
                      onClick={() => {
                        if (otherProps.certificate > 1) {
                          otherProps.setCertificate(otherProps.certificate - 1);
                        }
                      }}
                      className="minus"
                    >
                      -
                    </button>
                    <input
                      className="quantity"
                      min="0"
                      name="quantity"
                      value={otherProps.certificate}
                      type="number"
                      // onChange={(e) => otherProps.setCertificate(e.target.value)}
                      readOnly={true}
                    />
                    <button
                      onClick={() =>
                        otherProps.setCertificate(
                          parseInt(otherProps.certificate) + 1
                        )
                      }
                      className="plus"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="certificate-amount">
                  <h6
                  //  className="text-white"
                  >
                    $
                    {parseFloat(
                      otherProps.certificate * crtPrice - crtPrice
                    ).toFixed(2)}
                  </h6>
                </div>
              </div>
              <div className="payment-op1 bill-container bill-content-custom bill-content2">
                <p>Tax Calculation</p>
                <h6
                // className="text-white"
                >
                  ${`${parseFloat(otherProps.getTotalTax).toFixed(2)}`}
                </h6>
              </div>
              <div className="payment-op1 bill-container bill-content-custom bill-content3">
                <p>Total amount due</p>
                <h6
                  //  className="text-white"
                  className="total-Price"
                >
                  {/* <b>${`${parseFloat(otherProps.total).toFixed(2)}`}</b> */}
                  ${`${parseFloat(otherProps.total).toFixed(2)}`}
                </h6>
              </div>

              <div className="cat-error error-background-color" id="caterror">
                {errorMessageForUrn !== "" && <h3>{errorMessageForUrn}</h3>}
                {errorMessageForAdditionServices !== "" && (
                  <h3>{errorMessageForAdditionServices}</h3>
                )}
                {errorMessageForDelivery !== "" && (
                  <h3>{errorMessageForDelivery}</h3>
                )}
                {errorMessageForDeliveryAddress !== "" && (
                  <h3>{errorMessageForDeliveryAddress}</h3>
                )}
                {errorMessageForPeacemaker !== "" && (
                  <h3>{errorMessageForPeacemaker}</h3>
                )}
                {errorMessageForServiceArea !== "" && (
                  <h3>{errorMessageForServiceArea}</h3>
                )}
                {errorMessageForServiceAreaAddress !== "" && (
                  <h3>{errorMessageForServiceAreaAddress}</h3>
                )}
              </div>
              <div className="payment-op1">
                <button
                  type="text"
                  className="btn-apply btn-continue mt-4 mx-0 mb-4 bill-container-button-custom"
                  onClick={() => savePackages()}
                >
                  Continue
                </button>
              </div>
            </div>

            {/* <div className="amount-note">
              <p>There are no additional costs or hidden charges.</p>
            </div> */}
          </div>

          <div className="col-lg-4 col-md-12 pb-60">
            <div className="quote quote-custom ">
              <h6 className="text-white">Summary</h6>

              <div className="servicre-package">
                <h3>{`${name}`}</h3>
                <span>
                  {" "}
                  <b>${`${parseFloat(price).toFixed(2)}`}</b>
                </span>
              </div>
              <>
                {addonCategory.map((category, index) => {
                  // if (category.id != 28 && category.id != 2) {
                  if (category.id != 2) {
                    if (category.addOns.length > 0) {
                      let selectedServices = otherProps.selected.filter(
                        ({ catId }) => catId === category.id
                      );

                      if (selectedServices.length > 0) {
                        // Filter out services that should not be displayed
                        const filteredServices = selectedServices.filter(
                          (selectedService) => {
                            return !(
                              category.id === 31 &&
                              selectedService.name !== "Yes"
                            );
                          }
                        );

                        if (filteredServices.length > 0) {
                          return (
                            <div key={index} className="service-category">
                              {(category.id === 32 ||
                                (category.id === 31 &&
                                  selectedServices.some(
                                    (service) => service.name === "Yes"
                                  ))) && <p>{category.name}</p>}

                              {filteredServices.map((selectedService, idx) => (
                                <div key={idx} className="quote-service">
                                  <span className="service-name">
                                    {category.id === 31 &&
                                    selectedService.name === "Yes" ? (
                                      `Implant Disposition (${selectedService.quantity})`
                                    ) : category.id === 32 ? (
                                      //  && selectedService.name === "No"
                                      <>
                                        {
                                          selectedService.quantity > 0
                                          ? selectedService.quantity
                                          : // : selectedService.quantity <= 20
                                            // ? "0"
                                            "0"}{" "}
                                        miles,
                                        <br />
                                        {selectedService.address}
                                      </>
                                    ) : (
                                      selectedService.name
                                    )}
                                    {category.id !== 28 &&
                                      category.id !== 31 &&
                                      category.id !== 32 &&
                                      `(${selectedService?.quantity})`}
                                  </span>
                                  <span>
                                    {category.id !== 28 ? (
                                      <b>
                                        {" "}
                                        {category.id === 32 &&
                                        selectedService.id === 77
                                          ? "$" +
                                            parseFloat(
                                              selectedService.quantity <= 20
                                                ? "0"
                                                : extraMileServicePrice
                                            ).toFixed(2)
                                          : "$" +
                                            parseFloat(
                                              selectedService.price *
                                                selectedService.quantity
                                            ).toFixed(2)}
                                      </b>
                                    ) : (
                                      category.id === 28 ? (
                                        <b>{" "}
                                        {selectedService.placeholder_price > 0 ?
                                            "" : (selectedService.price > 0 ? "$" + parseFloat(selectedService.price).toFixed(2) : "")  }
                                            </b>
                                            ) : ""
                                    )}
                                  </span>
                                </div>
                              ))}
                            </div>
                          );
                        }
                      }
                      return null; // Return null if no services to display
                    }
                  }
                  return null;
                })}

                {/* <-----------shipping-------- */}
                {addonCategory.map((category, index) => {
                  if (category.id === 2) {
                    if (category.addOns.length > 0) {
                      let selectedServices = otherProps.selected.filter(
                        ({ catId }) => catId === category.id
                      );

                      if (selectedServices.length > 0) {
                        return (
                          <div key={index} className="service-category">
                            <p>{category.name}</p>

                            {selectedServices.map((selectedService, idx) => (
                              <div key={idx} className="quote-service">
                                <span className="service-name">
                                  {selectedService.name} <br />
                                  {selectedService.address}
                                </span>
                                <span>
                                  <b>
                                    $
                                    {parseFloat(
                                      otherProps.getShippingTotal
                                    ).toFixed(2)}
                                  </b>
                                </span>
                              </div>
                            ))}
                          </div>
                        );
                      }
                    }
                  }
                  return null;
                })}

                {/* <------------------- */}

                {/* {otherProps.getShippingTotal !== undefined &&
                otherProps.getShippingTotal > 0 ? (
                  <div className="quote-service-part dashed-line">
                    <span>Delivery </span>
                    <span>Shipping </span>
                    <span>
                      <b>
                        ${parseFloat(otherProps.getShippingTotal).toFixed(2)}
                      </b>
                    </span>
                  </div>
                ) : (
                  ""
                )} */}

                <div className="service-payment-main">
                  <div className="quote-service-part">
                    <span>Death certificates ({otherProps.certificate})</span>
                    <span>
                      <b>
                        $
                        {parseFloat(
                          otherProps.certificate * crtPrice - crtPrice
                        ).toFixed(2)}
                      </b>
                    </span>
                  </div>
                  <div className="quote-service">
                    <span style={{ color: "black" }}>Tax Calculation</span>
                    <span>
                      <b>
                        ${`${parseFloat(otherProps.getTotalTax).toFixed(2)}`}
                      </b>
                    </span>
                  </div>
                </div>

                <div className="quote-service quote-service-custom ">
                  <p>Your Quote</p>
                  <h4 className="total-Price">
                    <b>${`${parseFloat(otherProps.total).toFixed(2)}`}</b>
                  </h4>
                </div>
              </>

              {/* <p className="text-white">
                ${`${parseFloat(otherProps.total).toFixed(2)}`}
              </p> */}
              <button
                className="btn-apply quote-button-custom"
                onClick={() => savePackages()}
              >
                CONTINUE &nbsp;
                <i className="fa fa-arrow-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </Grid>
  );
}
