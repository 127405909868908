import React, { useEffect, useState } from "react";
import CenterLoader from "src/Components/Loader/CenterLoader";
import { Http } from "src/Services/Http";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, useLocation } from "react-router";
import { login } from "src/redux/actions/sessionActions";
import { connect } from "react-redux";
import { Grid } from "@mui/material";

export const VerifyAccountView = ({ login }) => {
  const varifyEmailToken = new URLSearchParams(useLocation().search).get("id");
  const [isLoading, setisLoading] = useState(true);
  const [isVarified, setIsVarified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [redirect, setredirect] = useState(false);
  const [redirectlogout, setredirectlogout] = useState(false);
  const [minheight] = useState("250px");

  useEffect(() => {
    const varifyAccount = async () => {
      await Http("GET", "guest/email/verify/" + varifyEmailToken).then(
        async (response) => {
          if (response.status === 200) {
            setIsVarified(true);
            setisLoading(false);
            if (
              localStorage.getItem("token") === null ||
              localStorage.getItem("token") === undefined
            ) {
              login(
                response.data.access_token,
                response.data.token_type,
                response.data
              );
              setTimeout(() => {
                setredirect(true);
                localStorage.setItem("token", response.data.access_token);
              }, 5000);
            } else {
              setTimeout(() => {
                setredirect(true);
              }, 5000);
            }
          } else {
            setErrorMessage(response.message);
            setisLoading(false);
            if (
              response.status === 403 &&
              localStorage.getItem("token") !== null
            ) {
              setTimeout(() => setredirectlogout(true), 5000);
            }
          }
        }
      );
    };

    varifyAccount();
  }, [varifyEmailToken]);

  if (isLoading) {
    return localStorage.getItem("token") !== null ? (
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundImage: `url("/img/Rectangle24.png")`,
          backgroundSize: "cover",
          height: "100vh",
          position: "relative",
          zIndex: "0",
        }}
        className="homesection_main"
      >
        <CenterLoader minheight={minheight} />
      </Grid>
    ) : (
      <CenterLoader minheight={minheight} />
    );
  }
  if (redirect) {
    return <Navigate to="/app/home" />;
  }
  if (redirectlogout) {
    return <Navigate to="/app/logout" />;
  }

  return localStorage.getItem("token") !== null ? (
    <Grid
      container
      direction="row"
      style={{
        backgroundImage: `url("/img/Rectangle24.png")`,
        backgroundSize: "cover",
        height: "100vh",
        position: "relative",
        zIndex: "0",
      }}
      className="homesection_main"
    >
      <Container>
        <Row>
          <Col>
            <div className="thanks-section">
              <div className="thank-box">
                {isVarified ? (
                  <>
                    <p className="">
                      The user account has been successfully registered and
                      verified.
                    </p>
                  </>
                ) : (
                  <p className="">{errorMessage}</p>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Grid>
  ) : (
    <Container>
      <Row>
        <Col>
          <div className="thanks-section">
            <div className="thank-box">
              {isVarified ? (
                <>
                  <p className="">
                    The user account has been successfully registered and
                    verified.
                  </p>
                </>
              ) : (
                <p className="">{errorMessage}</p>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccountView);
