import * as appConst from "src/constants";
export const Http = async (method, callUrl, body, isMedia = false) => {
  //const token = store.getState().session.token;
  let token = await JSON.parse(localStorage.getItem("state"));
  let response = null;
  let api_url = appConst.API_URL + callUrl;
  //console.log(api_url);
  let Header = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (token) {
    Header = {
      ...Header,
      Authorization: "Bearer " + token.session.token,
    };
  }
  //console.log(body);
  await fetch(api_url, {
    method: method,
    headers: Header,
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      response = responseJson;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      //setLoading(false);
      //store.dispatch(setSnackbar(true));
    });
  return response;
};
