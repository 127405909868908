import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Page from "src/Components/Page";
import ProfileDetails from "./Components/ProfileDetails";
import { Http } from "src/Services/Http";
import { createUseStyles } from "react-jss";
import { Image, Button } from "react-bootstrap";
import { setImage, setSnackbar } from "src/redux/actions";
import CenterLoader from "src/Components/Loader/CenterLoader";

import { configureStore } from "src/redux/store";
import { Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const store = configureStore();

const user = {
  avatar:
    store.getState().session.image === ""
      // ? "/img/logo/Avatar.png"
      ? "/img/logo/F_C_Logo.png"
      : store.getState().session.image,
};

const bgimage = localStorage.getItem("postBgImage");
const useStyles = createUseStyles({
  bgImg: {
    background: bgimage
      ? "url(" + bgimage + ") no-repeat"
      : "url(/img/m.jpg) no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
  },
  avatar: {
    height: 100,
    width: 100,
  },
  input: {
    display: "none",
  },
});

export const Profile = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const [Values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    // image: null,
  });
  const [isLoading, setisLoading] = useState(true);
  const [isImage, setisImage] = useState(0);
  const [Error, setError] = useState("");
  const propsimg = props.image;
  // console.log(propsimg);
  const propsimg_name = propsimg !== undefined ? propsimg.split("/") : "";
  // const { values } = rest;
  const handleUploadClick = async (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setError("Please select valid image.");
      return false;
    } else {
      setError("");
    }
    createImage(files[0]);
  };

  const createImage = (file) => {
    let reader = new FileReader();
    setisImage(1);
    reader.onload = (e) => {
      setValues({
        ...Values,
        image: e.target.result,
        file: file,
      });
    };
    reader.readAsDataURL(file);
  };
  const handleRemove = async () => {
    setisImage(0);
    setValues({
      ...Values,
      // image: null,
      // file: null,
    });
    props.setImage("");
  };
  const updateProfile = async (data, actions) => {
    await Http("POST", "user/auth/update_profile", {
      ...data,
      isImage: isImage,
      image: Values.image !== null ? Values.image : "",
      _method: "PUT",
    }).then(async (response) => {
      if (response.status === 200) {
        await setValues({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          mobile: data.mobile,
        });
        props.setImage(response.data.image);
        props.setSnackbar(true, response.status, response.message);
        navigate("/app/check-verified/login");
      } else {
        actions.setErrors(response.data);
        actions.setSubmitting(false);
      }
    });
  };
  const handleChange = (event) => {
    setValues({
      ...Values,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await Http("GET", "user/auth/profile");

        setValues({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          mobile: data.mobile,
        });
        props.setImage(data.image);
        if (props.image !== "") {
          setisImage(1);
        }
        setisLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchUsers();
  }, []);

  // if (isLoading) {
  //   return <CenterLoader />;
  // }

  return (
    <Page title="My Profile">
      <Grid container className="main-body">
        <Grid item md={6} xs={12} className="scroll-md theme-bg-color">
          <Grid
            container
            alignitem="center"
            justifyContent="center"
            direction="row"
          >
            {isLoading ? (
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  minHeight: 400,
                }}
              >
                <CenterLoader />
              </Grid>
            ) : (
              <>
                <Grid item md={12} xs={12}>
                  <h6 className="profile-title">My Profile</h6>
                  <div className="profile-img">
                    <Image
                      className="d-block"
                      // src="/img/logo/Avatar.png"
                      src="/img/logo/F_C_Logo.png"
                      // {
                      //   Values.file !== null && Values.file !== undefined
                      //     ? URL.createObjectURL(Values.file)
                      //     : props.image === ""
                      //     ? "/img/logo/Avatar.png"
                      //     : props.image
                      // }
                      alt="gdf"
                    />
                    {/* <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  multiple={false}
                  type="file"
                  onChange={handleUploadClick}
                />
                <div className="fileuploadmain">
                  <Button
                    color="primary"
                    variant="outline-light"
                    className="fupload"
                  >
                    <label
                      htmlFor="contained-button-file"
                      style={{
                        marginBottom: "0px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faUpload}
                        color="#273969"
                        size="xs"
                      />
                    </label>
                  </Button>
                </div> */}
                  </div>
                  <div className="profile-dellink">
                    {propsimg_name !== undefined &&
                      propsimg_name[10] !== "default_avatar.jpg" &&
                      propsimg_name[10] !== undefined &&
                      props.image !== "" &&
                      props.image !== undefined && (
                        <a
                          href="javascript:void(0);"
                          onClick={() => handleRemove()}
                          className="deleteIcon"
                        >
                          Remove
                        </a>
                      )}
                    <h6
                      color="error"
                      style={{
                        display: "flex",
                        width: "100%",
                        color: "#FFFF00",
                        marginBottom: "5%",
                      }}
                      variant="danger"
                    >
                      {Error}
                    </h6>
                  </div>
                </Grid>
                <Grid item md={12}>
                  <ProfileDetails
                    formValues={Values}
                    handleChange={handleChange}
                    setValues={setValues}
                    setSnackbar={props.setSnackbar}
                    updateProfile={updateProfile}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item md={6} xs={12} className="scroll-md">
          <div className={classes.bgImg}></div>
        </Grid>
      </Grid>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  current_user_id: state.session.user.id,
  image: state.session.image,
});

const mapDispatchToProps = {
  setImage,
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
