import React from "react";
import { Link } from "react-router-dom";
import logo from "src/Assets/img/logo/HF-WG-logo.svg";
import left_arrow_icon from "src/Assets/img/left_arrow_icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  return (
    <div>
      <header className="py-3">
        <div className="row flex justify-content-between align-items-center">
          <div className="col-md-4 col-sm-12 col-xs-12 pt-1 order-3 order-md-1 ">
            {window.location.pathname === "/login" ? (
              <Link to="/" className="back">
                <img src={left_arrow_icon} alt="Logo" />
                Return Home
              </Link>
            ) : (
              <Link to="/login" className="back-button">
                <img src={left_arrow_icon} alt="Logo" />
                Login
              </Link>
            )}
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12 text-center order-1 order-md-2">
            <a className="header-logo text-dark" href="/">
              {/* <img src={logo} alt="Logo" /> */}
              <img src="/img/logo/Fernwood_logo.png" alt="Logo" />
            </a>
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12 d-flex justify-content-end align-items-center order-1 order-md-3 ">
            <p className="call-details">
              For immediate assistance
              <br /> please call (415) 707-3393
            </p>
          </div>
        </div>
      </header>
    </div>
  );
}
