export const API_URL = "https://cremation.fernwood.com/api/";
export const PUBLIC_URL = "https://cremation.fernwood.com/public/";

// export const API_URL =
//   "http://localhost/fernwoodcremation_live/fernwoodcremationwebservice/api/";
// export const PUBLIC_URL =
//   "http://localhost/fernwoodcremation_live/fernwoodcremationwebservice/public/";
// export const API_URL =
//   "http://1.22.161.26:9875/cremation_system_live/webservice/api/";
// export const PUBLIC_URL =
//   "http://1.22.161.26:9875/cremation_system_live/webservice/public/";
export const PWD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()_+={}:;"'|\\<>?,./\[\]-])/;
