import { Grid } from "@material-ui/core";
import React from "react";
import Lottie from 'react-lottie';
// import animation from '../../Assets/img/FernWood_Loading_color-1.json'
import animation from '../../Assets/img/FernWood_Loading_color2_new.json'

export default function CenterLoader({ minheight }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: minheight ? minheight : "250px",
      }}
    >
      {/* <div className="spinner-grow" role="status"></div> */}
      {/* <img src="/img/loader.gif" style={{ height: 100, alignSelf: "center" }} /> */}
      <Lottie options={defaultOptions} style={{ height: 100, alignSelf: "center",width:"auto" }} />
    </Grid>
  );
}
