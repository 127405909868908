import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import Header from "./Components/Header";
import CustomToastMessageView from "src/Components/CustomToastMessageView";
import * as Helper from "src/Services/Helper";
import { Http } from "src/Services/Http";
import Footer from "./Components/Footer";

export const DashboardLayout = (props) => {
  const { image, user, questconditionchk } = props;
  const bgImage = Helper.getItem("bgImage");
  const [Image, setImage] = useState(bgImage);
  const [Color, setColor] = useState("#273969");
  useEffect(() => {
    const getTheme = async () => {
      await Http("get", "get_theme/2").then(async ({ data, status }) => {
        setImage(data.image);
        setColor(data.font_color);
        Helper.setItem("postBgImage", data.image);
      });
    };
    getTheme();
  }, []);
  const css =
    `
  .postque .list-que {
    background-image: url(` +
    Image +
    `);
  }
  .postque .list-que ul li {
    color: ` +
    Color +
    `;
  }
  .postque .list-que ul li.active {
    color: ` +
    Color +
    `;
  }
  .attent {
    color: ` +
    Color +
    ` !important;
  }`;
  return (
    <div>
      <style>{css}</style>
      <Header avatar={image} authUser={user} isGwinPreQue={questconditionchk} />
      <Outlet />
      <CustomToastMessageView visible={props.visible} />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  image: state.session.image,
  user: state.session.user,
  questconditionchk: state.session.questconditionchk,
  visible: state.global.snackBar,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
