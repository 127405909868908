import React, { useState } from "react";
import { connect } from "react-redux";

import QuestionHeader from "./Question/QuestionHeader";
import QuestionNote from "./Question/QuestionNotes";
import TextBox from "./Question/TextBox";
import RadioBox from "./Question/RadioBox";
import AutoCompleteBox from "./Question/AutoCompleteBox";
import SelectBox from "./Question/SelectBox";
import TextAreaBox from "./Question/TextAreaBox";
import FileDropBox from "./Question/FileDropBox";
import DatePickerBox from "./Question/DatePickerBox";
import PhonInputBox from "./Question/PhonInputBox";
import GoogleAutoCompleteBox from "./Question/GoogleAutoCompleteBox";
import { Button } from "react-bootstrap";
import Question from "./Question/Question";
import NameBox from "./Question/NameBox";

export const QuestionBox = ({
  data,
  options,
  answer,
  setAnswer,
  validateForm,
  getQuestion,
  error,
  setError,
  setfileExt,
  Address,
  setAddress,
  UnitData,
  NameError,
  setNameError,
  ...props
}) => {
  const {
    question,
    control,
    placeholder,
    question_description,
    file_path,
    section_name,
    is_last,
    control_handler,
    ext,
  } = data;

  let answer_index = "";
  if (
    control === 4 &&
    (control_handler === 1 || control_handler === 5 || control_handler === 6)
  ) {
    answer_index = options.findIndex((obj) => obj.answer === answer);
  } else {
    answer_index = options.findIndex((obj) => obj.id === answer);
  }
  const handleCheckboxClick = (ansval) => {
    let ans = answer.toString();
    let answers = [];
    if (ans !== "") {
      if (ans.includes(",")) {
        answers = ans.split(",");
      } else {
        answers.push(ans);
      }
      // answers = ans.split(",");
      if (answers.includes(ansval)) {
        let index = answers.indexOf(ansval);
        if (index > -1) {
          answers.splice(index, 1);
        }
        setAnswer(answers.toString());
      } else {
        answers.push(ansval);
        setAnswer(answers.toString());
      }
    } else {
      answers.push(ansval);
      setAnswer(answers.toString());
    }
  };

  return (
    <div>
      <QuestionHeader section_name={section_name} />
      <div className="row">
        <Question question={question} />
        <div className="col-md-12 pb-3 ">
          {control === 1 && (
            <TextBox
              setAnswer={setAnswer}
              placeholder={placeholder}
              answer={answer}
              error={error}
              type="text"
            />
          )}
          {control === 2 && (
            <RadioBox
              options={options}
              answer={answer}
              error={error}
              getQuestion={getQuestion}
            />
          )}
          {control === 3 && (
            <div className="col-md-12">
              {options.map((option, index) => {
                let classString = "text-left btn col-12 mb-1 btn-custom";
                let isSelected = false;
                let ans = answer.toString();
                if (ans.indexOf(",") > -1) {
                  if (ans.includes(option.id)) {
                    {
                      isSelected = true;
                    }
                    classString = classString + " active";
                  }
                } else {
                  if (answer.toString() === option.id.toString()) {
                    isSelected = true;
                    classString = classString + " active";
                  }
                }

                return (
                  <div className="box" key={index}>
                    <div className="plans">
                      <label
                        className="plan basic-plan"
                        htmlFor={"answerchk_" + option.id}
                      >
                        <input
                          type="checkbox"
                          name="answer"
                          id={"answerchk_" + option.id}
                          checked={isSelected}
                          value={option.id}
                          onChange={(e) => {
                            handleCheckboxClick(e.target.value);
                          }}
                          autoFocus={true}
                        />
                        <div className={classString}>{option.answer}</div>
                      </label>
                    </div>
                  </div>
                );
              })}
              {error !== "" && (
                <div className="invalid-feedback d-block">{error}</div>
              )}
            </div>
          )}
          {control === 4 && [1, 5, 6].includes(control_handler) ? (
            <AutoCompleteBox
              options={options}
              answer={answer}
              placeholder={placeholder}
              setAnswer={setAnswer}
              error={error}
              setError={setError}
            />
          ) : control === 4 ? (
            <SelectBox
              answer={answer}
              setAnswer={setAnswer}
              options={options}
              error={error}
            />
          ) : (
            ""
          )}
          {control === 5 && (
            <TextAreaBox
              placeholder={placeholder}
              answer={answer}
              setAnswer={setAnswer}
              error={error}
            />
          )}
          {control === 6 && (
            <FileDropBox
              control_handler={control_handler}
              ext={ext}
              file_path={file_path}
              setfileExt={setfileExt}
              setAnswer={setAnswer}
              answer={answer}
              error={error}
              setError={setError}
            />
          )}
          {control === 7 && (
            <div className="ans-fill">
              <div className="form-check"></div>
            </div>
          )}
          {control === 8 && (
            <DatePickerBox
              placeholder={placeholder}
              answer={answer}
              setAnswer={setAnswer}
              error={error}
              setError={setError}
              maxDate={new Date()}
            />
          )}
          {control === 9 && (
            <TextBox
              setAnswer={setAnswer}
              placeholder={placeholder}
              answer={answer}
              error={error}
              type="email"
            />
          )}
          {control === 10 && (
            <PhonInputBox
              answer={answer}
              placeholder={placeholder}
              setAnswer={setAnswer}
              setError={setError}
              error={error}
            />
          )}
          {control === 11 && (
            <TextBox
              setAnswer={setAnswer}
              placeholder={placeholder}
              answer={answer}
              error={error}
              type="number"
            />
          )}
          {control === 12 && (
            <GoogleAutoCompleteBox
              placeholder={placeholder}
              answer={answer}
              setAnswer={setAnswer}
              error={error}
              setError={setError}
              is_last={is_last}
              getQuestion={getQuestion}
              address={data.address}
              setAddress={setAddress}
              UnitData={UnitData}
            />
          )}
          {control === 13 && (
            <DatePickerBox
              placeholder={placeholder}
              answer={answer}
              setAnswer={setAnswer}
              error={error}
              setError={setError}
              maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
            />
          )}
          {control === 14 && (
            <NameBox
              defaultValue={data.name_fields}
              getQuestion={getQuestion}
              setAddress={setAddress}
              setAnswer={setAnswer}
              NameError={NameError}
              setNameError={setNameError}
            />
          )}
        </div>

        <QuestionNote question_description={question_description} />
        {[13, 11, 10, 9, 8, 6, 5, 4, 1].includes(control) && (
          <div className="col-md-12 ">
            <Button
              color="primary"
              variant="outline-light"
              className="btn-block"
              onClick={async () => {
                let isValid = await validateForm();
                if (isValid) {
                  getQuestion(true);
                }
              }}
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionBox);
