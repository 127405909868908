import React, { useState } from "react";
import { useField } from "formik";
import {
  Grid,
  TextField
} from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import TextInput from "./TextInput";


const AddressBox = ({ address, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const { setValue } = helpers;
  
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");


  const removeAddressData = (e) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      setCity("");
      setState("");
      setCountry("");
      setZip("");
    }
    setValue(e.target.value);
  };

  const setAddressData = ({
    address_components,
    geometry,
    formatted_address,
    name,
    business_status,
  }) => {
    let addressComponent = {};
    if (address_components.length > 0) {
      addressComponent.address = formatted_address;
      //setAddressLine1(addressComponent.address_line_1);
      props.setFieldValue("address", addressComponent.address);
      addressComponent.locationAddress = formatted_address;
      addressComponent.lat = geometry.location.lat();
      addressComponent.long = geometry.location.lng();
      addressComponent.house_name =
        business_status !== undefined && business_status === "OPERATIONAL"
          ? name
          : "";
      //props.setFieldValue("house_name", addressComponent.house_name);
      address_components.map((address_component) => {
        if (address_component.types.includes("administrative_area_level_1")) {
          addressComponent.state = address_component.short_name;
          //setState(addressComponent.state);
          props.setFieldValue("state", addressComponent.state);
        } else if (address_component.types.includes("locality")) {
          addressComponent.city = address_component.long_name;
          //setCity(addressComponent.city);
          props.setFieldValue("city", addressComponent.city);
        } else if (address_component.types.includes("postal_code")) {
          addressComponent.zip = address_component.long_name;
          //setZip(addressComponent.zip);
          props.setFieldValue("zip", addressComponent.zip);
        } else if (address_component.types.includes("country")) {
          addressComponent.country = address_component.long_name;
          //setCountry(addressComponent.country);
          props.setFieldValue("country", addressComponent.country);
        }
      });
    }

    return addressComponent;
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAW9PMZKxsJ3iAZ249oFM6k8M1xYKhMXhw",
    options: {
      types: ["geocode", "establishment"],
      fields: ["ALL"],
    },
    onPlaceSelected: (place) => {
        console.log(place,"Place selected");
      props.setAddress(setAddressData(place));
      setValue(place.formatted_address);
    },
  });

  return (
    <Grid container direction="row" justifyContent="center" spacing={3}>
      <Grid item md={12} xs={12}>
        <TextField
          label="Address"
          fullWidth
          variant="outlined"
          inputRef={ref}
          placeholder={props.placeholder}
          multiline
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          {...field}
          {...props}
          autoComplete="off"
          onKeyDown={removeAddressData}
        />
      </Grid>
      
    </Grid>
  );
};

export default AddressBox;
