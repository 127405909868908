import { Formik } from "formik";
import React,{useState} from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import TextInput from "src/Components/Formik/TextInput";
import * as Messages from "src/constants/Messages";
import CustomPhoneInput from "src/Components/Formik/CustomPhoneInput";
import "react-phone-input-2/lib/bootstrap.css";
import { Grid } from "@material-ui/core";
import TextMaskCustom from "src/Components/TextMaskCustom";

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, Messages.ONLY_ALPABATES)
    .required(Messages.FIELD_REQUIRED),
  last_name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, Messages.ONLY_ALPABATES)
    .required(Messages.FIELD_REQUIRED),

  email: Yup.string()
    .lowercase()
    .email(Messages.VALID_EMAIL)
    .max(100, Messages.MAX_Email)
    .required(Messages.FIELD_REQUIRED),
  mobile: Yup.string().required(Messages.FIELD_REQUIRED),
});

export default function ProfileDetails({ formValues, updateProfile, ...rest }) {
  /*console.log(formValues);*/
  // const  formValues = props;
  // console.log(rest);
  const [checkPhoneNumber,setCheckPhoneNumber] = useState(0);

  const initialValues = {
    first_name: formValues.first_name,
    last_name: formValues.last_name,
    email: formValues.email,
    mobile: formValues.mobile,
  };

  return (
    <div className="ProfileForm">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          if(checkPhoneNumber === 0){
            await updateProfile(values, actions);
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextInput
                    name="first_name"
                    type="text"
                    placeholder="Full Name"
                    fullWidth
                    InputProps={{ readOnly: true}} 
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextInput
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                    fullWidth
                    InputProps={{ readOnly: true}} 
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextInput
                    name="email"
                    type="email"
                    placeholder="Email"
                    fullWidth
                    InputProps={{ readOnly: true}} 
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                    <TextInput
                        name="mobile"
                        label="Phone Number"
                        value={values.mobile}
                        onChange={(event) => {
                            setFieldValue("mobile", event.target.value);
                        }}
                        InputProps={{
                            inputComponent: TextMaskCustom,
                            readOnly: true,
                        }}
                    />
                    {/* <CustomPhoneInput
                        name={"mobile"}
                        handleChange={handleChange}
                        setValue={setFieldValue}
                        setCheckPhoneNumber={setCheckPhoneNumber}
                    /> */}
                </Grid>
                {/* <Container>
                  <div className="col-md-12 text-center">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-outline-light"
                    >
                      Update Profile
                    </Button>
                  </div>
                </Container> */}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
