import { Grid } from "@material-ui/core";
import React from "react";

import Lottie from "react-lottie";
import animation from "../../../Assets/img/FernWood_Loading_color2_new.json";

export default function PaymentLoader({ minheight }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        margin: "auto",
      }}
    >
      {/* <div
        className="spinner-grow"
        role="status"
        style={{
          position: "absolute",
          left: "50%",
          top: "45%",
          flexDirection: "row",
        }}
      ></div> */}
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: minheight ? minheight : "250px",
          top: "45%",
        }}
      >
        <Lottie
          options={defaultOptions}
          style={{ height: 100, alignSelf: "center" }}
        />
        {/* <img
          src="/img/loader.gif"
          style={{ height: 100, alignSelf: "center" }}
        /> */}
      </Grid>
      <div
        style={{
          // position: "absolute",
          // left: "35%",
          // top: "35%",
          color: "#fff",
        }}
      >
        Your payment is currently being processed. Please wait...
      </div>
    </div>
  );
}
