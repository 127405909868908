import React from "react";
import { Button, Form } from "react-bootstrap";

export default function TextAreaBox({
  placeholder,
  answer,
  setAnswer,
  error,
  validateForm,
  handleAnswerClick,
}) {
  return (
    <div className="col-md-12 ans-fill">
      <Form.Group>
        <Form.Control
          name="answer"
          placeholder={placeholder}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          isInvalid={error !== "" ? true : false}
          as="textarea"
          rows={3}
        />
        {error !== "" ? (
          <Form.Control.Feedback type="invalid" className=" d-block">
            {error}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
      {/* <Button
        color="primary"
        variant="outline-light"
        onClick={() => {
          let isvalid = validateForm();
          if (isvalid) {
            handleAnswerClick(answer, true);
          }
        }}
      >
        Continue
      </Button> */}
    </div>
  );
}
