import React, { useState } from "react";
import {
  Container,
  Button,
  Card,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import { connect } from "react-redux";

export const ReviewBox = ({ data, sectiondata, ...props }) => {
  let filepath = "";
  let fileName = "";
  let spanastric = "";
  const [submitReview, setSubmitReview] = useState(1);
  return (
    <div>
      <Container fluid="md">
        {data.map((reviewdata, rdataindex) => {
          return (
            <Card className="mb-2" style={{ width: "100%" }} key={rdataindex}>
              <Card.Header className="reviewsection_title ">
                {reviewdata.section_title}
              </Card.Header>
              <ListGroup className="list-group-flush">
                {reviewdata.user_answers.map((user_answersdata, uansindex) => {
                  if (user_answersdata.is_skip === 1) {
                    spanastric = '';
                  }
                  else {
                    spanastric = <span style={{ color: 'black' }}> * </span>;
                  }
                  if (user_answersdata.control_id === 6) {
                    filepath = user_answersdata.answer.split("/");
                    filepath = filepath[filepath.length - 1];
                    fileName = filepath;
                  }

                  return (
                    <div key={uansindex}>
                      <ListGroupItem className="reviewsection_quetitle">
                        {user_answersdata.question}{spanastric}
                      </ListGroupItem>
                      <ListGroupItem>
                        {user_answersdata.control_id === 6 ? (
                          fileName !== "Unknown" ? (
                            <a
                              href={user_answersdata.answer}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              {fileName}
                            </a>
                          ) : (
                            fileName
                          )
                        ) : user_answersdata.control_id === 10 &&
                          user_answersdata.answer !== "Unknown" ? (
                          "+" +
                          user_answersdata.answer.replace(
                            /^(\d{3})(\d{3})(\d{4}).*/,
                            "($1) $2-$3"
                          )
                        ) : (
                          user_answersdata.answer
                        )}
                      </ListGroupItem>
                    </div>
                  );
                })}
              </ListGroup>
            </Card>
          );
        })}

        {sectiondata[sectiondata.length - 1].is_complete === false ?
          <Card>
            <Card.Body>
              <Card.Text style={{ color: "#000" }}>
                Please review the above information carefully before contiuing.
              </Card.Text>
              <Card.Text style={{ color: "red" }}>{props.reviewerror}</Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-apply"
                variant="primary"
                onClick={() => {
                  props.getSectionWiseQuest(data[data.length - 1].section_id);
                }}
              >
                Back
              </Button>
              <Button
                className="btn-apply"
                variant="primary"
                onClick={() => {
                  props.getConfirm(submitReview);
                }}
              >
                Continue
              </Button>
            </Card.Footer>
          </Card>
          : ('')}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewBox);