import React from "react";
import { useField } from "formik";
import { Form } from "react-bootstrap";

const TextInput = ({ name, ...otherProps }) => {
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
  };

  return (
    <Form.Group controlId={"BasicForm" + name}>
      <Form.Check
        custom
        isInvalid={mata && mata.touched && mata.error}
        {...configTextfield}
      />
      {mata.touched && mata.error ? (
        <Form.Control.Feedback type="invalid">
          {mata.error}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default TextInput;
