import * as actionTypes from "../actions";

const initialState = {
  loggedIn: false,
  token: "",
  user: {},
  type: "",
  image: "",
  verified: false,
  paymentchk: false,
  questconditionchk: false,
  preeneeddonechk: false,
  payment_extra_mailes_chk: true,
  payment_extra_mailes_chk_tem: false,
  is_package_selected: false,
  check_post_qa: false,
  extra_mailes_chk: false,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...state,
        loggedIn: true,
        token: action.payload.token,
        type: action.payload.token_type,
        user: action.payload.data,
        image: action.payload.data.image,
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        token: null,
        user: {
          role: "GUEST",
        },
      };
    }

    case actionTypes.SESSION_SET_IMAGE: {
      return {
        ...state,
        image: action.payload.data,
      };
    }
    case actionTypes.SESSION_SET_VERIFY: {
      return {
        ...state,
        verified: action.payload.data,
      };
    }
    case actionTypes.SESSION_SET_PAYMENTCHK: {
      return {
        ...state,
        paymentchk: action.payload.data,
      };
    }
    case actionTypes.SESSION_SET_EXTRAMAILES_PAYMENTCHK: {
      return {
        ...state,
        payment_extra_mailes_chk: action.payload.data,
      };
    }
    case actionTypes.SESSION_SET_EXTRAMAILES_PAYMENTCHK_TEM: {
      return {
        ...state,
        payment_extra_mailes_chk_tem: action.payload.data,
      };
    }
    case actionTypes.SESSION_SET_QUESTCONDITIONCHK: {
      return {
        ...state,
        questconditionchk: action.payload.data,
      };
    }
    case actionTypes.SESSION_SET_PRENEEDDONE: {
      return {
        ...state,
        preeneeddonechk: action.payload.data,
      };
    }
    case actionTypes.SESSION_SET_PACKAGE: {
      return {
        ...state,
        is_package_selected: action.payload.data,
      };
    }
    case actionTypes.SESSION_SET_POST_QA_CHECK: {
      return {
        ...state,
        check_post_qa: action.payload.data,
      };
    }
    case actionTypes.SESSION_SET_EXTRA_MAILS_CHECK: {
      return {
        ...state,
        extra_mailes_chk: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default sessionReducer;
