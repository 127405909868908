export const mobileNoFormate = (arg) => {
  let cleaned = ("" + arg).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};
export const saveAddOns = (name, key, value) => {
  let data = localStorage.getItem(name.toString());
  let existing = data
    ? Array.isArray(JSON.parse(data))
      ? JSON.parse(data)
      : []
    : [];
  const foundIndex = existing.findIndex((el) => el.catId === value.catId);
  if (foundIndex !== -1) {
    existing[foundIndex] = value;
  } else {
    existing.push(value);
  }
  localStorage.setItem(name.toString(), JSON.stringify(existing));
  return existing;
};

export const setItem = async (key, value) => {
  return localStorage.setItem(key, value);
};

export const getItem = async (key) => {
  return localStorage.getItem(key);
};
