import React from "react";

export default function index({ progress }) {
  const { index, total } = progress;
  return (
    <div className="row">
      <div className="col-md-2 my-auto"></div>
      <div className="col-md-8 my-auto">
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${((index - 1) / total) * 100}%` }}
            aria-valuenow="5"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          {/* <ProgressBar now={prog} className="progress-bar" /> */}
        </div>
        <p className="answered">
          {index - 1} of {total} Answered
        </p>
      </div>
      <div className="col-md-2 my-auto"></div>
    </div>
  );
}
