import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Http } from "src/Services/Http";
import CenterLoader from "src/Components/Loader/CenterLoader";

import { Col, Container, Image, Row } from "react-bootstrap";
import { Grid } from "@mui/material";
import PaymentInvoice from "../PaymentView/Components/PaymentInvoice";
import { useLocation } from "react-router-dom";
import { Download } from "@mui/icons-material";
import { Button } from "@material-ui/core";

export const ThankYouView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const token = new URLSearchParams(location.search).get("token");
  const [isLoading, setisLoading] = useState(true);

  const [Values, setValues] = useState({
    name: "",
    email: "",
    invoice_no: "",
    referenceno: "",
    invoice_date: "",
    packagename: "",
    packageqty: "",
    packageprice: "",
    packagetotalprice: "",
    services: "",
    certificate_base_price: "",
    certificate_count: "",
    certificate_total_price: "",
    sub_total: "",
    tax_amount: "",
    total_amount: "",
    discount_amount: "",
    invoicepdf: "",
    promocode: "",
  });
  const startQuestionnary = () => {
    navigate("/app/postquest");
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await Http("POST", "invoicedetails", {is_adjustpayment: token ? 1 : 0, token});
        // console.log("data==>", response);
        setValues({
          name: response.name,
          email: response.email,
          invoice_no: response.invoice_no,
          referenceno: response.referenceno,
          invoice_date: response.invoice_date,
          packagename: response.packagename,
          packageqty: response.packageqty,
          packageprice: response.packageprice,
          packagetotalprice: response.packagetotalprice,
          services: response.services,
          sub_total: response.sub_total,
          tax_amount: response.tax_amount,
          total_amount: response.total_amount,
          discount_amount: response.discount_amount,
          certificate_base_price: response.certificate_base_price,
          certificate_count: response.certificate_count,
          certificate_total_price: response.certificate_total_price,
          invoicepdf: response.invoicepdf,
          promocode: response.promocode
        });
        setisLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchUsers();
  }, []);

  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        // minHeight: 500,
        // backgroundImage: `url("/img/Rectangle24.png")`,
        // backgroundSize: "cover",
        height: "100%",
        // backgroundPosition: "center",
        position: "relative",
        zIndex: "0",
      }}
      className="homesection_main"
    >
      <Container>
        <Row>
          <Col>
            <div className="thanks-section">
              <div className="thank-box-inovice">
                <p className="thanks-heading">
                  Thank You For Choosing Fernwood
                </p>
                <p className="thanks-heading-description">
                  Your payment has been successfully processed.
                </p>
                {!token && (
                <Button
                  className="thanks-page-button mx-0"
                  onClick={() => {
                    startQuestionnary();
                  }}
                >
                  Begin Questionnaire
                </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {isLoading ? (
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: 400,
            }}
          >
            <CenterLoader />
          </Grid>
        ) : (
          <Row>
            <Col>
              <div className="thanks-section-invoice">
                <div className="invoice-box">
                  <div className="logo-invoiceid">
                    <div className=" invoice-logo text-dark">
                      <Image src="/img/logo/f_invoice-logo.png" alt="Logo" />
                    </div>
                    <div className="invoice-id">
                      <h6> Invoice</h6>
                      <p>{Values.invoice_no}</p>
                    </div>
                  </div>

                  <div className="invoice-content-custom">
                    <div className="invoice-child-content-custom">
                      <h6 className="invoice-h6">Invoice Date</h6>
                      <p>{Values.invoice_date}</p>
                    </div>
                    <div className="invoice-child-content-custom">
                      <h6 className="invoice-h6"> Billed to</h6>
                      <p style={{ fontWeight: "600" }}>{Values.name}</p>
                      <p>{Values.email}</p>
                    </div>
                    {/* <div className="invoice-child-content-custom">
                      <h6 className="invoice-h6">Reference No</h6>
                      <p>{Values.referenceno}</p>
                    </div> */}
                  </div>

                  <PaymentInvoice formValues={Values} />

                  <div>
                    <Button
                      className="invoice-page-button mx-0 mt-1 "
                      style={{ color: "#d8b350" }}
                      component="a"
                      href={Values.invoicepdf}
                      target="_blank"
                    >
                      <Download style={{ marginRight: "20px" }} />
                      {/* <a href={Values.invoicepdf} rel="noopener noreferrer" target="_blank" > */}
                      Download Invoice
                      {/* </a> */}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Grid>
  );
};

export default ThankYouView;
