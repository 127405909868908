import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import DatePicker from "./DatePicker";
import * as Messages from "src/constants/Messages";
import { FormHelperText, Grid, Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
export const QuestionBox = ({
  answer,
  setAnswer,
  error,
  setError,
  Data,
  postQuestion,
  color,
  isIframe,
  ...props
}) => {
  const { question } = Data;
  const { control, placeholder, control_handler, options } = question;
  const isString = [13, 8, 4, 1];
  let answer_index = "";
  const [autocomplelteOpen, setautocomplelteOpen] = useState(false);
  const [inputOptions, setInputOptions] = React.useState([{ answer: answer }]);
  const [inputValue, setInputValue] = useState("");

  if (control === 4 && control_handler === 1) {
    answer_index = options.findIndex((obj) => obj.answer === answer);
  } else {
    answer_index = options.findIndex((obj) => obj.id === answer);
  }
  const handleCheckboxClick = (ansval) => {
    let ans = answer.toString();
    let answers = [];
    if (ans !== "") {
      if (ans.includes(",")) {
        answers = ans.split(",");
      } else {
        answers.push(ans);
      }
      // answers = ans.split(",");
      if (answers.includes(ansval)) {
        let index = answers.indexOf(ansval);
        if (index > -1) {
          answers.splice(index, 1);
        }
        setAnswer(answers.toString());
      } else {
        answers.push(ansval);
        setAnswer(answers.toString());
      }
    } else {
      answers.push(ansval);
      setAnswer(answers.toString());
    }
  };
  return (
    <Grid container direction="column">
      <Grid item>
        <div className="que-title">{question.question}</div>
        <hr />
      </Grid>
      <Grid id="style-1" item className='optscroll'>
        <Grid container direction="column" spacing={3} alignContent="center">
          {control === 1 && (
            <Grid item className="preque-txt d-grid mx-auto">
              {/* <Form.Group>*/}
              <TextField
                name="answer"
                type="text"
                label={placeholder}
                variant="standard"
                value={answer}
                onBlur={(e) => setAnswer(e.target.value)}
                onChange={(e) => setAnswer(e.target.value)}
                margin="normal"
                fullWidth={true}
                autoFocus={true}
                autoComplete={false}
              />
              {/* <Form.Control
                  name="answer"
                  type="text"
                  placeholder={placeholder}
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  isInvalid={error !== "" ? true : false}
                /> */}
              {/*</Form.Group>*/}
            </Grid>
          )}
          {control === 2 && (
            <Grid item className="d-grid mx-auto">
              {options.map((option, index) => {
                let classString = "btn dynamic-btn btn-block mb-3";
                if (option.id === answer) {
                  classString = classString + " active";
                }
                return (
                  <div className="row" key={index}>
                    <div
                      className={classString}
                      onClick={() => {
                        postQuestion(true, option.id);
                      }}
                    >
                      {option.answer}
                    </div>
                  </div>
                );
              })}
            </Grid>
          )}
          {control === 3 && (
            <Grid item className="d-grid mx-auto">
              {options.map((option, index) => {
                let classString = "btn dynamic-btn btn-block mb-3";
                let isSelected = false;
                let ans = answer.toString();
                if (ans.indexOf(",") > -1) {
                  if (ans.includes(option.id)) {
                    {
                      isSelected = true;
                    }
                    classString = classString + " active";
                  }
                } else {
                  if (answer.toString() === option.id.toString()) {
                    isSelected = true;
                    classString = classString + " active";
                  }
                }

                return (
                  <div className="row" key={index}>
                    <div className="plans">
                      <label
                        className="plan basic-plan"
                        htmlFor={"answerchk_" + option.id}
                      >
                        <input
                          type="checkbox"
                          name="answer"
                          id={"answerchk_" + option.id}
                          checked={isSelected}
                          value={option.id}
                          onChange={(e) => {
                            handleCheckboxClick(e.target.value);
                          }}
                        />
                        <div className={classString}>{option.answer}</div>
                      </label>
                    </div>
                  </div>
                );
              })}
            </Grid>
          )}
          {control === 4 && (
            <Grid item className="d-grid mx-auto">
              <Autocomplete
                id="disable-clearable"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={placeholder}
                    variant="standard"
                  />
                )}
                forcePopupIcon={false}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                  if (newInputValue !== answer && newInputValue.length > 1) {
                    setInputOptions(options);
                  } else {
                    setInputOptions([{ answer: answer }]);
                  }
                }}
                defaultValue={{ answer: answer }}
                onChange={(event, newValue) => {
                  setAnswer(newValue.answer);
                  setError("");
                }}
                filterOptions={createFilterOptions({
                  matchFrom: "start",
                  stringify: (option) => option.answer,
                })}
                style={{ width: 300 }}
                disableClearable
                options={inputOptions}
                getOptionLabel={(option) => option.answer || ""}
              />
            </Grid>
          )}
          {control === 8 && (
            <Grid item className="d-grid mx-auto">
              <DatePicker
                name="answer"
                lable="Select Date"
                placeholder="Select Date"
                setAnswer={setAnswer}
                answer={answer}
                error={error}
                setError={setError}
                maxDate={new Date()}
                isIframe={isIframe}
              />
            </Grid>
          )}

          {control === 13 && (
            <Grid item className="d-grid mx-auto">
              <DatePicker
                name="answer"
                lable="Select Date"
                placeholder="Select Date"
                setAnswer={setAnswer}
                answer={answer}
                error={error}
                setError={setError}
                maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                isIframe={isIframe}
              />
            </Grid>
          )}
          {error !== "" && ![8, 13].includes(control) && (
            <FormHelperText
              error={error}
              margin="dense"
              className={"MuiFormHelperText-contained"}
            >
              {error}
            </FormHelperText>
          )}
          {isString.includes(control) && (
            <Grid item>
              <div className="row justify-content-center">
                <Button
                  className="dynamic-btn"
                  variant="outlined"
                  type="submit"
                  onClick={() => {
                    if (answer === "" || answer === null) {
                      setError(Messages.FIELD_REQUIRED);
                      return false;
                    } else {
                      if (error === "") {
                        postQuestion(true, null, 1);
                      }
                    }
                  }}
                >
                  Next
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionBox);
