import React, { Component } from "react";
import { connect } from "react-redux";
import { Http } from "src/Services/Http";
import CenterLoader from "src/Components/Loader/CenterLoader";
import PackageBody from "./Components/PackageBody/index";
import * as Helper from "src/Services/Helper";
import {
  setCertificatePrice,
  setPackageData,
  emptyPackageData,
} from "src/redux/actions";
import { Grid } from "@material-ui/core";

export class PackageInfoView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      data: {},
      certificate: 1,
      minheight: "250px",
      extraMileServicePrice : 0
    };
  }

  setData = async (arg) => this.setState({ data: arg });
  setLoading = async (arg) => this.setState({ isLoading: arg });
  setCertificate = async (arg) => {
    this.setState({ certificate: arg });
    Helper.setItem("certificate", arg);
  };

  setExtraMileServicePrice = async (arg) => {
    this.setState({ extraMileServicePrice: arg });
  };

  componentDidMount = async () => {
    const { setData, setLoading, setCertificate, setExtraMileServicePrice } = this;
    const { setCertificatePrice, setPackageData, emptyPackageData } =
      this.props;

    // const certificateCount = await Helper.getItem("certificate");
    emptyPackageData();
    // if (certificateCount === null) {
    //   setCertificate(1);
    // } else {
    //   setCertificate(certificateCount);
    // }
    const { data, status, message } = await Http("GET", "packages-services");
    if (status === 200) {
      Helper.setItem(
        "packageData",
        JSON.stringify({
          id: data.id,
          name: data.name,
          price: data.price,
          tax: data.tax,
        })
      );
      setData(data);
      setCertificatePrice(parseInt(data.certificate_rate));
      setCertificate(data.certificate_count);
      // certificateCount = data.certificate_count;
      data.addonCategory.map((item, index) => {
        item.addOns.map((additem, addindex) => {
          if (additem.is_selected) {
            setPackageData({
              id: additem.id,
              catId: item.id,
              name: additem.service_name,
              price: additem.price,
              placeholder_price: additem.placeholder_price,
              tax: additem.tax,
              tax_per: additem.tax_per,
              type: item.selection_type,
              quantity: additem.quantity ? additem.quantity : 0,
              selected: additem.quantity ? true : false,
              included: additem.is_included ? true : false,
              address: additem.address ? additem.address : "",
              state: additem.state ? additem.state : "",
              shipping_inside: item.shipping_inside,
              shipping_outside: item.shipping_outside,
              city: additem.city !== undefined ? additem.city : "",
              country: additem.country !== undefined ? additem.country : "",
              lat: additem.lat !== undefined ? additem.lat : "",
              long: additem.long !== undefined ? additem.long : "",
              zip: additem.zip !== undefined ? additem.zip : "",
            });
            if(additem.id === 77){
              let additemQty = additem.quantity ? additem.quantity : 0;
              let additemPrice = additem.price ? additem.price : 0;
              let serviceItemprice = additemQty * additemPrice;
              setExtraMileServicePrice(serviceItemprice);
            }
          }
        });
      });
      setLoading(false);
    } else {
      alert("API ERO+ROR" + message);
    }
  };

  render() {
    const { isLoading, data, certificate,extraMileServicePrice } = this.state;
    const { packageData, setPackageData } = this.props;
    const { QuestionConditionChk } = this.props;
    const { PreneedDoneChk } = this.props;

    const getTotalTax = () => {
      let packageTax = JSON.parse(localStorage.getItem("packageData")).tax;
      let addOnstax = parseFloat(packageTax);
      let itemTax = 0;
      let itemPricewithShip = 0;
      let itemPricewithShipTax = 0;
      let shippingAddonData = packageData.categoryData.find(
        (item) => item.id === 10
      );
      packageData.categoryData.map((item, index) => {
        if (
          item.catId === 1 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state === "California"
        ) {
          // $addonItemTax = round(((($addonDetails->price * $totalAddonQuantity) + $totalShippingAmount) * $addonDetails->taxable_amount) / 100, 2);
          itemPricewithShip =
            ((item.price * item.quantity +
              item.shipping_inside * item.quantity) *
              item.tax_per) /
            100;
          //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
          itemTax += Number(itemPricewithShip.toFixed(2));
        } else if (
          item.catId === 3 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state === "California"
        ) {
          itemPricewithShip =
            ((item.price * item.quantity +
              item.shipping_inside * item.quantity) *
              item.tax_per) /
            100;
          //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
          itemTax += Number(itemPricewithShip.toFixed(2));
        } else if (
          item.catId === 30 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state === "California"
        ) {
          itemPricewithShip =
            ((item.price * item.quantity +
              item.shipping_inside * item.quantity) *
              item.tax_per) /
            100;
          //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
          itemTax += Number(itemPricewithShip.toFixed(2));
        } else if (
          item.catId === 1 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state !== undefined &&
          shippingAddonData.state !== "" &&
          shippingAddonData.state !== "California"
        ) {
          itemPricewithShip =
            ((item.price * item.quantity +
              item.shipping_outside * item.quantity) *
              item.tax_per) /
            100;
          //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
          itemTax += Number(itemPricewithShip.toFixed(2));
        } else if (
          item.catId === 3 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state !== undefined &&
          shippingAddonData.state !== "" &&
          shippingAddonData.state !== "California"
        ) {
          itemPricewithShip =
            ((item.price * item.quantity +
              item.shipping_outside * item.quantity) *
              item.tax_per) /
            100;
          //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
          itemTax += Number(itemPricewithShip.toFixed(2));
        } else if (
          item.catId === 30 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state !== undefined &&
          shippingAddonData.state !== "" &&
          shippingAddonData.state !== "California"
        ) {
          itemPricewithShip =
            ((item.price * item.quantity +
              item.shipping_outside * item.quantity) *
              item.tax_per) /
            100;
          //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
          itemTax += Number(itemPricewithShip.toFixed(2));
        } else {
          itemPricewithShip = (item.price * item.quantity * item.tax_per) / 100;
          //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
          itemTax += Number(itemPricewithShip.toFixed(2));
        }
      });
      let totaltax = addOnstax + itemTax;
      return totaltax;
    };

    const getShippingTotal = () => {
      let shipping = 0;
      let shippingAddonData = packageData.categoryData.find(
        (item) => item.id === 10
      );
      packageData.categoryData.map((item, index) => {
        if (
          item.catId === 1 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state === "California"
        ) {
          shipping = shipping + item.shipping_inside * item.quantity;
        } else if (
          item.catId === 3 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state === "California"
        ) {
          shipping = shipping + item.shipping_inside * item.quantity;
        } else if (
          item.catId === 30 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state === "California"
        ) {
          shipping = shipping + item.shipping_inside * item.quantity;
        } else if (
          item.catId === 1 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state !== undefined &&
          shippingAddonData.state !== "" &&
          shippingAddonData.state !== "California"
        ) {
          shipping = shipping + item.shipping_outside * item.quantity;
        } else if (
          item.catId === 3 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state !== undefined &&
          shippingAddonData.state !== "" &&
          shippingAddonData.state !== "California"
        ) {
          shipping = shipping + item.shipping_outside * item.quantity;
        } else if (
          item.catId === 30 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state !== undefined &&
          shippingAddonData.state !== "" &&
          shippingAddonData.state !== "California"
        ) {
          shipping = shipping + item.shipping_outside * item.quantity;
        }
      });
      return shipping;
    };

    const getExtraMailesCharges = async (extramiledata) => {
      //this.setLoading(true);
      let serviceAreaAddonData = packageData.categoryData.find(
        (item) => item.id === 77
      );
      try {
        await Http("POST", "extra_miles_service", extramiledata).then((response) => {
          if (response.status === 200) {
                serviceAreaAddonData = {
                  ...serviceAreaAddonData,
                  price:response.data.amount_per_extra_mile,
                  quantity: Math.round(response.data.total_mile_count), //parseInt(response.data.total_mile_count),
                }
                setPackageData(serviceAreaAddonData);
                // console.log("serviceAreaAddonData",serviceAreaAddonData)
                // console.log("setPackageData",packageData)
                //this.setLoading(false);
                return this.setExtraMileServicePrice(response.data.total_amount_to_be_paid);
                //return response;
              // return response.data.total_amount_to_be_paid;
            } else {
              //this.setLoading(false);
              return this.setExtraMileServicePrice(0);
            }
          });
      } catch (e) {
        console.log(e);
        this.setLoading(false);
        return;
      }
    };

    const getTotal = () => {
      let packagePrice = JSON.parse(localStorage.getItem("packageData")).price;
      //let packageTax = JSON.parse(localStorage.getItem("packageData")).tax;
      let addOnstotal = parseFloat(packagePrice);
      let addOnstax = getTotalTax();
      packageData.categoryData.map((item, index) => {
      if(item.id !== 77){
          if (item.included) {
            addOnstotal = addOnstotal + item.price * (item.quantity - 1);
          } else {
            addOnstotal = addOnstotal + item.price * item.quantity;
          }
      }
        //addOnstax = addOnstax + item.tax * item.quantity;
      });
      // console.log(extraMileServicePrice, ' extraMileServicePrice');
      let total =
        addOnstotal +
        addOnstax +
        (certificate * packageData.certificatePrice -
          packageData.certificatePrice) +
        getShippingTotal() + extraMileServicePrice ;
        // console.log(total, ' total');
      return total;
    };
    if (isLoading) {
      return (
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundImage: `url("/img/Rectangle24.png")`,
            backgroundSize: "cover",
            height: "100vh",
            position: "relative",
            zIndex: "0",
          }}
          className="scrollable-section"
        >
          <CenterLoader minheight={this.state.minheight} />
        </Grid>
      );
    }
    return (
      <PackageBody
        data={data}
        total={getTotal()}
        certificate={certificate}
        setCertificate={this.setCertificate}
        crtPrice={packageData.certificatePrice}
        selected={packageData.categoryData}
        QuestionConditionChk={QuestionConditionChk}
        PreneedDoneChk={PreneedDoneChk}
        getTotalTax={getTotalTax()}
        getShippingTotal={getShippingTotal()}
        getExtraMailesCharges = {getExtraMailesCharges}
        extraMileServicePrice = {extraMileServicePrice}
        setExtraMileServicePrice = {this.setExtraMileServicePrice}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  packageData: state.package,
  QuestionConditionChk: state.session.questconditionchk,
  PreneedDoneChk: state.session.preeneeddonechk,
});

const mapDispatchToProps = {
  setCertificatePrice,
  setPackageData,
  emptyPackageData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageInfoView);
