import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

export const IframeLayout = (props) => {
  return <Outlet />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IframeLayout);
