import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Http } from "src/Services/Http";
import Page from "src/Components/Page";
import { login } from "src/redux/actions/sessionActions";
import { useLocation } from "react-router";
import AdditonalPaymentView from "./paymentView/additonalPaymentView";

export const AdditionalServicePayment = () => {
  const resetFeedbackToken = new URLSearchParams(useLocation().search).get(
    "token"
  );
  const decodedToken_Email = resetFeedbackToken ? atob(resetFeedbackToken): null;
  console.log("resetFeedbackToken", resetFeedbackToken);
  console.log("decodedToken_Email", decodedToken_Email);

  // const handleRegister = async (values, setErrors) => {
  //   await Http("POST", "add_review", {
  //     ...values,
  //   }).then(async (response) => {
  //     const { status, data } = response;
  //     if (status === 200) {
  //       setSubmitReview(true);
  //     } else {
  //       setSubmitError(true);
  //       setSubmitErrorMsg("You have already submitted your feedback");
  //       if (status === 422) {
  //         setErrors(data);
  //       }
  //     }
  //   });
  // };
  // useEffect(() => {
  //   const checkActivity = async () => {
  //     let chekvalue = { token: resetFeedbackToken };
  //     await Http("POST", "check_review", chekvalue).then(async (response) => {
  //       const { status, data } = response;
  //       if (status === 400) {
  //         setShowReviewForm(false);
  //         setSubmitError(true);
  //         setSubmitErrorMsg(data["flag_msg"]);
  //       } else {
  //         console.log(status);
  //         if (status === 422) {
  //           setShowReviewForm(false);
  //           setSubmitError(true);

  //           setSubmitErrorMsg(data.token);
  //         } else {
  //           setShowReviewForm(true);
  //         }
  //       }
  //     });
  //   };
  //   checkActivity();
  // }, [resetFeedbackToken]);

  return (
    <Page title="Fernwood Cemetery Feedback">
      <div className="feedback-main">
        <div className="row">
          {/* <div className="col-md-2 my-auto"></div> */}
          <div className="col-md-12 my-auto mx-auto">
            <AdditonalPaymentView resetFeedbackToken={resetFeedbackToken} />
          </div>
          {/* <div className="col-md-2 my-auto"></div> */}
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalServicePayment);
