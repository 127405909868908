import * as appConst from "src/constants";
export const Login = async (method, callUrl, body) => {
  let response = null;
  let api_url = appConst.API_URL + callUrl;
  await fetch(api_url, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      response = responseJson;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      //setLoading(false);
      //store.dispatch(setSnackbar(true));
    });
  return response;
};
