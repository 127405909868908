import { combineReducers } from "redux";
import globalReducer from "./globalReducer";
import packageReducer from "./packageReducer";
import sessionReducer from "./sessionReducer";

const rootReducer = combineReducers({
  session: sessionReducer,
  global: globalReducer,
  package: packageReducer,
});

export default rootReducer;
