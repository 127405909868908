export const PASSWORD_MIN_8 = "Password must be at least 8 characters";
export const ONLY_ALPABATES = "Only alphabets are allowed";
export const ONLY_ALPHANUM = "Only letters and numbers are allowed";
export const VALID_EMAIL = "Must be a valid email address";
export const MAX_Email = "Email must be less than 100 characters";
export const FIELD_REQUIRED = "This field is required";
export const PASSWORD_MIN_6 = "Password must be at least 6 characters";
export const PASSWORD_MATCH =
  "Must contain at least 1 capital letter, 1 small letter, 1 special character and 1 number";
export const PASSWORD_SAME = "Password and confirm password must match";
export const INVALID_MOBILE = "Invalid Mobile Number.";
export const INVALID_NUMBERS = "Invalid Number";
export const INVALID_DATE = "Invalid Date";
export const INVALID_PASSWORD =
  "Must contain at least 1 capital letter, 1 small letter, 1 special character and 1 number";
export const NOT_MORE_THEN_FIVE =
  "Please enter a number between 1 and 5. If the number of Next Of Kin exceeds this amount, please enter 5.";
export const MIN_2WORDS = "Must contain at least 2 words.";
export const ONLY_ALPHAINFIRSTNAME = "The first name may only contain letters.";
export const ONLY_ALPHAINLASTNAME = "The last name may only contain letters.";
export const ALLOW_TWO_CHARACTER_STATE_CODE = "Allow two-character state code only.";
export const MAX_TWENTY_CHARACTER_ALLOWED = "Maximum twenty character are allowed.";
export const MAX_TWENTYFIVE_CHARACTER_ALLOWED = "Maximum twenty five character are allowed.";
export const MAX_FOURTY_CHARACTER_ALLOWED = "Maximum Fourty character are allowed.";
export const MAX_FIFTY_CHARACTER_ALLOWED = "Maximum fifty character are allowed.";
export const MAX_SIXTY_CHARACTER_ALLOWED = "Maximum sixty character are allowed.";
