import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import * as Messages from "src/constants/Messages";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

export default function MaterialUIPickers(props) {
  const { setAnswer, answer, setError, error } = props;
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(
    answer === "" ? null : answer
  );
  const handleDateChange = (date) => {
    //setError("");
    setSelectedDate(date);
    setAnswer(date);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        SelectProps={{ style: { height: "10%", width: "10%" } }}
        disableToolbar={false}
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        inputProps={{ style: { paddingLeft: 10, paddingRight: 0, margin: 0 } }}
        value={selectedDate}
        onChange={handleDateChange}
        autoOk={true}
        maxDateMessage={Messages.INVALID_DATE}
        onError={setError}
        helperText={<div style={{ fontSize: 15 }}>{error}</div>}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
